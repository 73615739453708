import React from 'react';
import ReactLoading from 'react-loading';
import FileViewer from 'react-file-viewer';

export default function QuestionPrint({
  handleChangeFile,
  copyFileUpload,
  copyUploadLoading,
  copyUpload,
  handleChange,
  formData,
  printURL,
}) {
  const docFile = 'docx';
  return (
    <div className="compulsoryContainer">
      {/* <h4 className="compulsoryHeading">Question Print</h4> */}
      <div className="shortDescription">
        <h4 className="addH4">Question Printable Data(qp)</h4>
        <div>
          <input
            type="file"
            name="question_file_path"
            id="question_file_path"
            className="fileChooseAddDetails"
            onChange={(e) => {
              console.log(e, e.currentTarget.value, 'upload');
              handleChangeFile(e);
            }}
            accept=".doc, .docx"
          ></input>
          <button type="button" className="btnFilesUploadAddDetails" onClick={(e) => copyFileUpload(e)}>
            Upload
          </button>
          {copyUploadLoading ? (
            <div>
              <ReactLoading type="spinningBubbles" color="#68dff0" className="fileCopyUploadedSpin" />
              <h5 className="fileCopyUploadedText">Uploding</h5>
            </div>
          ) : null}
        </div>
      </div>
      {copyUpload ? <p className="fileCopyUploaded">File has been Uploaded</p> : null}
      {console.log(printURL, 'printURL')}
      {printURL && !printURL.endsWith('undefined') && (
        <div
          key={Math.random()} // This is used to re-render when the file changes
          className="docxFileDiv"
        >
          <FileViewer fileType={docFile} filePath={printURL.endsWith('undefined') ? null : printURL} />
        </div>
      )}
      <div className="shortDescription">
        <h4 className="addH4">Reference</h4>
        <textarea className="addInput" onChange={(e) => handleChange(e, 'reference')} value={formData.reference}></textarea>
      </div>
    </div>
  );
}
