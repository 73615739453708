import { fetchMigrateLan } from '../../../../api/ms_sql';
import { toast } from 'react-toastify';

export default async function fetchData(setState, setStartUpLoad) {
  try {
    const getLanguage = await fetchMigrateLan();
    setState(function (prevState) {
      return {
        ...prevState,
        language: getLanguage.data.languageList,
      };
    });
    setStartUpLoad(false);
  } catch (err) {
    if (err.response && err.response.data) {
      console.log(err.response.data.message);
      toast.error(err.response.data.message);
    } else {
      toast.error('Server Error');
    }
    setStartUpLoad(false);
  }
}
