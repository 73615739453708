// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgotpassword_main__6t0Hc{
    height: 100vh;
    margin: auto;
    display: flex;
}
.forgotpassword_logincontainer__Wa93U{
    width: 330px;
    /* height: 50%; */
    background-color: white;
    /* position: relative; */
    left: 38%;
    text-align: center;
    /* margin-top: 12%; */
    border-radius: 10px;
    border: 1px solid #48bcb4;
    overflow: hidden;
    box-shadow: 0px 3px 13px #a8a8a8;
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Screens/ForgotPassword/forgotpassword.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;AACjB;AACA;IACI,YAAY;IACZ,iBAAiB;IACjB,uBAAuB;IACvB,wBAAwB;IACxB,SAAS;IACT,kBAAkB;IAClB,qBAAqB;IACrB,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,gCAAgC;IAChC,YAAY;AAChB","sourcesContent":[".main{\n    height: 100vh;\n    margin: auto;\n    display: flex;\n}\n.logincontainer{\n    width: 330px;\n    /* height: 50%; */\n    background-color: white;\n    /* position: relative; */\n    left: 38%;\n    text-align: center;\n    /* margin-top: 12%; */\n    border-radius: 10px;\n    border: 1px solid #48bcb4;\n    overflow: hidden;\n    box-shadow: 0px 3px 13px #a8a8a8;\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `forgotpassword_main__6t0Hc`,
	"logincontainer": `forgotpassword_logincontainer__Wa93U`
};
export default ___CSS_LOADER_EXPORT___;
