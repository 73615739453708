import { boardList } from '../../../../api/boards';
import { fetchCourse, fetchSubject, fetchSubSubject, fetchSubTopic, fetchTopic } from '../../../../api/institution';
import { toast } from 'react-toastify';
import { fetchMediumSub } from '../../../../api/mediums';
import { fetchObjective } from '../../../../api/objective';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

export default async function fetchOtherResults(e, selLabel, setStartUpLoad, setState, institution_id, formData, setFormData) {
  if (selLabel === 'question_type_id') {
    setStartUpLoad(true);
    try {
      const getBoard = await boardList();
      setState((prevState) => ({
        ...prevState,
        boards: getBoard.data.data,
      }));
      setStartUpLoad(false);
    } catch (err) {
      errorHandle(err);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'board_id') {
    setStartUpLoad(true);
    let board_id = +e.target.value || -1;
    console.log(board_id);
    try {
      const getCourse = await fetchCourse(institution_id, board_id);
      setState((prevState) => ({
        ...prevState,
        courses: getCourse.data.data,
      }));
      setStartUpLoad(false);
    } catch (err) {
      errorHandle(err);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'course_id') {
    setStartUpLoad(true);
    let course_id = +e.target.value || -1;
    try {
      const getSubject = await fetchSubject(institution_id, formData.board_id, course_id);
      setState((prevState) => ({
        ...prevState,
        subjects: getSubject.data.data,
      }));
      setStartUpLoad(false);
    } catch (err) {
      errorHandle(err);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'subject_id') {
    setStartUpLoad(true);
    let subject_id = +e.target.value || -1;
    try {
      const getSubSubject = await fetchSubSubject(institution_id, formData.board_id, formData.course_id, subject_id);
      const getMedium = await fetchMediumSub(subject_id);
      console.log(getMedium.data.data[0].medium_id);
      var med = getMedium.data.data[0].medium_id ? getMedium.data.data[0].medium_id : -1;
      console.log(med);
      setState((prevState) => ({
        ...prevState,
        subSubjects: getSubSubject.data.data,
      }));
      setFormData((prevState) => ({
        ...prevState,
        medium_id: med,
      }));
      setStartUpLoad(false);
    } catch (err) {
      errorHandle(err);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'sub_subject_id') {
    setStartUpLoad(true);
    let sub_subject_id = +e.target.value || -1;
    try {
      const getTopic = await fetchTopic(institution_id, formData.board_id, formData.course_id, formData.subject_id, sub_subject_id);
      setState((prevState) => ({
        ...prevState,
        topics: getTopic.data.data,
      }));
      setStartUpLoad(false);
    } catch (err) {
      errorHandle(err);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'topic_1_id') {
    setStartUpLoad(true);
    let topic_1_id = +e.target.value || -1;
    try {
      const getSubTopic = await fetchSubTopic(
        institution_id,
        formData.board_id,
        formData.course_id,
        formData.subject_id,
        formData.sub_subject_id,
        topic_1_id,
      );
      setState((prevState) => ({
        ...prevState,
        subTopics: getSubTopic.data.data,
      }));
      setStartUpLoad(false);
    } catch (err) {
      errorHandle(err);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'topic_2_id') {
    setStartUpLoad(true);
    let topic_2_id = +e.target.value || -1;
    try {
      const getSubTopic = await fetchSubTopic(
        institution_id,
        formData.board_id,
        formData.course_id,
        formData.subject_id,
        formData.sub_subject_id,
        topic_2_id,
      );
      setState((prevState) => ({
        ...prevState,
        subTopics2: getSubTopic.data.data,
      }));
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message, 'error while fetching data');
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'taxonomy_id') {
    setStartUpLoad(true);
    let taxonomy_id = +e.target.value;
    try {
      const getObjectives = await fetchObjective(taxonomy_id);
      setState((prevState) => ({
        ...prevState,
        objectives: getObjectives.data.data,
      }));
      setStartUpLoad(false);
    } catch (err) {
      errorHandle(err);
      setStartUpLoad(false);
    }
  }
}
