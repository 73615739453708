import React from 'react';
import warning from '../../../assets/images/warning.png';
import close from '../../../assets/images/close.png';
import './deletePopup.css';

export default function DeletePopup({ cancel, popup, okDelete }) {
  return (
    <div onClick={cancel} className={popup ? 'popUpBack' : 'noPopUp'}>
      <div className={popup ? 'yesPopUp' : 'noPopUp'}>
        <button onClick={cancel} className="popUpCloseBtn">
          <img src={close} alt="close" className="closeImgDelete" />
        </button>
        <div className="warningDiv">
          <img src={warning} alt="warning" />
        </div>
        <h2 className="popUph2">Are You Sure?</h2>
        <div className="popUpButtonDiv">
          <button onClick={cancel} className="popUpCancelButton">
            Cancel
          </button>
          <button onClick={okDelete} className="popUpOkButton">
            OK
          </button>
        </div>
      </div>
    </div>
  );
}
