import React from 'react';
// import arrowForward from '../../../../assets/images/arrowFroward.png';
import Pagination from '../../../SubComponents/Pagination/Pagination';
import PublicIcon from '../../../../assets/images/globe.png';
import organisation from '../../../../assets/images/organisation.png';
import questionSearch from '../../../../assets/images/questionSearch.png';
import createdByIcon from '../../../../assets/images/createdBy.png';
import lastUpdatedIcon from '../../../../assets/images/lastUpdated.png';
import curatedIcon from '../../../../assets/images/curated.png';
import notCuratedIcon from '../../../../assets/images/notCurated.png';
import curatedByIcon from '../../../../assets/images/curatedBy.png';
import nonCuratorIcon from '../../../../assets/images/noneCurator.png';
import noDataImage from '../../../../assets/images/noData.png';
import Breadcrums from '../../../SubComponents/breadcrum/breadcrum';
import { Player } from '@lottiefiles/react-lottie-player';
import configuration from '../../../config/Config';

export default function ViewQuestionResult({
  questionView,
  questionType,
  handleRadioButtons,
  questionList,
  selData,
  handleClick,
  paginationClick,
  numberOfQuestions,
  offSet,
  pageNumber,
  selLink,
  handleEditDetails,
  handleReuse,
  noData,
  role,
  handleTranslate,
  handleDelete,
  handleDownloadSource,
}) {
  function newDateFormate(oldDate) {
    var dateArray = oldDate.split('-');
    var newDate = [dateArray[1], dateArray[0], dateArray[2]].join('-');
    return new Date(newDate + 'Z').toString().slice(0, 25);
  }
  return (
    <div className="queResult">
      {/* <div className="viewQueH3Div">
        <h2 className="viewQueH2">Question Bank</h2>
        <img src={arrowForward} alt="arrow forward" className="viewH3Arrow" />
        <h3 className="viewQueH3">View Question</h3>
      </div> */}
      <div>
        <Breadcrums title="View Question" />
      </div>
      {questionView === false && noData === false ? (
        <div className="questionSearchImgDiv">
          <p className="useLeftPanel">Please use left panel to search question</p>
          <img src={questionSearch} alt="question search" />
        </div>
      ) : null}
      {noData ? (
        <div className="questionSearchImgDiv">
          <p className="useLeftPanel">There is no data available</p>
          <img src={noDataImage} alt="question search" />
        </div>
      ) : null}
      <div>
        {questionView ? (
          <div className="compulsoryContainerViewQuestions">
            <div className="viewQuestionsSearchDisplay">
              <div className="viewQuestionMainTable">
                <div className="viewQuestionsH4RadioDiv">
                  <h4 className="compulsoryQueViewHeading">Questions</h4>
                  <div
                    className="viewQuestionsRadioDiv"
                    // onChange={handleRadioButtons}
                  >
                    <input
                      type="radio"
                      value="All"
                      name="questions"
                      checked={questionType === 'All'}
                      className="viewQuestionsRadioInput"
                      onChange={handleRadioButtons}
                    />{' '}
                    <p className={`${questionType === 'All' ? 'activeviewQuestionsRadioP' : 'viewQuestionsRadioP'}`}>All</p>
                    <input
                      type="radio"
                      value="Verified"
                      name="questions"
                      checked={questionType === 'Verified'}
                      className="viewQuestionsRadioInput"
                      onChange={handleRadioButtons}
                    />{' '}
                    <p className={`${questionType === 'Verified' ? 'activeviewQuestionsRadioP' : 'viewQuestionsRadioP'}`}>
                      Curated
                    </p>
                    <input
                      type="radio"
                      value="Not Verified"
                      name="questions"
                      checked={questionType === 'Not Verified'}
                      className="viewQuestionsRadioInput"
                      onChange={handleRadioButtons}
                    />{' '}
                    <p className={`${questionType === 'Not Verified' ? 'activeviewQuestionsRadioP' : 'viewQuestionsRadioP'}`}>
                      Not-Curated
                    </p>
                    <input
                      type="radio"
                      value="Rejected"
                      name="questions"
                      checked={questionType === 'Rejected'}
                      className="viewQuestionsRadioInput"
                      onChange={handleRadioButtons}
                    />
                    <p className={`${questionType === 'Rejected' ? 'activeviewQuestionsRadioP' : 'viewQuestionsRadioP'}`}>
                      Rejected
                    </p>
                  </div>
                </div>

                <div className="viewTablePagination">
                  <div className="tableContainerViewQuestion">
                    <div>
                      {questionList.map((row, questionKey) => {
                        return (
                          <div key={questionKey}>
                            <div
                              className={+selData.question_id === +row.question_id ? 'viewResultRowDivSel' : 'viewResultRowDiv'}
                              onClick={(e) => handleClick(row)}
                            >
                              <div className="viewShortDiv">
                                <p className="viewShortP">
                                  {questionKey + 1 + 10 * (pageNumber - 1) + '. ' + row.short_description}
                                </p>
                                <div className="viewByLastDiv">
                                  <img src={createdByIcon} alt="create icon" className="queViewCreatedByIcon" />
                                  <p className="viewBy">{row.created_by}</p>
                                  <img src={lastUpdatedIcon} alt="last Updated icon" className="queViewCreatedByIcon" />
                                  <p className="viewLastUpdated">
                                    {/* Last Updated: {new Date(row.last_updated + "Z").toString()} */}
                                    {newDateFormate(row.last_updated)}
                                  </p>
                                  {questionType !== 'Not Verified' && questionType !== 'Rejected' && (
                                    <img
                                      src={row.is_curated ? curatedIcon : notCuratedIcon}
                                      alt="curated icon"
                                      className={row.is_curated ? 'queViewCreatedByIcon' : 'queViewCreatedByIcon2'}
                                    />
                                  )}
                                  {questionType !== 'Not Verified' && questionType !== 'Rejected' && (
                                    <p className="viewByCurated">{row.is_curated ? 'Yes' : 'No'}</p>
                                  )}
                                  {questionType !== 'Not Verified' && questionType !== 'Rejected' && (
                                    <img
                                      src={row.curated_by ? curatedByIcon : nonCuratorIcon}
                                      alt="curatedBy icon"
                                      className="queViewCreatedByIcon2"
                                    />
                                  )}
                                  {questionType !== 'Not Verified' && questionType !== 'Rejected' && (
                                    <p className="viewByCurated">{row.curated_by ? row.curated_by : 'none'}</p>
                                  )}
                                </div>
                              </div>
                              {row.access_level === 'Global' && <img src={PublicIcon} alt="glode" className="viewPublicIcon" />}
                              {row.access_level === 'Organisation' && (
                                <img src={organisation} alt="organisation" className="viewPublicIcon" />
                              )}
                              {row.access_level === 'Private' && (
                                <img src={createdByIcon} alt="personal" className="viewPublicIcon" />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <Pagination
                    paginationClick={paginationClick}
                    numberOfRows={numberOfQuestions}
                    offSet={offSet}
                    pageNumber={pageNumber}
                  />
                </div>
              </div>
              <div className="viewImgDisplay">
                {selLink !== '' ? (
                  <div>
                    <div className="viewAddContainer">
                      <button className="viewQueEditeButton" onClick={handleEditDetails}>
                        Edit Details
                      </button>
                      <button className="ReuseButton" onClick={handleReuse}>
                        Reuse
                      </button>
                      <button className="ReuseButton" onClick={handleTranslate}>
                        Translate
                      </button>
                      {role === 'Admin' ? (
                        <button className="ReuseButton" onClick={handleDelete}>
                          Delete
                        </button>
                      ) : null}
                      {role === 'Admin' ? (
                        <button className="ReuseButton" onClick={handleDownloadSource}>
                          Source File
                        </button>
                      ) : null}
                    </div>
                    <div className="viewQuestionDisplayDiv">
                      <p className="viewImageAbove">
                        #{selData.question_id}, Topic: {selData.topic} , Sub-topic: {selData.sub_topic}
                      </p>
                      <div className="viewImageContainer ">
                        {selLink?.endsWith('.json') ? (
                          <Player
                            autoplay
                            loop
                            src={selLink}
                            style={{
                              width: '100%',
                              height: '340px',
                            }}
                          />
                        ) : (
                          <img
                            src={
                              selLink?.split('/')?.includes('stucle-dev.sgp1.digitaloceanspaces.com')
                                ? selLink
                                : configuration?.digitalOceanSpaces + selLink
                            }
                            alt="UpLoaded"
                            className="viewQuestionsBigImage"
                          />
                        )}
                      </div>
                      <div className="veiwQuestionDisplayInfo">
                        {selData.response_type === 'MCQ' && (
                          <p className="viewImageBelow">
                            Answer:{' '}
                            {selData.answer.map((object, objectKey) => {
                              return objectKey === selData.answer.length - 1 ? object.option_text : object.option_text + ', ';
                            })}
                          </p>
                        )}
                        {selData.response_type === 'Yes/No' && (
                          <p className="viewImageBelow">Answer: {selData.answer.correct_answer}</p>
                        )}
                        {selData.response_type === '0-10' && (
                          <p className="viewImageBelow">Answer: {selData.answer.correct_answer}</p>
                        )}
                        {selData.response_type === 'MAQ' && (
                          <p className="viewImageBelow">
                            Answer:{' '}
                            {selData.answer.map((object, objectKey) => {
                              return objectKey === selData.answer.length - 1 ? object.option_text : object.option_text + ', ';
                            })}
                          </p>
                        )}
                        {selData.response_type === 'Matching ' && (
                          <p className="viewImageBelow">
                            Answer:{' '}
                            {selData.answer.map((object, objectKey) => {
                              return objectKey === selData.answer.length - 1
                                ? object.left_hand_option + '-' + object.right_hand_option
                                : object.left_hand_option + '-' + object.right_hand_option + ', ';
                            })}
                          </p>
                        )}
                        {selData.response_type === 'One Word Answer' && (
                          <p className="viewImageBelow">Answer: {selData.answer.correct_answer}</p>
                        )}
                        {selData.response_type === 'FITB' && (
                          <p className="viewImageBelow">Answer: {selData.answer.correct_answer}</p>
                        )}
                        {selData.response_type === 'Text' && (
                          <p className="viewImageBelow">Answer: {selData.answer.correct_answer}</p>
                        )}
                        {(selData.response_type === 'Image' ||
                          selData.response_type === 'Voice' ||
                          selData.response_type === 'Video') && (
                          <a
                            className="viewImageBelowFile"
                            href={selData.answer.answer_file_path}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            Answer: File.
                          </a>
                        )}
                        {/* {(selData.response_type === "Image" || selData.response_type === "Voice" ||
              selData.response_type === "Video") && (
              <div
                className="viewImageBelowFile"
                onClick={(e) => downloadFile(selData.answer.answer_file_path)}
              >
                Answer: File.
              </div>
            )} */}
                        {selData.response_type === 'Files' && (
                          <a
                            className="viewImageBelowFile"
                            href={selData.answer[0].answer_file_path}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            Answer: File.
                          </a>
                        )}
                        <p className="viewImageBelow">Reference: {selData.reference}</p>

                        <p className="viewImageBelowTwo">Difficulty: {selData.difficulty_level}</p>
                        <p className="viewImageBelowTwo">Objective: {selData.objective}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
