import { topicReportCount } from '../../../../api/report';
import { topicReport } from '../../../../api/report';
import { toast } from 'react-toastify';

export async function topicReportSubmit(
  setSubmitLoading,
  setNoData,
  formData,
  setNumberOfTopicReports,
  setTopicReportList,
  limit,
  offSet,
  setStartUpLoad,
) {
  setNumberOfTopicReports(0);
  // setTopicReportList([]);
  setSubmitLoading(true);
  setNoData(false);
  const link = [];
  link.push(`limit=${limit}`);
  link.push(`offset=${offSet}`);
  if (formData.medium_id && formData.medium_id !== -1) {
    link.push(`medium_id=${formData.medium_id}`);
  }
  if (formData.board_id && formData.board_id !== -1) {
    link.push(`board_id=${formData.board_id}`);
  }
  if (formData.course_id && formData.course_id !== -1) {
    link.push(`course_id=${formData.course_id}`);
  }
  if (formData.subject_id && formData.subject_id !== -1) {
    link.push(`subject_id=${formData.subject_id}`);
  }
  if (formData.sub_subject) {
    link.push(`sub_subject=${formData.sub_subject}`);
  }
  if (formData.topic_id && formData.topic_id !== -1) {
    link.push(`topic_id=${formData.topic_id}`);
  }
  if (formData.difficulty_level && formData.difficulty_level !== -1) {
    link.push(`difficulty_level=${formData.difficulty_level}`);
  }
  if (formData.createdBy !== '') {
    link.push(`added_by_user_id=${formData.createdBy}`);
  }
  if (formData.objective_id && formData.objective_id !== -1) {
    link.push(`objective_id=${formData.objective_id}`);
  }
  if (formData.taxonomy_id && formData.taxonomy_id !== -1) {
    link.push(`taxonomy_id=${formData.taxonomy_id}`);
  }
  if (formData.from_date) {
    link.push(`from_date=${formData.from_date}`);
  }
  if (formData.to_date) {
    link.push(`to_date=${formData.to_date}`);
  }
  const linkFinal = link.join('&');
  console.log('linkFinal', linkFinal);
  try {
    const getTopicReportCount = await topicReportCount(linkFinal);
    console.log('getTopicReportCount', getTopicReportCount.data.data);
    setNumberOfTopicReports(getTopicReportCount.data.data.count);
    if (getTopicReportCount.data.data.count !== 0) {
      const getTopicReport = await topicReport(linkFinal);
      console.log('getTopicReport', getTopicReport.data.data);
      setTopicReportList(getTopicReport.data.data);
      setSubmitLoading(false);
      setStartUpLoad(false);
      window.scrollTo(0, 1000);
    } else {
      setTopicReportList([]);
      setNoData(true);
      setSubmitLoading(false);
      setStartUpLoad(false);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    } else {
      toast.error('Server Error');
    }
    setSubmitLoading(false);
    setStartUpLoad(false);
  }
}
