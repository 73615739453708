import { fetchQuestionType, fetchAccessLevel } from '../../../../api/qbMasterData';
import { fetchMedium } from '../../../../api/mediums';
import { fetchTaxonomy } from '../../../../api/taxonomy';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

export default async function fetchData(setState, setStartUpLoad) {
  try {
    const getQuestionType = await fetchQuestionType();
    const getAccessLevel = await fetchAccessLevel();
    const getMediums = await fetchMedium();
    const getTaxonomies = await fetchTaxonomy();
    setState((prevState) => ({
      ...prevState,
      questionsType: getQuestionType.data.data,
      accessLevel: getAccessLevel.data.data,
      medium: getMediums.data.data,
      taxonomies: getTaxonomies.data.data,
    }));
    setStartUpLoad(false);
  } catch (error) {
    errorHandle(error);
    setStartUpLoad(false);
  }
}
