// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadingBars_loadingBackground__-sm50 {
  position: fixed; /* Positioning and size */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 99;
}

.loadingBars_startLoadSpin__reLxs {
  position: fixed;
  top: 45%;
  left: 55%;
  width: 150px !important;
  margin-left: -100px;
  height: 50px;
  margin-top: -25px;
  z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/components/SubComponents/LoadingBars/loadingBars.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,yBAAyB;EAC1C,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,0CAA0C;EAC1C,WAAW;AACb;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".loadingBackground {\n  position: fixed; /* Positioning and size */\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(128, 128, 128, 0.5);\n  z-index: 99;\n}\n\n.startLoadSpin {\n  position: fixed;\n  top: 45%;\n  left: 55%;\n  width: 150px !important;\n  margin-left: -100px;\n  height: 50px;\n  margin-top: -25px;\n  z-index: 100;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingBackground": `loadingBars_loadingBackground__-sm50`,
	"startLoadSpin": `loadingBars_startLoadSpin__reLxs`
};
export default ___CSS_LOADER_EXPORT___;
