import { getBacklogFunction } from '../../../../api/questions';
import { toast } from 'react-toastify';

export default async function handlePagination(offSet, userId, setQuestionData, setStartUpLoad, limit) {
  console.log(offSet);
  try {
    const getBacklogs = await getBacklogFunction(userId, offSet, limit);
    setQuestionData(getBacklogs.data.data);
    setStartUpLoad(false);
  } catch (err) {
    if (err.response && err.response.data) {
      console.log(err.response.data.message);
      toast.error(err.response.data.message);
    } else {
      toast.error('Server Error');
    }
    setStartUpLoad(false);
  }
}
