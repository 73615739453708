import { toast } from 'react-toastify';
import s3 from '../../../config/DigitalOcean';
import configuration from '../../../config/Config';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

export default async function handleDowSource(setStartUpLoad, selData) {
  setStartUpLoad(true);
  if (selData.question_group_source_file_path === null || selData.question_group_source_file_path === undefined) {
    toast.error('File Not Available');
  } else {
    s3.getObject(
      {
        Bucket: configuration.bucketName,
        Key: selData.question_group_source_file_path,
      },
      (err, data) => {
        if (err) {
          console.error(err);
          errorHandle(err);
          setStartUpLoad(false);
          return;
        }

        const url = URL.createObjectURL(new Blob([data.Body]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', selData.question_group_source_file_path.split('/')[2]);
        document.body.appendChild(link);
        link.click();
        setStartUpLoad(false);
      },
    );
  }
}
