import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// import Navbar from '../Header/Navbar';
// import AddDetails from '../Screens/AddDetails/AddDetails';
// import QSetCreator from '../Screens/QSetCreator/QSetCreator';
// import QSetMap from '../Screens/QSetMap/QSetMap';
// import QSetView from '../Screens/QSetView/QSetView';
// import TranslateQuestions from '../Screens/TranslateQuestions/TranslateQuestions';
// import ViewQuestions from '../Screens/ViewQuestions/ViewQuestions';
// import UploadQuestions from '../Screens/UploadQuestions/UploadQuestions';
import './layout.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Migrate from '../Screens/Migrate/Migrate';
// import Backlog from '../Screens/Backlog/Backlog';
// import MigrateMap from '../Screens/MigrateMap/MigrateMap';
import { clearAll } from '../../api/queReservation';
import Navbarnew from '../Header/newNavebar';
// import TopicReport from '../Screens/TopicReport/TopicReport';
// import SubTopicReport from '../Screens/SubTopicReport/SubTopicReport';
// import QSetReport from '../Screens/QSetReport/QSetReport';

// const switchRoutes = (
//   <Routes>
//     <Route path="/admin/add-details" component={AddDetails} />
//     <Route path="/admin/edit-details" component={AddDetails} />
//     <Route path="/admin/upload-questions" component={UploadQuestions} />
//     <Route path="/admin/q-set-creator" component={QSetCreator} />
//     <Route path="/admin/edit-q-set" component={QSetCreator} />
//     <Route path="/admin/q-set-map" component={QSetMap} />
//     <Route path="/admin/q-set-view" component={QSetView} />
//     <Route path="/admin/translate-questions" component={TranslateQuestions} />
//     <Route path="/admin/view-questions" component={ViewQuestions} />
//     <Route path="/admin/migrate" component={Migrate} />
//     <Route path="/admin/backlog" component={Backlog} />
//     <Route path="/admin/migrate-map" component={MigrateMap} />
//     <Route path="/admin/topic-report" component={TopicReport} />
//     <Route path="/admin/sub-topic-report" component={SubTopicReport} />
//     <Route path="/admin/q-set-report" component={QSetReport} />
//     <Route render={() => <Navigate to="/admin/upload-questions" />} />
//   </Routes>
// );

var i = 0;
var timeStampExpire;
var expiryTime;

function Layout() {
  // const [togglebar, setTogglebar] = useState(false);

  // const getRoute = () => {
  //   return window.location.pathname !== null;
  // };

  timeStampExpire = window.localStorage.getItem('expiry');
  if (window.location.pathname !== '/' && i === 0) {
    expiryTime = timeStampExpire - Date.now();
    console.log(expiryTime, i);
    i = 1;
    if (expiryTime < 0) {
      toast.error('Session has expired. Login again to continue...');
      setTimeout(() => {
        window.localStorage.clear();
        window.location = '/';
      }, 3000);
    } else {
      setTimeout(() => {
        toast.error('Session has expired. Login again to continue...');
        setTimeout(() => {
          window.localStorage.clear();
          window.location = '/';
        }, 3000);
      }, expiryTime);
    }
  }
  const userId = window.localStorage.getItem('userId');

  useEffect(() => {
    async function clearingReservation() {
      if (window.location.pathname !== '/admin/migrate') {
        console.log('Test layout');
        let reservedArr = JSON.parse(window.localStorage.getItem('reservedArray'));
        // let tempArray = JSON.parse(
        //   window.localStorage.getItem("reservedArray")
        // );
        // console.log(reservedArr);
        if (reservedArr && reservedArr.length !== 0) {
          //   for (let j = 0; j < reservedArr.length; j++) {
          try {
            // const clearResult = await clearReservation(
            //   reservedArr[j].reservationId,
            //   reservedArr[j].questionId
            // );
            // console.log(clearResult);
            // if (clearResult.status === 200) {
            //   tempArray.splice(0, j+1);
            //   console.log(tempArray);
            //   window.localStorage.setItem(
            //     "reservedArray",
            //     JSON.stringify(tempArray)
            //   );
            await clearAll(userId);
            window.localStorage.removeItem('reservedArray');
          } catch (err) {
            // }
            if (err.response && err.response.data) {
              console.log(err.response);
              toast.error(err.response.data.message);
            } else {
              toast.error('Server Error');
            }
          }
          // }
        }
      }
    }

    clearingReservation();
    //eslint-disable-next-line
  });

  if (window.localStorage.getItem('token') === null) {
    return (window.location.href = '/');
  }

  // function togglebarHandel() {
  //   setTogglebar(!togglebar);
  // }

  return (
    <div>
      <div className="layoutContainer">
        {/* <button className="layoutButton" onClick={togglebarHandel}>
          ☰
        </button> */}
        {/* <Navbar /> */}
        <Navbarnew />
      </div>
      {/* <div className={togglebar ? "togglebar" : "togglebarClose"}> */}
      {/* <div className="togglebar"> */}
      {/* {togglebar ? <Togglebar path={window.location.pathname} /> : ""} */}
      {/* <Togglebar path={window.location.pathname} /> */}
      {/* </div> */}
      {/* <div className={togglebar ? "togg" : "toggClose"}> */}
      {/* <div className="togg"> */}
      {/* {getRoute() ? <div className="contentcontainer">{switchRoutes}</div> : null}{' '} */}
      {/* <p className="developer">Developed by Sagar Bazar</p> */}
      {/* </div> */}
      {/* <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Outlet />
    </div>
  );
}

export default Layout;
