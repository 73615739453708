import React from 'react';
import styles from './breadcrum.module.css';
import { Link } from 'react-router-dom';
import ImageAssets from '../../../assets/images/imageAsset';

const Breadcrums = ({ title }) => {
  return (
    <div className={styles.breedcrumb}>
      <ul className={styles.row}>
        <li className={styles.home}>
          <Link className={styles.home} to="/admin/upload-questions">
            Home
          </Link>
        </li>
        <li className={styles.images}>
          <img src={ImageAssets?.qb_breadcrumarrow?.default} width={10} height={10} alt="arrow" />
        </li>
        <div className={styles.text}>{title}</div>
      </ul>
    </div>
  );
};

export default Breadcrums;
