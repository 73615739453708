import linkGen from './linkGen';
import { fetchQuestionsCount } from '../../../../api/questions';
import { fetchQuestions } from '../../../../api/questions';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

//function to search questions
export default async function handleSubmitQueView(
  setStartUpLoad,
  setNoData,
  formData,
  questionType,
  offSet,
  setNumberOfQuestions,
  setQuestionList,
  setQuestionView,
) {
  // e.preventDefault();
  setStartUpLoad(true);
  setNoData(false);
  // setQuestionType("All");
  let linkFinal = linkGen(formData, questionType);
  console.log(linkFinal);
  console.log(formData);
  // const AuthSub = {
  //   headers: {
  //     authorization: "Bearer " + token,
  //   },
  // };
  try {
    // const getNumberOfQuestions = await Axios.get(
    //   `${BASE_URL}/qb/questions/count_questions?${linkFinal}`,
    //   Auth
    // );
    const getNumberOfQuestions = await fetchQuestionsCount(linkFinal);
    console.log(getNumberOfQuestions.data.data.count);
    // const getQuestions = await Axios.get(
    //   `${BASE_URL}/qb/questions/search?${linkFinal}&limit=10&offset=${offSet}`,
    //   {
    //     headers: AuthSub.headers,
    //   }
    // );
    const getQuestions = await fetchQuestions(linkFinal, offSet);
    console.log(getQuestions.data.data);
    setNumberOfQuestions(getNumberOfQuestions.data.data.count);
    setQuestionList(getQuestions.data.data);
    if (getNumberOfQuestions.data.data.count > 0) {
      setQuestionView(true);
      setStartUpLoad(false);
      window.scrollTo(0, 1000);
    } else {
      setQuestionView(false);
      setStartUpLoad(false);
      setNoData(true);
    }
  } catch (err) {
    setNoData(true);
    errorHandle(err);
    setStartUpLoad(false);
  }
}
