export default function changeOptionMCQ(e, crtNear, numberSel, responseMCQ, setResponseMCQ) {
  const valueSele = e.target.checked;
  if (valueSele === true) {
    if (crtNear === 'correct') {
      let tempData = responseMCQ.filter((obj) => {
        return obj.option_text !== numberSel;
      });
      tempData = [
        ...tempData,
        {
          option_text: numberSel,
          is_correct_option: true,
          is_near_correct_option: false,
        },
      ];
      setResponseMCQ([...tempData]);
      console.log(responseMCQ);
    }
    if (crtNear === 'nearCorrect') {
      let tempData = responseMCQ.filter((obj) => {
        return obj.option_text !== numberSel;
      });
      tempData = [
        ...tempData,
        {
          option_text: numberSel,
          is_correct_option: false,
          is_near_correct_option: true,
        },
      ];
      setResponseMCQ([...tempData]);
      console.log(responseMCQ);
    }
  }
  if (valueSele === false) {
    if (crtNear === 'correct') {
      let tempData = responseMCQ.filter((obj) => {
        return obj.option_text !== numberSel;
      });
      setResponseMCQ([...tempData]);
      console.log(responseMCQ);
    }
    if (crtNear === 'nearCorrect') {
      let tempData = responseMCQ.filter((obj) => {
        return obj.option_text !== numberSel;
      });
      setResponseMCQ([...tempData]);
      console.log(responseMCQ);
    }
  }
}
