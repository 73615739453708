import React, { useState } from 'react';
// import Dropdown from '../../SubComponents/Dropdown/Dropdown';
import './translateQuestions.css';
import Breadcrums from '../../SubComponents/breadcrum/breadcrum';
import SelectInput from '../../SubComponents/SelectInput/SelectInput';

const language = ['Kannada', 'English', 'Telugu', 'Tamil', 'Hindi'];

function TranslateQuestions() {
  const [formData, setFormData] = useState({
    syllabus: '',
    baseLanguage: '',
    translateTo: '',
    subject: '',
    subSubject: '',
    class: '',
    topic: '',
  });

  function handelChange(e, selLabel) {
    const valueSele = e?.target?.value || e?.value;
    setFormData({ ...formData, [selLabel]: valueSele });
  }

  console.log(formData);

  return (
    <div>
      <div className="s">
        <Breadcrums title="Translate" />
      </div>
      <div className="main">
        <div className="formwraper">
          <form>
            <div className="translatesearchQuestionContainer">
              <h4 className="searchQuestionHeading">Search Questions for Translation</h4>
              <div className="dropdownwraper">
                <div className="transalesearchQuestionDropdown">
                  {/* <Dropdown
                  label="Syllabus"
                  options={language}
                  displayName=""
                  name="syllabus"
                  idName="syllabus_id"
                  onChange={handelChange}
                /> */}
                  <SelectInput
                    label="Syllabus"
                    options={language?.map((i) => ({ label: i, value: i })) || []}
                    placeholder="Select Syllabus"
                    displayName=""
                    name="syllabus"
                    idName="syllabus_id"
                    handleChange={(e) => handelChange(e, 'syllabus')}
                  />
                </div>
                <div className="transalesearchQuestionDropdown">
                  {/* <Dropdown
                  label="Base Language"
                  options={language}
                  displayName=""
                  name="baseLanguage"
                  idName="baseLanguage_id"
                  onChange={handelChange}
                /> */}
                  <SelectInput
                    label="Base Language"
                    options={language?.map((i) => ({ label: i, value: i })) || []}
                    placeholder="Select Base Language"
                    displayName=""
                    name="baseLanguage"
                    idName="baseLanguage_id"
                    handleChange={(e) => handelChange(e, 'baseLanguage')}
                  />
                </div>
                <div className="transalesearchQuestionDropdown">
                  {/* <Dropdown
                  label="Translate To"
                  options={language}
                  displayName=""
                  name="translateTo"
                  idName="translateTo_id"
                  onChange={handelChange}
                /> */}
                  <SelectInput
                    label="Translate To"
                    options={language?.map((i) => ({ label: i, value: i })) || []}
                    placeholder="Select Translate To"
                    displayName=""
                    name="translateTo"
                    idName="translateTo_id"
                    handleChange={(e) => handelChange(e, 'translateTo')}
                  />
                </div>
                <div className="transalesearchQuestionDropdown">
                  {/* <Dropdown
                  label="Subject"
                  options={language}
                  displayName=""
                  name="subject"
                  idName="subject_id"
                  onChange={handelChange}
                /> */}
                  <SelectInput
                    label="Subject"
                    options={language?.map((i) => ({ label: i, value: i })) || []}
                    placeholder="Select Subject"
                    displayName=""
                    name="subject"
                    idName="subject_id"
                    handleChange={(e) => handelChange(e, 'subject')}
                  />
                </div>
                <div className="transalesearchQuestionDropdown">
                  {/* <Dropdown
                  label="Sub-Subject"
                  options={language}
                  displayName=""
                  name="subSubject"
                  idName="sub_subject_id"
                  onChange={handelChange}
                /> */}
                  <SelectInput
                    label="Sub-Subject"
                    options={language?.map((i) => ({ label: i, value: i })) || []}
                    placeholder="Select Subject"
                    displayName=""
                    name="subSubject"
                    idName="sub_subject_id"
                    handleChange={(e) => handelChange(e, 'subSubject')}
                  />
                </div>
                <div className="transalesearchQuestionDropdown">
                  {/* <Dropdown
                  label="Class"
                  options={language}
                  displayName=""
                  name="class"
                  idName="class_id"
                  onChange={handelChange}
                /> */}
                  <SelectInput
                    label="Class"
                    options={language?.map((i) => ({ label: i, value: i })) || []}
                    placeholder="Select Class"
                    displayName=""
                    name="Class"
                    idName="class_id"
                    handleChange={(e) => handelChange(e, 'Class')}
                  />
                </div>
                <div className="transalesearchQuestionDropdown">
                  {/* <Dropdown
                  label="Topic"
                  options={language}
                  displayName=""
                  name="topic"
                  idName="topic_id"
                  onChange={handelChange}
                /> */}
                  <SelectInput
                    label="Topic"
                    options={language?.map((i) => ({ label: i, value: i })) || []}
                    placeholder="Select Topic"
                    displayName=""
                    name="topic"
                    idName="topic_id"
                    handleChange={(e) => handelChange(e, 'topic')}
                  />
                </div>
              </div>
            </div>
            <div className="transQueContainer">
              <button type="button" className="transQueSearch">
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TranslateQuestions;
