import https from '../init/https';

//QB-IC-4: Retrieve list of courses of an institution
export async function fetchCourse(institution_id, board_id) {
  const fetchCourseData = await https.get(`/institutions/${institution_id}/boards/${board_id}/courses`);
  return fetchCourseData;
}

//QB-IS-4: Retrieve list of subjects for an institutions
export async function fetchSubject(institution_id, board_id, course_id) {
  const fetchSubjectData = await https.get(`/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects`);
  return fetchSubjectData;
}

// QB-ISS-4: Retrieve list of sub-subjects for an institution
export async function fetchSubSubject(institution_id, board_id, course_id, subject_id) {
  const fetchSubSubjectData = await https.get(
    `/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects`,
  );
  return fetchSubSubjectData;
}

//QB-IT-4: Retrieve list of topics for an institution
export async function fetchTopic(institution_id, board_id, course_id, subject_id, sub_subject_id) {
  const fetchTopicData = await https.get(
    `/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics`,
  );
  return fetchTopicData;
}

//QB-IST-4: Retrieve list of sub-topics for an institution
export async function fetchSubTopic(institution_id, board_id, course_id, subject_id, sub_subject_id, topic_1_id) {
  const fetchSubTopicData = https.get(
    `/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics/${topic_1_id}/sub_topics`,
  );
  return fetchSubTopicData;
}
