import React from 'react';
import Dropdown from '../../../SubComponents/Dropdown/Dropdown';
import Options from '../../../SubComponents/Options/Options';
import OptionsRight from '../../../SubComponents/OptionsRight/OptionsRight';
import MAQ from '../../../SubComponents/MAQ/MAQ';
import Matching from '../../../SubComponents/Matching/Matching';
import DirectAnswer from '../../../SubComponents/DirectAnswer/DirectAnswer';
import ReactLoading from 'react-loading';

export default function Response({
  state,
  handleChange,
  formData,
  notFilled,
  handleChangeOptionMCQ,
  MCQCrtArray,
  MCQNrArray,
  handleChangeOptionYesNo,
  handleChangeResponse,
  handleChangeOption,
  MAQArray,
  matchingCount,
  handleRemove,
  responseMatchingData,
  incrementMatching,
  handleResponceFile,
  responseUploadFile,
  resUploadLoading,
  resVoice,
  resImgae,
  resVideo,
  resFile,
}) {
  const alphabet = ['A', 'B', 'C', 'D', 'E', 'F'];
  const number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const MCQNumber = 1;
  const yesOrNoNumber = 2;
  const zeroNumber = 3;
  const MAQNumber = 4;
  const matchingNumber = 5;
  const oneWordNumber = 6;
  const FITBNumber = 7;
  const textNumber = 8;
  const voiceNumber = 9;
  const imageNumber = 10;
  const videoNumber = 11;
  const fileNumber = 12;
  const numOptionValue = 6;

  return (
    <div className="responseDiv">
      <div className="responseContainer">
        {/* <h4 className="compulsoryHeading">Response Details</h4> */}
        <div className="dropdownFlex">
          <Dropdown
            placeholder="Answer Input"
            label="Answer Input"
            options={state.answerInput}
            name="answer_input_type_id"
            onChange={handleChange}
            idName="id"
            displayName="display_name"
            value={formData.answer_input_type_id}
            req={true}
            notFill={notFilled}
          />
          <Dropdown
            placeholder="Evaluation"
            label="Evaluation"
            options={state.evaluation}
            displayName="display_name"
            name="evaluation_type_id"
            idName="id"
            onChange={handleChange}
            value={formData.evaluation_type_id}
            req={true}
            notFill={notFilled}
          />
        </div>
        <div className="dropdownFlex">
          <Dropdown
            placeholder="Access Level"
            label="Access Level"
            options={state.accessLevel}
            displayName="display_name"
            name="access_level_id"
            idName="id"
            onChange={handleChange}
            value={formData.access_level_id}
            req={true}
            notFill={notFilled}
          />
          <Dropdown
            placeholder="Response Type"
            label="Response Type"
            options={+formData.answer_input_type_id === 1 ? state.responseType.slice(0, 3) : state.responseType}
            name="response_type_id"
            onChange={handleChange}
            idName="id"
            displayName="display_name"
            value={formData.response_type_id}
            req={true}
            notFill={notFilled}
          />
        </div>
      </div>
      <div>
        {parseInt(formData.response_type_id) === MCQNumber ? (
          <div /* className="compulsoryContainer" */>
            <h4 className="optionsH4">Options</h4>
            <div className="shortDescription">
              <p className="optionsH4">(Multiple correct answers allowed)</p>
              {Array.apply(null, { length: numOptionValue }).map((e, keyNumOptionValue) => (
                <Options
                  key={keyNumOptionValue}
                  alphabet={alphabet[keyNumOptionValue]}
                  onChange={handleChangeOptionMCQ}
                  arrayCrt={MCQCrtArray}
                  arrayNr={MCQNrArray}
                />
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
        {parseInt(formData.response_type_id) === yesOrNoNumber ? (
          <OptionsRight
            onChange={handleChangeOptionYesNo}
            optionOne="Yes"
            optionTwo="No"
            value={formData.answer_json.correct_answer}
          />
        ) : (
          ''
        )}
        {parseInt(formData.response_type_id) === zeroNumber ? (
          <div className="compulsoryContainer">
            <div className="directAnswerSingleInput">
              <label className="searchQuestionLabel">Answer</label>
              <input
                className="searchQuestionSelect"
                type="number"
                min="0"
                max="10"
                onChange={(e) => handleChangeResponse(e)}
                value={formData.answer_json.correct_answer}
              ></input>
            </div>
          </div>
        ) : (
          ''
        )}
        {parseInt(formData.response_type_id) === MAQNumber ? (
          <div className="compulsoryContainerMAQ">
            <label className="searchQuestionLabelMAQ">Options</label>
            {Array.apply(null, { length: numOptionValue }).map((e, keyMAQCount) => (
              <MAQ key={keyMAQCount} alphabet={alphabet[keyMAQCount]} onChange={handleChangeOption} name="MAQ" array={MAQArray} />
            ))}
          </div>
        ) : (
          ''
        )}
        {parseInt(formData.response_type_id) === matchingNumber ? (
          <div className="compulsoryContainer">
            <label className="searchQuestionLabelMat">Matching</label>
            {Array.apply(null, { length: matchingCount }).map((e, keyMatchingCount) => (
              <Matching
                key={keyMatchingCount}
                number={number[keyMatchingCount]}
                alphabet={alphabet[keyMatchingCount]}
                onChange={handleChangeOption}
                name="Matching"
                onClick={(e) => handleRemove(e, keyMatchingCount, 'Matching')}
                value={
                  responseMatchingData[keyMatchingCount] ? responseMatchingData[keyMatchingCount].right_hand_option : undefined
                }
              />
            ))}
            <div className="btnDivMatching">
              <button onClick={incrementMatching} className="btnResponse">
                +
              </button>
            </div>
          </div>
        ) : (
          ''
        )}

        {parseInt(formData.response_type_id) === oneWordNumber ? (
          <DirectAnswer
            label="One Word Answer"
            name="responseOneWordAnswer"
            onChange={handleChangeResponse}
            value={formData.answer_json.correct_answer}
          />
        ) : (
          ''
        )}
        {parseInt(formData.response_type_id) === FITBNumber ? (
          <DirectAnswer
            label="FITB"
            name="responseFITB"
            onChange={handleChangeResponse}
            value={formData.answer_json.correct_answer}
          />
        ) : (
          ''
        )}
        {parseInt(formData.response_type_id) === textNumber ? (
          <div>
            <div className="compulsoryContainer">
              <div className="directAnswerSingleInput">
                <label className="searchQuestionLabel">Text</label>
                <textarea
                  name="responseText"
                  className="searchQuestionSelectText"
                  onChange={(e) => handleChangeResponse(e)}
                  value={formData.answer_json.correct_answer}
                />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {parseInt(formData.response_type_id) === voiceNumber ? (
          <div className="compulsoryContainer">
            <div className="directAnswerSingleInput">
              <label className="searchQuestionLabel">Voice</label>
              <input
                className="fileChooseRespone"
                type="file"
                accept=".mp3"
                onChange={(e) => handleResponceFile(e, 'Voice')}
              ></input>
              <button type="button" className="btnFilesUpload" onClick={(e) => responseUploadFile(e, 'Voice')}>
                Upload
              </button>
            </div>
            {resUploadLoading ? (
              <div>
                <ReactLoading type="spinningBubbles" color="#68dff0" className="fileResUploadedSpin" />
                <h5 className="fileResUploadedText">Uploding</h5>
              </div>
            ) : null}
            {resVoice ? <p className="fileImageUploaded">File has been Uploaded</p> : null}
          </div>
        ) : (
          ''
        )}
        {parseInt(formData.response_type_id) === imageNumber ? (
          <div className="compulsoryContainer">
            <div className="directAnswerSingleInput">
              <label className="searchQuestionLabel">Image</label>
              <input
                className="fileChooseRespone"
                type="file"
                accept=".jpg, .png"
                onChange={(e) => handleResponceFile(e, 'Image')}
              ></input>
              <button type="button" className="btnFilesUpload" onClick={(e) => responseUploadFile(e, 'Image')}>
                Upload
              </button>
            </div>
            {resUploadLoading ? (
              <div>
                <ReactLoading type="spinningBubbles" color="#68dff0" className="fileResUploadedSpin" />
                <h5 className="fileResUploadedText">Uploding</h5>
              </div>
            ) : null}
            {resImgae ? <p className="fileImageUploaded">File has been Uploaded</p> : null}
          </div>
        ) : (
          ''
        )}
        {parseInt(formData.response_type_id) === videoNumber ? (
          <div className="compulsoryContainer">
            <div className="directAnswerSingleInput">
              <label className="searchQuestionLabel">Video</label>
              <input
                className="fileChooseRespone"
                type="file"
                accept=".mp4"
                onChange={(e) => handleResponceFile(e, 'Video')}
              ></input>
              <button type="button" className="btnFilesUpload" onClick={(e) => responseUploadFile(e, 'Video')}>
                Upload
              </button>
            </div>
            {resUploadLoading ? (
              <div>
                <ReactLoading type="spinningBubbles" color="#68dff0" className="fileResUploadedSpin" />
                <h5 className="fileResUploadedText">Uploding</h5>
              </div>
            ) : null}
            {resVideo ? <p className="fileImageUploaded">File has been Uploaded</p> : null}
          </div>
        ) : (
          ''
        )}
        {parseInt(formData.response_type_id) === fileNumber ? (
          <div className="compulsoryContainer">
            <div className="directAnswerSingleInput">
              <label className="searchQuestionLabel">File</label>
              <input
                className="fileChooseRespone"
                type="file"
                accept=".pdf, .doc, .docx"
                onChange={(e) => handleResponceFile(e, 'Files')}
              ></input>
              <button type="button" className="btnFilesUpload" onClick={(e) => responseUploadFile(e, 'Files')}>
                Upload
              </button>
            </div>
            {resUploadLoading ? (
              <div>
                <ReactLoading type="spinningBubbles" color="#68dff0" className="fileResUploadedSpin" />
                <h5 className="fileResUploadedText">Uploding</h5>
              </div>
            ) : null}
            {resFile ? <p className="fileImageUploaded">File has been Uploaded</p> : null}
          </div>
        ) : (
          ''
        )}
        {parseInt(formData.response_type_id) === 13 ? (
          <OptionsRight onChange={handleChangeOptionYesNo} optionOne="True" optionTwo="False" />
        ) : (
          ''
        )}
        {parseInt(formData.response_type_id) === 14 ? (
          <div>
            <div className="compulsoryContainer">
              <div className="directAnswerSingleInput">
                <label className="searchQuestionLabel">Short Answer</label>
                <textarea name="responseText" className="searchQuestionSelectText" onChange={(e) => handleChangeResponse(e)} />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
