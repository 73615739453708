import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import './pagination.css';

function Pagination(props) {
  var numberOfPages;
  if (props.numberOfRows !== 0) {
    numberOfPages = Math.ceil(props.numberOfRows / (props?.limit || 10));
    // console.log(numberOfPages);
    // console.log(props.pageNumber);
  }

  return (
    <div>
      {+props.numberOfRows === 0 ? null : (
        <div className="paginationContainer">
          <button
            onClick={() => props.paginationClick(1, 0)}
            disabled={props.pageNumber === 1 ? true : false}
            className="paginationArrows"
          >
            <KeyboardDoubleArrowLeftIcon fontSize="small" />
          </button>
          <button
            onClick={() => props.paginationClick(props.pageNumber - 1, props.offSet - 10)}
            disabled={props.pageNumber === 1 ? true : false}
            className="paginationArrows"
          >
            <ArrowBackIosIcon fontSize="small" />
          </button>
          <div className="numberContainer">
            {numberOfPages < 6 ? (
              Array.apply(null, { length: numberOfPages }).map((e, arrayKey) => {
                return (
                  <button
                    disabled={props.pageNumber === arrayKey + 1}
                    className={props.pageNumber === arrayKey + 1 ? 'seleNumber' : 'notSeleNumber'}
                    key={arrayKey}
                    onClick={() => props.paginationClick(arrayKey + 1, arrayKey * 10)}
                  >
                    {arrayKey + 1}
                  </button>
                );
              })
            ) : props.pageNumber < 6 ? (
              <div className="peginationNumberDiv">
                {Array.apply(null, { length: 5 }).map((e, arrayKey) => {
                  return (
                    <button
                      disabled={props.pageNumber === arrayKey + 1}
                      className={props.pageNumber === arrayKey + 1 ? 'seleNumber' : 'notSeleNumber'}
                      key={arrayKey}
                      onClick={() => props.paginationClick(arrayKey + 1, arrayKey * 10)}
                    >
                      {arrayKey + 1}
                    </button>
                  );
                })}
                <p className="notSeleNumberP">. . .</p>
                <button className="notSeleNumber" onClick={() => props.paginationClick(numberOfPages, (numberOfPages - 1) * 10)}>
                  {numberOfPages}
                </button>
              </div>
            ) : props.pageNumber > 5 &&
              props.pageNumber !== numberOfPages - 2 &&
              props.pageNumber !== numberOfPages - 1 &&
              props.pageNumber !== numberOfPages - 3 &&
              props.pageNumber !== numberOfPages ? (
              <div className="peginationNumberDiv">
                <button className="notSeleNumber" onClick={() => props.paginationClick(1, 0)}>
                  1
                </button>
                <p className="notSeleNumberP">. . .</p>
                <button
                  className="notSeleNumber"
                  onClick={() => props.paginationClick(props.pageNumber - 1, (props.pageNumber - 2) * 10)}
                >
                  {props.pageNumber - 1}
                </button>
                <button className="seleNumber" onClick={() => props.paginationClick(props.pageNumber, (props.pageNumber - 1) * 10)}>
                  {props.pageNumber}
                </button>
                <button
                  className="notSeleNumber"
                  onClick={() => props.paginationClick(props.pageNumber + 1, props.pageNumber * 10)}
                >
                  {props.pageNumber + 1}
                </button>
                <p className="notSeleNumberP">. . .</p>
                <button className="notSeleNumber" onClick={() => props.paginationClick(numberOfPages, (numberOfPages - 1) * 10)}>
                  {numberOfPages}
                </button>
              </div>
            ) : (
              <div className="peginationNumberDiv">
                <button className="notSeleNumber" onClick={() => props.paginationClick(1, 0)}>
                  1
                </button>
                <button className="notSeleNumber" onClick={() => props.paginationClick(2, 10)}>
                  2
                </button>
                <p className="notSeleNumberP">. . .</p>
                {props.pageNumber === numberOfPages - 3 ? (
                  <button
                    className="seleNumber"
                    onClick={() => props.paginationClick(props.pageNumber, (props.pageNumber - 1) * 10)}
                  >
                    {props.pageNumber}
                  </button>
                ) : (
                  <button
                    className="notSeleNumber"
                    onClick={() => props.paginationClick(numberOfPages - 3, (numberOfPages - 4) * 10)}
                  >
                    {numberOfPages - 3}
                  </button>
                )}
                {props.pageNumber === numberOfPages - 2 ? (
                  <button
                    className="seleNumber"
                    onClick={() => props.paginationClick(props.pageNumber, (props.pageNumber - 1) * 10)}
                  >
                    {props.pageNumber}
                  </button>
                ) : (
                  <button
                    className="notSeleNumber"
                    onClick={() => props.paginationClick(numberOfPages - 2, (numberOfPages - 3) * 10)}
                  >
                    {numberOfPages - 2}
                  </button>
                )}
                {props.pageNumber === numberOfPages - 1 ? (
                  <button
                    className="seleNumber"
                    onClick={() => props.paginationClick(props.pageNumber, (props.pageNumber - 1) * 10)}
                  >
                    {props.pageNumber}
                  </button>
                ) : (
                  <button
                    className="notSeleNumber"
                    onClick={() => props.paginationClick(numberOfPages - 1, (numberOfPages - 2) * 10)}
                  >
                    {numberOfPages - 1}
                  </button>
                )}
                {props.pageNumber === numberOfPages ? (
                  <button
                    className="seleNumber"
                    onClick={() => props.paginationClick(props.pageNumber, (props.pageNumber - 1) * 10)}
                  >
                    {props.pageNumber}
                  </button>
                ) : (
                  <button className="notSeleNumber" onClick={() => props.paginationClick(numberOfPages, (numberOfPages - 1) * 10)}>
                    {numberOfPages}
                  </button>
                )}
              </div>
            )}
          </div>
          <button
            onClick={() => props.paginationClick(props.pageNumber + 1, props.offSet + 10)}
            disabled={props.pageNumber === numberOfPages ? true : false}
            className="paginationArrows"
          >
            <ArrowForwardIosIcon fontSize="small" />
          </button>
          <button
            onClick={() => props.paginationClick(numberOfPages, (numberOfPages - 1) * 10)}
            disabled={props.pageNumber === numberOfPages ? true : false}
            className="paginationArrows"
          >
            <KeyboardDoubleArrowRightIcon fontSize="small" />
          </button>
        </div>
      )}
    </div>
  );
}

export default Pagination;
