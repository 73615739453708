import { boardList } from '../../../../api/boards';
import { fetchCourse, fetchSubject, fetchSubSubject, fetchSubTopic, fetchTopic } from '../../../../api/institution';
import { fetchObjective } from '../../../../api/objective';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

export default async function fetchResults(
  institution_id,
  setStartUpLoad,
  setState,
  board_id,
  course_id,
  subject_id,
  sub_subject_id,
  topic_1_id,
  topic_2_id,
  taxonomy_id,
) {
  try {
    //   const getQuestionType = await fetchQuestionType();
    //   const getAccessLevel = await fetchAccessLevel();
    //   const getResponseType = await fetchResponseType();
    //   const getEvaluation = await fetchEvalType();
    //   const getInputType = await fetchAnsType();
    //   const getTaxonomies = await fetchTaxonomy();
    //   const getMediums = await fetchMedium();
    const getBoard = await boardList();
    const getCourse = await fetchCourse(institution_id, board_id);
    const getSubject = await fetchSubject(institution_id, board_id, course_id);
    const getSubSubject = await fetchSubSubject(institution_id, board_id, course_id, subject_id);
    const getTopic = await fetchTopic(institution_id, board_id, course_id, subject_id, sub_subject_id);
    console.log(topic_1_id);
    const getSubTopic = await fetchSubTopic(institution_id, board_id, course_id, subject_id, sub_subject_id, topic_1_id);
    const getSubTopic2 = await fetchSubTopic(institution_id, board_id, course_id, subject_id, sub_subject_id, topic_2_id);
    const getObjectives = await fetchObjective(taxonomy_id);
    setState((prevState) => ({
      ...prevState,
      // questionsType: getQuestionType.data.data,
      // answerInput: getInputType.data.data,
      // responseType: getResponseType.data.data,
      // evaluation: getEvaluation.data.data,
      // accessLevel: getAccessLevel.data.data,
      // taxonomies: getTaxonomies.data.data,
      // mediums: getMediums.data.data,
      boards: getBoard.data.data,
      courses: getCourse.data.data,
      subjects: getSubject.data.data,
      subSubjects: getSubSubject.data.data,
      topics: getTopic.data.data,
      subTopics: getSubTopic.data.data,
      subTopics2: getSubTopic2.data.data,
      objectives: getObjectives.data.data,
    }));
    setStartUpLoad(false);
  } catch (err) {
    errorHandle(err);
    setStartUpLoad(false);
  }
}
