import { createQuestion } from '../../../../api/questions';
// import { error } from "../../../SubComponents/Error/error";
import { uploadQuestionFunction } from '../../../../api/questions';
import { toast } from 'react-toastify';

export async function translateSubmit(formData, setStartUpLoad, fileInfo, setQuestionCreate) {
  const userId = window.localStorage.getItem('userId');
  setStartUpLoad(true);
  const fileFullDetails = fileInfo.fullDetails;
  const sourceFile = fileInfo.fullDetailsSource;
  let formDataImage = new FormData();
  formDataImage.append('question_file_path', fileFullDetails);
  formDataImage.append('source_file_path', sourceFile);
  try {
    const uploadTranslate = await uploadQuestionFunction(fileInfo.fileTypeId, userId, formDataImage);
    if (uploadTranslate.data && uploadTranslate.data.data) {
      const newFormData = {
        ...formData,
        image_path: fileInfo.fileType === 'Image' ? uploadTranslate.data.data.inputs.question : uploadTranslate.data.data.images[0],
        question_group_source_file_path: uploadTranslate.data.data.inputs.source,
        added_by_user_id: +userId,
        answer_json: JSON.stringify(formData.answer),
        is_enabled: true,
        topic_2_id: formData.topic_2_id ? formData.topic_2_id : -1,
        sub_topic_2_id: formData.sub_topic_2_id ? formData.sub_topic_2_id : -1,
      };
      console.log(newFormData);
      const submitQuestion = await createQuestion(newFormData);
      console.log(submitQuestion);
      setQuestionCreate(true);
    } else {
      toast.error('Error, Try again');
    }
    setStartUpLoad(false);
  } catch (err) {
    setStartUpLoad(false);
    if (err.response && err.response.data) {
      console.log(err.response.data.message);
      toast.error(err.response.data.message);
    } else {
      toast.error('Error');
      console.log(err);
    }
  }
}
