import errorHandle from '../../../../utils/errorHandle/errorHandle';
import { toast } from 'react-toastify';
import { rejectQuestion } from '../../../../api/questions';

export default async function addHandleReject(rejectReason, setStartUpLoad, question_id, history, assigneMC) {
  if (rejectReason === '') {
    toast.error('Reject reason must be specified');
  } else {
    setStartUpLoad(true);
    console.log(rejectReason);
    var rejectData = { is_rejected: true, reject_reason: rejectReason };
    try {
      var rejectQue = await rejectQuestion(question_id, rejectData);
      console.log(rejectQue.data.data);
      toast.success('Reject question successful');
      assigneMC();
      window.localStorage.setItem('previousPath', 'addDetails');
      setTimeout(() => history('/admin/view-questions'), 1500);
      setStartUpLoad(false);
    } catch (error) {
      errorHandle(error);
      setStartUpLoad(false);
    }
  }
}
