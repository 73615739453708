import linkGen from './linkGen';
import { fetchQuestions } from '../../../../api/questions';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

export default async function handlePagination(offSet, setQuestionList, setStartUpLoad, formData, questionType) {
  console.log(offSet);
  // const AuthSub1 = {
  //   headers: {
  //     authorization: "Bearer " + token,
  //   },
  // };
  let linkFinal = linkGen(formData, questionType);
  console.log(linkFinal);
  try {
    // const getQuestions = await Axios.get(
    //   `${BASE_URL}/qb/questions/search?${linkFinal}&limit=10&offset=${offSet}`,
    //   {
    //     headers: AuthSub1.headers,
    //   }
    // );
    const getQuestions = await fetchQuestions(linkFinal, offSet);
    console.log(getQuestions.data.data);
    setQuestionList(getQuestions.data.data);
    setStartUpLoad(false);
  } catch (error) {
    errorHandle(error);
    setStartUpLoad(false);
  }
}
