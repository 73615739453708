import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import './backlog.css';
import { ToastContainer } from 'react-toastify';
import configuration from '../../config/Config';
import Pagination from '../../SubComponents/Pagination/Pagination';
import Card from '../../SubComponents/Card/Card';
import backlogData from './backlogFunctions/backlogData';
import deleteBacklog from './backlogFunctions/deleteBacklog';
import handlePagination from './backlogFunctions/handlePaginationBack';
import DeletePopup from '../../SubComponents/DeletePopup/DeletePopup';
import Breadcrums from '../../SubComponents/breadcrum/breadcrum';

var iniFilePath = configuration.digitalOceanSpaces;
var linkprv;

function Backlog() {
  const history = useNavigate();
  // const [link, setLink] = useState("");
  // const [redirect, setRedirect] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [startUpLoad, setStartUpLoad] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(parseInt(window.innerWidth / 247.5) * 2);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const initialRender = useRef(true);
  const [popupBacklog, setPopupBacklog] = useState(false);
  const selQuestionDelete = useRef('');
  // const [noData, setNoData] = useState(false);

  console.log(questionData);
  const getType = () => {
    if (window?.innerWidth) {
      let mq = window?.innerWidth / 247.5;
      setLimit(parseInt(mq) * 2);
    }
  };
  useEffect(() => {
    getType();
  }, []);
  const userId = window.localStorage.getItem('userId');

  async function handleDelete(selQuestion) {
    selQuestionDelete.current = selQuestion;
    setPopupBacklog(true);
  }

  function okDelete() {
    deleteBacklog(selQuestionDelete.current, setStartUpLoad);
    setPopupBacklog(false);
  }

  function cancel() {
    setPopupBacklog(false);
  }

  function handleAddDetails(selLink, questionId, migrateQuestionId) {
    console.log(selLink, questionId, migrateQuestionId);
    linkprv = iniFilePath + selLink;
    // window.localStorage.setItem("questionId", questionId);
    window.localStorage.setItem('migrateQuestionId', migrateQuestionId);
    window.localStorage.setItem('previousPath', 'backlog');
    // window.localStorage.setItem("previousImageLink", linkprv);
    history('/admin/add-details', {
      state: { questionId, migrateQuestionId, linkprv },
    });
    // setRedirect(true);
  }
  console.log(numberOfQuestions, pageNumber, 'numberOfQuestions');
  function paginationClick(number, offSetNumber) {
    if (Math.ceil(numberOfQuestions / limit) > 1) {
      console.log(number, numberOfQuestions, offSetNumber, 'numberOfQuestions');
      if (number > 1) {
        setPageNumber(+number);
        // setOffSet(+offSetNumber);
        setOffSet(limit * (number - 1));
      } else {
        setOffSet(0);
        setPageNumber(1);
      }
      setStartUpLoad(true);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log(limit, 'limit');
    backlogData(userId, offSet, setNumberOfQuestions, setQuestionData, setStartUpLoad, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialRender.current === true) {
      initialRender.current = false;
    } else {
      handlePagination(offSet, userId, setQuestionData, setStartUpLoad, limit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSet]);

  // if (redirect) {
  //   return <Redirect to="/admin/edit-details" />;
  // }

  return (
    <div>
      {startUpLoad ? (
        <div>
          <ReactLoading type="spin" color="#68dff0" className="startLoadSpin" />
        </div>
      ) : null}
      <div>
        <div>
          <Breadcrums title="Backlogs" />
        </div>
        <div className="backlogHeading">
          <h3 className="backlogQueH3">Backlogs</h3>
          <p className="backlogTotal">Total: {numberOfQuestions}</p>
        </div>
        <div className="backlogContainer">
          <div>
            {questionData.length !== 0 ? (
              <div className="cardContainer">
                {questionData.map((row, rowKey) => {
                  return <Card row={row} key={rowKey} handleAddDetails={handleAddDetails} handleDelete={handleDelete} />;
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div className="paginationDivBacklog">
          <Pagination
            paginationClick={paginationClick}
            numberOfRows={numberOfQuestions}
            offSet={offSet}
            limit={limit}
            pageNumber={pageNumber}
          />
        </div>
      </div>
      <DeletePopup cancel={cancel} popup={popupBacklog} okDelete={okDelete} />
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Backlog;
