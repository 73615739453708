import { fetchAccessLevel, fetchResponseType, fetchEvalType, fetchQuestionType, fetchAnsType } from '../../../../api/qbMasterData';
import { fetchMedium } from '../../../../api/mediums';
import { fetchTaxonomy } from '../../../../api/taxonomy';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

const fetchFirstResults = async (setState, setStartUpLoad) => {
  console.log('first');
  try {
    const getQuestionType = await fetchQuestionType();
    const getAccessLevel = await fetchAccessLevel();
    const getResponseType = await fetchResponseType();
    const getEvaluation = await fetchEvalType();
    const getInputType = await fetchAnsType();
    const getTaxonomies = await fetchTaxonomy();
    const getMediums = await fetchMedium();

    setState((prevState) => ({
      ...prevState,
      questionsType: getQuestionType.data.data,
      answerInput: getInputType.data.data,
      responseType: getResponseType.data.data,
      evaluation: getEvaluation.data.data,
      accessLevel: getAccessLevel.data.data,
      taxonomies: getTaxonomies.data.data,
      mediums: getMediums.data.data,
    }));
  } catch (err) {
    errorHandle(err);
    setStartUpLoad(false);
  }
};

export default fetchFirstResults;
