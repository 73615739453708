import { getBacklogCountFunction } from '../../../../api/questions';
import { getBacklogFunction } from '../../../../api/questions';
import { toast } from 'react-toastify';

export default async function backlogData(userId, offSet, setNumberOfQuestions, setQuestionData, setStartUpLoad, limit) {
  try {
    const getNumberOfQuestions = await getBacklogCountFunction(userId);
    console.log(getNumberOfQuestions.data.data[0].count);
    if (+getNumberOfQuestions.data.QuestionTotalCounts !== 0) {
      const getBacklogs = await getBacklogFunction(userId, offSet, limit);
      setNumberOfQuestions(getNumberOfQuestions.data.data[0].count);
      setQuestionData(getBacklogs.data.data);
      setStartUpLoad(false);
    }
    if (+getNumberOfQuestions.data.QuestionTotalCounts === 0) {
      setNumberOfQuestions(0);
      setStartUpLoad(false);
    }
  } catch (err) {
    if (err.response && err.response.data) {
      console.log(err.response.data.message);
      toast.error(err.response.data.message);
    } else {
      toast.error('Server Error');
    }
    setStartUpLoad(false);
  }
}
