import React, { useState, useEffect } from 'react';
import Axios from 'axios';
// import { BASE_URL } from "../../config/Api";
import { BASE_URL_MIGRATE } from '../../config/Api';
import ReactLoading from 'react-loading';
import Pdf from '@mikecousins/react-pdf';
import './migrateMap.css';
import { fetchAccessLevel } from '../../../api/qbMasterData';
import { boardList } from '../../../api/boards';
import { fetchCourse } from '../../../api/institution';
import { fetchMedium } from '../../../api/mediums';

var pageNum = 1;
var board_id = 1;
var institution_id = 0;
var linkSel = [];
var queSel = [];
var MCQCrtArray = [];
var MCQNrArray = [];
var answerArray = [];
var tempArray;
var tempAlpha;
var tempData;
var tempNum;
var responseType = 1;
var answerInput = 2;
var evaluation = 2;
var questionsType = 1;

function MigrateMap() {
  const [state, setState] = useState({
    boards: [],
    courses: [],
    mediums: [],
    accessLevel: [],
  });
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [questionList, setQuestionList] = useState([]);
  // const [offSet, setOffSet] = useState(0);
  const offSet = 0;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  // const [selLink, setSelLink] = useState("");
  const [loadingCal, SetLoadingCal] = useState(false);

  // const token = window.localStorage.getItem("token");
  // const userId = window.localStorage.getItem("userId");
  // const Auth = {
  //   headers: {
  //     Authorization: "Bearer " + token,
  //   },
  // };
  console.log(state);

  //useEffect to load ids of the inputs
  useEffect(() => {
    async function fetchIds() {
      try {
        // const getAccessLevel =
        //   await Axios.get(`${BASE_URL}/qb/access_levels`, Auth);
        const getAccessLevel = await fetchAccessLevel();
        // const getMediums = await Axios.get(`${BASE_URL}/mediums`, Auth);
        const getMediums = await fetchMedium();
        // const getBoard = await Axios.get(`${BASE_URL}/boards`, Auth);
        const getBoard = await boardList();
        // const getCourse = await Axios.get(
        //     `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses`,
        //     Auth
        //   );
        const getCourse = await fetchCourse(institution_id, board_id);

        setState((prevState) => ({
          ...prevState,
          accessLevel: getAccessLevel.data.data,
          mediums: getMediums.data.data,
          boards: getBoard.data.data,
          courses: getCourse.data.data,
        }));
      } catch (err) {
        console.log(err.number);
      }
    }
    fetchIds();
    //eslint-disable-next-line
  }, []);

  //function handle click on the table row
  function handleClick(clickedLink) {
    console.log(clickedLink);
    // setSelLink(clickedLink);
  }

  function handleSelectAll(e) {
    // setMigrateComplete(false);
    // setMigrateLoding(false);
    // setError(false);
    var check = e.target.checked;
    console.log(check);
    if (check === true) {
      linkSel = [];
      queSel = [];
      // setSelectAll(true);
      let tempList = questionList.map((question) => {
        return { ...question, isChecked: true };
      });
      console.log(tempList);
      setQuestionList(tempList);
      for (var i = 0; i < numberOfQuestions; i++) {
        if (questionList[i]) {
          linkSel = [...linkSel, questionList[i].PhotoPath];
          queSel = [...queSel, questionList[i].QuestionID];
          console.log(questionList[i].PhotoPath);
        }
      }
    } else if (check === false) {
      linkSel = [];
      queSel = [];
      let tempList = questionList.map((question) => {
        return { ...question, isChecked: false };
      });
      console.log(tempList);
      setQuestionList(tempList);
      // setSelectAll(false);
    }
  }

  function handleCheckbox(e, row) {
    // if(selectAll === true){
    //   setSelectAll(false);
    // }
    // setMigrateComplete(false);
    // setMigrateLoding(false);
    // setError(false);
    const valueSele = e.target.checked;
    const nameSele = parseInt(e.target.name);
    console.log(valueSele, nameSele);
    if (valueSele === true) {
      linkSel = [
        ...linkSel,
        {
          accessLevel: row.AccessLevel,
          course: row.Class,
          description: row.Description,
          difficultyLevel: row.DifficultyLevel,
          medium: row.Language,
          shortDescription: row.Note,
          answer_json: row.OptionList,
          questionID: row.QuestionID,
          questionNumber: row.QuestionNumber,
          reference: row.Reference,
          //   subject: row.Subject,
          board: row.Syllabus,
          timeRequired: row.TimeRequired,
          responseType: responseType,
          answerInput: answerInput,
          evaluation: evaluation,
          questionsType: questionsType,
        },
      ];
      queSel = [...queSel, nameSele];
      let tempList = questionList.map((question) => {
        // return (question.QuestionID === nameSele
        //   ? { ...question, isChecked: true }
        //   : question);
        if (question.QuestionID === nameSele) {
          return { ...question, isChecked: true };
        } else {
          return question;
        }
      });
      console.log(tempList);
      setQuestionList(tempList);
    }
    if (valueSele === false) {
      var index = linkSel.indexOf(row.PhotoPath);
      var indexQue = queSel.indexOf(nameSele);
      linkSel.splice(index, 1);
      queSel.splice(indexQue, 1);
      let tempList = questionList.map((question) => {
        return question.QuestionID === nameSele ? { ...question, isChecked: false } : question;
      });
      console.log(tempList);
      setQuestionList(tempList);
    }
    console.log(linkSel);
  }

  //function to search questions
  async function handleSubmit() {
    linkSel = [];
    // setMigrateComplete(false);
    // setMigrateLoding(false);
    // setError(false);
    setSubmitLoading(true);
    setNumberOfQuestions(0);
    // setQuestionList([]);
    try {
      const getNumberOfQuestions = await await Axios.get(
        `${BASE_URL_MIGRATE}/GetQuestionTotalCount?LanguageID=1&SyllabusID=1&ClassID=6&SubjectID=2&SubSubjectID=0&TopicID=0&SubtopicID=0`,
      );
      const getQuestionList = await await Axios.get(
        `${BASE_URL_MIGRATE}/GetQuestionAllList?LanguageID=1&SyllabusID=1&ClassID=6&SubjectID=2&SubSubjectID=0&TopicID=0&SubtopicID=0&OFFSET=${offSet}&FETCHNEXT=${getNumberOfQuestions.data.QuestionTotalCounts}`,
      );
      setNumberOfQuestions(getNumberOfQuestions.data.QuestionTotalCounts);
      console.log(getQuestionList.data);
      setQuestionList(getQuestionList.data);
      setSubmitLoading(false);
      window.scrollTo(0, 480);
    } catch (err) {
      console.log(err);
      console.log(err.response.status);
      if (err.response.status === 404) {
        setNoData(true);
        setSubmitLoading(false);
      }
    }
  }

  //function to map the migrate data to required formate
  function handleAddToBacklog() {
    SetLoadingCal(true);
    for (var i = 0; i < linkSel.length; i++) {
      dataMap(i);
    }
    // answerArray = [];
    function dataMap(i) {
      state.accessLevel.map((set) => {
        // if (linkSel[i].accessLevel === set.display_name) {
        //   linkSel[i].accessLevel = set.id;
        // }
        return linkSel[i].accessLevel === set.display_name && (linkSel[i].accessLevel = set.id);
      });
      if (linkSel[i].board === 'CBSE ') {
        linkSel[i].board = 1;
      }
      state.mediums.map((LanguageList) => {
        // if (linkSel[i].medium === LanguageList.medium_name) {
        //   linkSel[i].medium = LanguageList.medium_id;
        // }
        return linkSel[i].medium === LanguageList.medium_name && (linkSel[i].medium = LanguageList.medium_id);
      });
      tempArray = linkSel[i].answer_json;
      console.log(tempArray);
      MCQCrtArray = tempArray.map((num) => (num.Answer === 'True' ? num.Options : 0));
      MCQNrArray = tempArray.map((num) => (num.NeartoCorrect === 'True' ? num.Options : 0));
      console.log(MCQCrtArray, MCQNrArray);
      tempAlpha = MCQCrtArray;
      MCQCrtArray = tempAlpha.map((alpha) => {
        if (alpha === 'A') {
          return 1;
        }
        if (alpha === 'B') {
          return 2;
        }
        if (alpha === 'C') {
          return 3;
        }
        if (alpha === 'D') {
          return 4;
        } else {
          return 0;
        }
      });
      tempAlpha = MCQNrArray;
      MCQNrArray = tempAlpha.map((alpha) => {
        if (alpha === 'A') {
          return 1;
        }
        if (alpha === 'B') {
          return 2;
        }
        if (alpha === 'C') {
          return 3;
        }
        if (alpha === 'D') {
          return 4;
        } else {
          return 0;
        }
      });
      console.log(MCQCrtArray, MCQNrArray);
      tempNum = MCQCrtArray;
      tempNum.map((number) => {
        number !== 0 &&
          (tempData = [
            {
              option_text: number,
              is_correct_option: true,
              is_near_correct_option: false,
            },
          ]);
        return number !== 0 && (answerArray = [tempData]);
      });
      tempNum = MCQNrArray;
      tempNum.map((number) => {
        number !== 0 &&
          (tempData = [
            {
              option_text: number,
              is_correct_option: false,
              is_near_correct_option: true,
            },
          ]);
        return number !== 0 && (answerArray = [tempData]);
      });
      linkSel[i].answer_json = answerArray;
    }
    SetLoadingCal(false);
    console.log(linkSel);
  }

  return (
    <div>
      <div className="compulsoryContainerMap">
        <button
          type="submit"
          className="addSubmit"
          onClick={() => {
            handleSubmit();
            setNoData(false);
          }}
        >
          Search
        </button>
        {submitLoading ? (
          <div className="loadingDiv">
            <ReactLoading type="spinningBubbles" color="#68dff0" className="reactLoadingIcon" />
            <h5 className="loadingText">Loading</h5>
          </div>
        ) : null}
        {noData ? (
          <div className="loadingDiv">
            <h5 className="loadingTextData">Data is not available</h5>
          </div>
        ) : null}
      </div>
      <div>
        {questionList !== undefined && numberOfQuestions !== 0 ? (
          <div className="compulsoryContainer">
            <h4 className="compulsoryHeading">{numberOfQuestions} Questions</h4>
            <div className="selectDiv">
              <div className="selectAllDiv">
                <input
                  type="checkbox"
                  onChange={(e) => handleSelectAll(e)}
                  className="selectAllInput"
                  checked={!questionList.some((question) => question.isChecked !== true)}
                ></input>
                <p className="selectAllP">Select All</p>
              </div>
              <div>{linkSel.length === 0 ? null : <p className="selectedP">{linkSel.length} Selected</p>}</div>
            </div>
            <div className="tableContainerMigrate">
              {/* {loading ? (
                <div className="loadingDivMigrate">
                  <ReactLoading
                    type="spinningBubbles"
                    color="#68dff0"
                    className="reactLoadingIconMigrate"
                  />
                  <h5 className="loadingText">Loading</h5>
                </div>
              ) : ( */}

              <table className="migrateTable">
                <thead>
                  <tr>
                    <th>Sl. No.</th>
                    <th>Select</th>
                    <th>Question #</th>
                    <th className="child2Migrate">Old Question Code</th>
                    <th className="child3Migrate">Access Level</th>
                    <th>Question Type</th>
                    <th>Photo Path</th>
                  </tr>
                </thead>
                <tbody>
                  {questionList.map((row, questionKey) => {
                    return (
                      <tr key={questionKey} onClick={(e) => handleClick(row.PhotoPath)}>
                        <td>{questionKey + 1}.</td>
                        <td>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckbox(e, row)}
                            checked={row.isChecked ? row.isChecked : false}
                            name={row.QuestionID}
                          ></input>
                        </td>
                        <td>{row.QuestionID}</td>
                        <td>{row.QuestionNumber}</td>
                        <td>{row.AccessLevel}</td>
                        <td>{row.QuestionType}</td>
                        <td>
                          <Pdf file={row.PhotoPath} page={pageNum} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="migrateAddContainer">
              <button className="migrateButton" onClick={handleAddToBacklog}>
                Add To My Backlogs
              </button>
              <div className="migrateLoading">
                {loadingCal ? (
                  <div className="loadingDivMigrateAdd">
                    <ReactLoading type="spinningBubbles" color="#68dff0" className="reactLoadingIconAdd" />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default MigrateMap;
