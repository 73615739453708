import { getQueMetadata } from '../../../../api/user';
import { questionMetadata } from '../../../../api/user';
import errorHandle from '../../../../utils/errorHandle/errorHandle';
import fetchResults from './fetchResults';

export default async function addMetaData(
  formData,
  setStartUpLoad,
  previousImageLink,
  setFormData,
  institution_id,
  setState,
  assigneeBoard,
  questionIdData,
) {
  const userId = window.localStorage.getItem('userId');
  const previousPath = window.localStorage.getItem('previousPath');
  const questionIdBacklog = questionIdData;
  // const previousImageLink = window.localStorage.getItem('previousImageLink');
  try {
    const submitedFormData = await getQueMetadata(userId, 'question_defaults');
    console.log(submitedFormData.data.data[0]);
    if (previousPath === 'backlog') {
      if (submitedFormData.data.data.length === 0) {
        async function meta() {
          const pref = {
            pref_key: 'question_defaults',
            pref_value_json: JSON.stringify(formData),
          };
          try {
            let metaData = await questionMetadata(userId, pref);
            console.log(metaData);
            setStartUpLoad(false);
          } catch (err) {
            errorHandle(err);
            setStartUpLoad(false);
          }
        }
        meta();
      }
      if (submitedFormData.data.data.length !== 0) {
        const subData = JSON.parse(submitedFormData.data.data[0].pref_value_json);
        if (subData.answer_json !== '' && subData.answer_json !== '{}') {
          console.log(subData);
          console.log(submitedFormData.data.data[0].pref_value_json);
          setFormData((prevState) => {
            return {
              ...prevState,
              question_file_id: questionIdBacklog,
              copiable_data_file_path: subData.copiable_data_file_path,
              reference: subData.reference,
              question_type_id: subData.question_type_id,
              board_id: subData.board_id,
              course_id: subData.course_id,
              subject_id: subData.subject_id,
              sub_subject_id: subData.sub_subject_id,
              topic_1_id: subData.topic_1_id,
              sub_topic_1_id: subData.sub_topic_1_id,
              topic_2_id: subData.topic_2_id,
              sub_topic_2_id: subData.sub_topic_2_id,
              // difficulty_level: subData.difficulty_level,
              answer_input_type_id: subData.answer_input_type_id,
              // response_type_id: subData.response_type_id,
              evaluation_type_id: subData.evaluation_type_id,
              access_level_id: subData.access_level_id,
              taxonomy_id: subData.taxonomy_id,
              // objective_id: subData.objective_id,
              // max_marks: subData.max_marks,
              // time_required: subData.time_required,
              medium_id: subData.medium_id,
              // short_description: subData.short_description,
              // description: subData.description,
              // hint: subData.hint,
              // solution_text: subData.solution_text,
              is_enabled: subData.is_enabled,
              answer_json: subData.answer_json,
              solution_file: subData.solution_file,
              image_path: previousImageLink,
              added_by_user_id: userId,
            };
          });
          console.log('acid');
          console.log(subData);
          assigneeBoard(subData, '');
          let board_id = +subData.board_id || -1;
          let course_id = +subData.course_id || -1;
          let subject_id = +subData.subject_id || -1;
          let sub_subject_id = +subData.sub_subject_id || -1;
          let topic_1_id = +subData.topic_1_id || -1;
          let topic_2_id = +subData.topic_2_id || -1;
          let taxonomy_id = +subData.taxonomy_id || -1;
          console.log(institution_id, board_id, course_id, subject_id, sub_subject_id, topic_1_id, topic_2_id, taxonomy_id);
          fetchResults(
            institution_id,
            setStartUpLoad,
            setState,
            board_id,
            course_id,
            subject_id,
            sub_subject_id,
            topic_1_id,
            topic_2_id,
            taxonomy_id,
          );
        }
      }
    }
  } catch (err) {
    console.log(err);
    errorHandle(err);
    setStartUpLoad(false);
  }
}
