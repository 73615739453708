import React, { useState, useEffect, useRef } from 'react';
import './subTopicReport.css';
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userList } from '../../../api/questions';
import { saveAs } from 'file-saver';
import Pagination from '../../SubComponents/Pagination/Pagination';
// import TableSubTopicReport from './SubTopicReportComponents/TableSubTopicRep/TableSubTopicRep';
import SubTopicReportSearch from './SubTopicReportComponents/SubTopicRepSearch/SubTopicRepSearch';
import { fetchOtherResultsSubTopicReport } from './SubTopicReportFunction/fetchOtherResultsSubTopicReport';
import { fetchDataSubTopicReport } from './SubTopicReportFunction/fetchDataSubTopicReport';
import { subTopicReportSubmit } from './SubTopicReportFunction/subTopicReportSubmit';
import { fetchSubTopicExcelData } from '../../../api/report';
import TableTopicReport from '../TopicReport/TopicReportComponents/TableTopicReport/TableTopicReport';
import ImageAsset from '../../../assets/images/imageAsset';

function SubTopicReport() {
  const [formData, setFormData] = useState({
    question_type_id: -1,
    board_id: -1,
    course_id: -1,
    subject_id: -1,
    sub_subject_id: -1,
    topic_id: -1,
    difficulty_level: '',
    medium_id: -1,
    from_date: '',
    to_date: '',
    createdBy: '',
    createdByName: '',
    objective_id: -1,
    taxonomy_id: -1,
  });
  const [state, setState] = useState({
    questionsType: [],
    medium: [],
    board: [],
    course: [],
    subject: [],
    subSubject: [],
    topic: [],
    achieverNameList: [],
    objectives: [],
    taxonomies: [],
  });
  const [subTopicReportList, setSubTopicReportList] = useState([]);
  // const [questionView, setQuestionView] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [startUpLoad, setStartUpLoad] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [numberOfSubTopicReports, setNumberOfSubTopicReports] = useState(0);
  const [noData, setNoData] = useState(false);
  const initialRender = useRef(true);
  const [achieverSearch, setAchieverSearch] = useState('');
  const [achieverSelected, setAchieverSelected] = useState('');
  const [searchSuggestion, setSearchSuggestion] = useState(false);

  // const userId = window.localStorage.getItem("userId");
  var institution_id = 0;
  const limit = 10;
  console.log(formData);

  function handleChange(e, selLabel) {
    const valueSele = e?.target?.value || e?.value || e;
    if (selLabel === 'createdBy') {
      setAchieverSelected(valueSele);
      setAchieverSearch(valueSele);
      if (valueSele === '') {
        setFormData((prevState) => {
          return { ...prevState, createdBy: '' };
        });
      }
      setFormData({ ...formData, [selLabel]: valueSele });
    } else {
      fetchOtherResultsSubTopicReport(e, selLabel, institution_id, setFormData, setStartUpLoad, state, setState, formData);
      setFormData((prevState) => ({
        ...prevState,
        [selLabel]: valueSele,
      }));
    }
  }

  function handleAchieverClick(object) {
    setAchieverSelected(object?.user_name || object?.label || '');
    setSearchSuggestion(false);
    setFormData({
      ...formData,
      createdBy: object?.user_id || object?.value || '',
    });
    // setAchieverCodeSelected(object.achiever_code);
  }

  function handleUlClose() {
    setSearchSuggestion(false);
    setAchieverSelected('');
    setAchieverSearch('');
    setFormData((prevState) => {
      return { ...prevState, createdBy: '' };
    });
    // setAchieverCodeSelected("");
  }

  async function handleOnFocus() {
    console.log('s');
    setStartUpLoad(true);
    try {
      const searchItems = await userList(achieverSearch);
      console.log(searchItems.data.data);
      let searchItemSortOne = searchItems.data.data.sort(function (a, b) {
        var x = a.user_name.toLowerCase();
        var y = b.user_name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      setState({
        ...state,
        achieverNameList: searchItemSortOne,
      });
      setSearchSuggestion(true);
      setStartUpLoad(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
    // } else {
    //   setSearchSuggestion(false);
    // }
  }

  function paginationClick(number, offSetNumber) {
    if (Math.ceil(numberOfSubTopicReports / 10) > 1) {
      setPageNumber(+number);
      setOffSet(+offSetNumber);
      setStartUpLoad(true);
    }
  }

  useEffect(() => {
    setStartUpLoad(true);

    fetchDataSubTopicReport(setState, state, setStartUpLoad);
    // setFormData((prevState) => {
    //   return { ...prevState, added_by_user_id: userId };
    // });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialRender.current === false) {
      async function handlePagination() {
        console.log(offSet);
        subTopicReportSubmit(
          setSubmitLoading,
          setNoData,
          formData,
          setNumberOfSubTopicReports,
          setSubTopicReportList,
          limit,
          offSet,
          setStartUpLoad,
        );
      }
      handlePagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSet]);

  useEffect(() => {
    console.log(initialRender);
    if (initialRender.current === true) {
      initialRender.current = false;
    } else {
      async function searchItemList() {
        // if (achieverSearch.length > 0) {
        setStartUpLoad(true);
        try {
          const searchItems = await userList(achieverSearch);
          console.log(searchItems.data.data);
          let searchItemSort = searchItems.data.data.sort(function (a, b) {
            var x = a.user_name.toLowerCase();
            var y = b.user_name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
          setState({
            ...state,
            achieverNameList: searchItemSort,
          });
          setSearchSuggestion(true);
          setStartUpLoad(false);
        } catch (error) {
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          } else {
            toast.error('Server Error');
          }
          setStartUpLoad(false);
        }
        // } else {
        //   setSearchSuggestion(false);
        // }
      }
      searchItemList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [achieverSearch]);

  const createDownloadInfo = (formData) => {
    const link = [];
    if (formData?.medium_id && formData?.medium_id !== -1) {
      link.push(`medium_id=${formData?.medium_id}`);
    }
    if (formData?.board_id && formData?.board_id !== -1) {
      link.push(`board_id=${formData?.board_id}`);
    }
    if (formData?.course_id && formData?.course_id !== -1) {
      link.push(`course_id=${formData?.course_id}`);
    }
    if (formData?.subject_id && formData?.subject_id !== -1) {
      link.push(`subject_id=${formData.subject_id}`);
    }
    if (formData?.sub_subject) {
      link.push(`sub_subject=${formData?.sub_subject}`);
    }
    if (formData?.topic_id && formData?.topic_id !== -1) {
      link.push(`topic_id=${formData?.topic_id}`);
    }
    if (formData?.createdBy !== '') {
      link.push(`added_by_user_id=${formData?.createdBy}`);
    }
    if (formData?.difficulty_level && formData?.difficulty_level !== -1) {
      link.push(`difficulty_level=${formData?.difficulty_level}`);
    }
    if (formData?.from_date) {
      link.push(`from_date=${formData?.from_date}`);
    }
    if (formData.to_date) {
      link.push(`to_date=${formData?.to_date}`);
    }
    const linkFinal = link.join('&');
    return linkFinal;
  };

  const handleDownload = async () => {
    setStartUpLoad(true);
    const excelDownloadParams = createDownloadInfo(formData);
    // console.log("excelDownloadParams", excelDownloadParams);
    fetchSubTopicExcelData(excelDownloadParams)
      .then((res) => {
        const excelData = res;
        const fileName = excelData?.headers[`content-disposition`].split('=')[1];
        // console.log("fileName", fileName);
        saveAs(excelData.data, fileName);
        toast.success('file downloaded');
        setStartUpLoad(false);
      })
      .catch((err) => {
        console.log('err', err);
        toast.error(err?.message);
        setStartUpLoad(false);
      });
  };
  const tableHead = [
    {
      name: 'Sl. No.',
      position: 'left',
      mapped: 'index',
    },
    {
      name: 'Course',
      position: 'left',
      mapped: 'course_name',
    },
    {
      name: 'Subject',
      position: 'left',
      mapped: 'subject_name',
    },
    {
      name: 'Sub-Subject',
      position: 'left',
      mapped: 'sub_subject_name',
    },
    {
      name: 'Topic',
      position: 'center',
      mapped: 'topic_name',
    },
    {
      name: 'Sub-Topic',
      position: 'left',
      mapped: 'sub_topic_name',
    },
    {
      name: 'Objective',
      position: 'left',
      mapped: 'objective',
    },
    {
      name: 'No. Of Questions',
      position: 'left',
      mapped: 'no_of_questions',
    },
    {
      name: 'DL1',
      position: 'left',
      mapped: 'difficulty_level_1',
    },
    {
      name: 'DL2',
      position: 'left',
      mapped: 'difficulty_level_2',
    },
    {
      name: 'DL3',
      position: 'left',
      mapped: 'difficulty_level_3',
    },
    {
      name: 'DL4',
      position: 'left',
      mapped: 'difficulty_level_4',
    },
    {
      name: 'DL5',
      position: 'left',
      mapped: 'difficulty_level_5',
    },
    {
      name: 'DL6',
      position: 'left',
      mapped: 'difficulty_level_6',
    },
    {
      name: 'DL7',
      position: 'left',
      mapped: 'difficulty_level_7',
    },
    {
      name: 'DL8',
      position: 'left',
      mapped: 'difficulty_level_8',
    },
    {
      name: 'DL9',
      position: 'left',
      mapped: 'difficulty_level_9',
    },
    {
      name: 'DL10',
      position: 'left',
      mapped: 'difficulty_level_10',
    },
  ];

  return (
    <div>
      {startUpLoad ? (
        <div className="loadingBackgroundSubTopicRep">
          <ReactLoading type="spin" color="#68dff0" className="startLoadSpinSubTopicRep" />
        </div>
      ) : null}
      <div style={{ display: 'flex' }}>
        <SubTopicReportSearch
          state={state}
          formData={formData}
          handleChange={handleChange}
          submitLoading={submitLoading}
          noData={noData}
          achieverSelected={achieverSelected}
          searchSuggestion={searchSuggestion}
          handleUlClose={handleUlClose}
          handleAchieverClick={handleAchieverClick}
          handleOnFocus={handleOnFocus}
          setSubmitLoading={setSubmitLoading}
          setNoData={setNoData}
          setNumberOfSubTopicReports={setNumberOfSubTopicReports}
          setSubTopicReportList={setSubTopicReportList}
          limit={limit}
          offSet={offSet}
          setStartUpLoad={setStartUpLoad}
          createDownloadInfo={createDownloadInfo}
        />
        {noData ? (
          <div className="noData">
            There is no available data.
            <div>
              <img src={ImageAsset?.qb_nodata?.default} alt="" />
            </div>
          </div>
        ) : null}
        {subTopicReportList.length !== 0 ? (
          <div className="subTopicReportTableContainer" style={{ width: '78%' }}>
            <button className="download-report-btn" onClick={handleDownload}>
              Download
            </button>

            <div>
              {/* <TableSubTopicReport tableData={subTopicReportList} /> */}
              <TableTopicReport tableData={subTopicReportList} headData={tableHead} />
            </div>
            <div style={{ border: ' 1px solid rgba(224, 224, 224, 1)' }}>
              <Pagination
                paginationClick={paginationClick}
                numberOfRows={numberOfSubTopicReports}
                offSet={offSet}
                pageNumber={pageNumber}
              />
            </div>
          </div>
        ) : (
          <>
            {!noData ? (
              <div className="noData" style={{ textTransform: 'capitalize' }}>
                Please use left panel to search question
                <div>
                  <img src={ImageAsset?.qb_uaeleftimage?.default} alt="" />
                </div>
              </div>
            ) : null}
          </>
        )}
        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default SubTopicReport;
