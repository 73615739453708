const ImageAsset = {
  qb_close: require('./close.png'),
  qb_breadcrumarrow: require('./Icon.svg'),
  qb_fileicon: require('./fileicon.svg').default,
  qb_errorImage: require('./errimage.svg').default,
  qb_nodata: require('./Nodataimage.svg'),
  qb_uaeleftimage: require('./useleftsection.svg'),
  qb_defaultImage: require('./default_placeholder.png'),
};
export default ImageAsset;
