import { toast } from 'react-toastify';
import { createQuestion } from '../../../../api/questions';
import { updateQueMetadata } from '../../../../api/user';
import { curateQuestion } from '../../../../api/questions';
import { editQuestion } from '../../../../api/questions';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

export default async function addSubmit(
  e,
  formData,
  setNotFilled,
  setSubmited,
  setSubmitError,
  setSubmitLoading,
  backlog,
  userId,
  curator,
  setErrorQueSub,
  assigneeQuestionId,
  isCurated,
  handleQueDelete,
  history,
  questionReuseId,
  viewQuestion,
  question_id,
  assigneeMC,
  printURL,
  solutionFileChoose,
  setStartUpLoad,
) {
  e.preventDefault();
  if (printURL && !formData.copiable_data_file_path) {
    console.log('printURL');
    toast.error('Printable file is attached but not uploaded');
  }
  if (solutionFileChoose && !formData.solution_file) {
    console.log('Solution');
    toast.error('Solution file is attached but not uploaded');
  }
  if (
    formData.question_type_id === '-1' ||
    formData.board_id === '-1' ||
    formData.course_id === '-1' ||
    formData.subject_id === '-1' ||
    formData.sub_subject_id === '-1' ||
    formData.topic_1_id === '-1' ||
    formData.sub_topic_1_id === '-1' ||
    formData.difficulty_level === '-1' ||
    formData.answer_input_type_id === '-1' ||
    formData.response_type_id === '-1' ||
    formData.evaluation_type_id === '-1' ||
    formData.access_level_id === '-1' ||
    formData.taxonomy_id === '-1' ||
    formData.objective_id === '-1' ||
    formData.max_marks === '' ||
    formData.time_required === '' ||
    formData.medium_id === '-1' ||
    formData.medium_id === -1 ||
    formData.short_description === '' ||
    formData.question_type_id === -1 ||
    formData.board_id === -1 ||
    formData.course_id === -1 ||
    formData.subject_id === -1 ||
    formData.sub_subject_id === -1 ||
    formData.topic_1_id === -1 ||
    formData.sub_topic_1_id === -1 ||
    formData.difficulty_level === -1 ||
    formData.answer_input_type_id === -1 ||
    formData.response_type_id === -1 ||
    formData.evaluation_type_id === -1 ||
    formData.access_level_id === -1 ||
    formData.taxonomy_id === -1 ||
    formData.objective_id === -1 ||
    formData.max_marks === 0 ||
    formData.max_marks === '' ||
    formData.time_required === 0
  ) {
    toast.error('Please fill all the madatory data');
    setNotFilled(true);
  } else {
    setSubmited(false);
    setSubmitError(false);
    const pref = {
      pref_key: 'question_defaults',
      pref_value_json: JSON.stringify(formData),
    };
    if (backlog === true) {
      setSubmitLoading(true);
      setStartUpLoad(true);
      setSubmitError(false);
      window.localStorage.setItem('formData', JSON.stringify(formData));
      console.log(formData, 'formData');
      try {
        var responseQue = await createQuestion(formData);
        let metaData = await updateQueMetadata(userId, pref);
        console.log(responseQue);
        console.log(metaData);
        if (responseQue.data.data.o_question_id) {
          if (curator && isCurated) {
            let question_id = responseQue.data.data.o_question_id;
            assigneeQuestionId(question_id);
            console.log(question_id);
            let curatorData = {
              is_curated: isCurated,
              curated_by_user_id: userId,
            };
            console.log(curatorData);
            try {
              var curatingBacklog = await curateQuestion(question_id, curatorData);
              console.log(curatingBacklog);
            } catch (err) {
              setErrorQueSub(err.response.data.message);
              errorHandle(err);
            }
          }
        }
        if (responseQue.status === 200) {
          handleQueDelete();
          setSubmited(true);
          setSubmitLoading(false);
          setStartUpLoad(false);
          //   MCQCrtArray = [];
          //   MCQNrArray = [];
          //   MAQArray = [];
          assigneeMC();
          setTimeout(() => history('/admin/backlog'), 1000);
        }
      } catch (err) {
        errorHandle(err);
        setSubmitLoading(false);
        setSubmitError(true);
        setStartUpLoad(false);
      }
    }
    if (viewQuestion === true) {
      setSubmitLoading(true);
      setStartUpLoad(true);
      window.localStorage.setItem('formData', JSON.stringify(formData));
      console.log(formData);
      console.log(questionReuseId);
      try {
        if (questionReuseId && questionReuseId !== '') {
          let reuseCreate = await createQuestion(formData);
          console.log(reuseCreate);
        } else {
          var response = await editQuestion(question_id, formData);
          console.log(response);
        }
        let metaData = await updateQueMetadata(userId, pref);
        console.log(metaData);
        if (curator) {
          let curatorData = {
            is_curated: isCurated,
            curated_by_user_id: userId,
          };
          console.log(curatorData);
          try {
            var curatingViewQuestions = await curateQuestion(question_id, curatorData);
            console.log(curatingViewQuestions);
          } catch (err) {
            setErrorQueSub(err.response.data.message);
            if (err.response && err.response.data) {
              console.log(err.response.data.message);
              toast.error(err.response.data.message);
            } else {
              toast.error('Server Error');
            }
          }
        }
        if (metaData.status === 200) {
          setSubmited(true);
          setSubmitLoading(false);
          setStartUpLoad(false);
          //   MCQCrtArray = [];
          //   MCQNrArray = [];
          //   MAQArray = [];
          assigneeMC();
          window.localStorage.setItem('previousPath', 'addDetails');
          setTimeout(() => history('/admin/view-questions'), 2000);
        }
        // }
      } catch (err) {
        setErrorQueSub(err.response.data.message);
        errorHandle(err);
        setSubmitLoading(false);
        setSubmitError(true);
        setStartUpLoad(false);
      }
    }
  }
}
