// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asterik {
  color: red;
  margin-left: 5px;
  font-weight: 600;
  font-size: 12px;
}
.selectlabel {
  margin-bottom: 0px;
}
#selectcontainer {
  margin-top: 3px;
}
::-webkit-scrollbar {
  width: 4px;
}
.errormessage {
  color: red;
  font-size: 12px;
  margin: 2px 0px 0px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SubComponents/SelectInput/selectInput.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,eAAe;EACf,wBAAwB;AAC1B","sourcesContent":[".asterik {\n  color: red;\n  margin-left: 5px;\n  font-weight: 600;\n  font-size: 12px;\n}\n.selectlabel {\n  margin-bottom: 0px;\n}\n#selectcontainer {\n  margin-top: 3px;\n}\n::-webkit-scrollbar {\n  width: 4px;\n}\n.errormessage {\n  color: red;\n  font-size: 12px;\n  margin: 2px 0px 0px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
