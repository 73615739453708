import React from 'react';
import ReactLoading from 'react-loading';

export default function AddSoultion({
  handleChange,
  formData,
  handleSoultionFile,
  solutionFileUpload,
  solUploadLoading,
  solFileUpload,
}) {
  return (
    <div className="compulsoryContainer">
      {/* <h4 className="addH4">Solution</h4> */}
      {/* <p className="addOpt">(optional)</p> */}
      {/* <div className="moreDropdown"> */}
      <div className="shortDescription">
        <h4 className="addH4">Hint</h4>
        <input
          placeholder="Enter Hint"
          className="addInputSmall"
          onChange={(e) => handleChange(e, 'hint')}
          value={formData.hint}
        ></input>
        {/* </div> */}
      </div>
      <div className="shortDescription">
        <label className="addH4">Solution File</label>
        <div>
          <input
            type="file"
            name="question_file_path"
            id="question_file_path"
            className="fileChooseAddDetails"
            onChange={(e) => handleSoultionFile(e)}
            accept=".pdf, .jpg, .jpeg, .png"
          ></input>
          <button type="button" className="btnFilesUploadAddDetails" onClick={(e) => solutionFileUpload(e)}>
            Upload
          </button>
          {solUploadLoading ? (
            <div>
              <ReactLoading type="spinningBubbles" color="#68dff0" className="fileSolUploadedSpin" />
              <h5 className="fileSolUploadedText">Uploding</h5>
            </div>
          ) : null}
        </div>
      </div>
      {solFileUpload ? <p className="fileSolUploaded">File has been Uploaded</p> : null}
      <div className="shortDescription">
        <h4 className="addH4">Solution Text</h4>
        <textarea
          placeholder="Can add multi-linguistic text by copy paste"
          className="addInput"
          onChange={(e) => handleChange(e, 'solution_text')}
          value={formData.solution_text}
        ></textarea>
      </div>
    </div>
  );
}
