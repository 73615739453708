import { deleteQuestionFunction } from '../../../../api/questions';
import { toast } from 'react-toastify';

export default async function deleteBacklog(selQuestion, setStartUpLoad) {
  console.log(selQuestion);
  setStartUpLoad(true);
  try {
    // const deleteQuestionData = Axios.delete(`${BASE_URL}/qb/question_files/${selQuestion}`, Auth);
    const deleteQuestionData = await deleteQuestionFunction(selQuestion);
    console.log(deleteQuestionData.data.data.message);
    toast.success('Successfully deleted the question');
    setStartUpLoad(false);
  } catch (error) {
    if (error.response.data) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    } else {
      toast.error('Server Error');
    }
    setStartUpLoad(false);
  }
  window.location.reload(false);
}
