import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Screens/Login/Login';
import Layout from './Layout/Layout';
import ForgotPassword from './Screens/ForgotPassword/ForgotPassword';
import ResetPassword from './Screens/ResetPassword/ResetPassword';
import Backlog from './Screens/Backlog/Backlog';
import AddDetails from './Screens/AddDetails/AddDetails';
import QSetCreator from './Screens/QSetCreator/QSetCreator';
import QSetMap from './Screens/QSetMap/QSetMap';
import QSetView from './Screens/QSetView/QSetView';
import TranslateQuestions from './Screens/TranslateQuestions/TranslateQuestions';
import ViewQuestions from './Screens/ViewQuestions/ViewQuestions';
import UploadQuestions from './Screens/UploadQuestions/UploadQuestions';
import Migrate from './Screens/Migrate/Migrate';
import MigrateMap from './Screens/MigrateMap/MigrateMap';
import TopicReport from './Screens/TopicReport/TopicReport';
import SubTopicReport from './Screens/SubTopicReport/SubTopicReport';
import QSetReport from './Screens/QSetReport/QSetReport';

function App() {
  return (
    <div>
      <Router>
        <div>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/admin" element={<Layout />}>
              <Route path="/admin/backlog" Component={Backlog} />
              <Route path="/admin/add-details" Component={AddDetails} />
              <Route path="/admin/edit-details" Component={AddDetails} />
              <Route path="/admin/upload-questions" Component={UploadQuestions} />
              <Route path="/admin/q-set-creator" Component={QSetCreator} />
              <Route path="/admin/edit-q-set" Component={QSetCreator} />
              <Route path="/admin/q-set-map" Component={QSetMap} />
              <Route path="/admin/q-set-view" Component={QSetView} />
              <Route path="/admin/translate-questions" Component={TranslateQuestions} />
              <Route path="/admin/view-questions" Component={ViewQuestions} />
              <Route path="/admin/migrate" Component={Migrate} />
              <Route path="/admin/backlog" Component={Backlog} />
              <Route path="/admin/migrate-map" Component={MigrateMap} />
              <Route path="/admin/topic-report" Component={TopicReport} />
              <Route path="/admin/sub-topic-report" Component={SubTopicReport} />
              <Route path="/admin/q-set-report" Component={QSetReport} />
              <Route render={() => <Navigate to="/admin/upload-questions" />} />
            </Route>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/:user_id/new-password" element={<ResetPassword />} />
            <Route render={() => <Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
