import React from 'react';
import ReactLoading from 'react-loading';
import styled from './loadingBars.module.css';

const LoadingBars = () => {
  return (
    <div className={styled.loadingBackground}>
      <ReactLoading type="spin" color="#68dff0" className={styled.startLoadSpin} />
    </div>
  );
};

export default LoadingBars;
