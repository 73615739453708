import https from '../init/https';

//QB-LOGIN-4: Signin
export async function loginFunction(data) {
  const loginData = await https.post(`/user/signin`, data);
  return loginData;
}

//QB-LOGIN-3: Set Password
export async function passwordSet(data) {
  const passwordSetData = await https.post(`/user/save`, data);
  return passwordSetData;
}

//QB-LOGIN-1: Send Password Reset Mail
export async function passReset(data) {
  const passResetData = await https.post(`/mail/sendmail`, data);
  return passResetData;
}
