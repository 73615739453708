import { fetchNewDropDown } from './fetchNewDropDown';

export function populateFormData(setFormData, setStartUpLoad, setState, institution_id, setValue) {
  setStartUpLoad(true);
  const oldFormData = JSON.parse(window.localStorage.getItem('translateQuestion'));
  console.log(oldFormData);
  window.localStorage.removeItem('translateQuestion');
  if (oldFormData === null || oldFormData === undefined) {
    window.location = '/admin/view-questions';
  } else {
    setValue('medium', oldFormData?.medium_id);
    setValue('board', oldFormData?.board_id);
    setValue('course', oldFormData?.course_id);
    setValue('subject', oldFormData?.subject_id);
    setValue('subSubject', oldFormData?.sub_subject_id);
    setValue('topic', oldFormData?.topic_1_id);

    setFormData({ ...oldFormData, translate: true });
    fetchNewDropDown(setStartUpLoad, oldFormData, setState, institution_id);
  }
}
