import { userList } from '../../../../api/questions';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

export default async function handleOnFocusViewQue(setStartUpLoad, achieverSearch, setState, setSearchSuggestion) {
  console.log('s');
  setStartUpLoad(true);
  try {
    const searchItems = await userList('');
    console.log(searchItems.data.data);
    let searchItemSort = searchItems.data.data.sort(function (a, b) {
      var x = a.user_name.toLowerCase();
      var y = b.user_name.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
    setState((prevState) => ({
      ...prevState,
      achieverNameList: searchItemSort,
    }));
    setSearchSuggestion(true);
    setStartUpLoad(false);
  } catch (error) {
    errorHandle(error);
    setStartUpLoad(false);
  }
}
