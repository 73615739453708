import https from '../init/https';

//QB-MD-1: Retrieve list of all mediums
export async function fetchMedium() {
  const fetchMediumData = await https.get(`/mediums`);
  return fetchMediumData;
}

//QB-MD-2: Retrieve the medium info for a given Subject
export async function fetchMediumSub(subject_id) {
  const fetchMediumSubData = await https.get(`/mediums/subjects/${subject_id}`);
  return fetchMediumSubData;
}
