import React from 'react';
import './viewDropDown.css';
// import DropDown from "../../../SubComponents/SearchDrop/SearchDrop";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import SearchSuggestion from '../../../SubComponents/SearchSuggestion/SearchSuggestion';
// import SearchDrop from '../../../SubComponents/SearchDrop/SearchDrop';
import SelectInput from '../../../SubComponents/SelectInput/SelectInput';

function ViewDropDown({
  state,
  formData,
  handleChange,
  handleSubmit,
  achieverSelected,
  searchSuggestion,
  handleUlClose,
  handleAchieverClick,
  handleOnFocus,
  curatorSelected,
  curatorSuggestion,
  handleUlCloseCurator,
  handleCuratorClick,
  handleOnFocusCurator,
}) {
  const role = window.localStorage.getItem('role');
  const difficultyLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  console.log(state, 'state');
  return (
    <div className="viewQuestionBox">
      <div>
        <div className="viewQuesearchQuestionContainer">
          <h4 className="searchQuestionHeading">Search Question</h4>
          {(role === 'Curator' || role === 'Admin') && (
            <div style={{ marginBottom: '10px' }}>
              {/* <label className="searchDropLabelCreated">Created By</label> */}
              {/* <SearchSuggestion
                achieverSelected={achieverSelected}
                // disabled={editValue}
                handleChange={handleChange}
                searchSuggestion={searchSuggestion}
                dropDownData={state.achieverNameList}
                handleUlClose={handleUlClose}
                handleUlClick={handleAchieverClick}
                handleOnFocus={handleOnFocus}
                name="user_name"
                type="createdBy"
              /> */}
              <SelectInput
                label="Created By"
                options={
                  state?.achieverNameList?.map((i) => ({
                    label: i?.user_name,
                    value: i?.user_id,
                  })) || []
                }
                onFocus={handleOnFocus}
                placeholder="Select Created By"
                displayName=""
                name="Created By"
                idName="Created_by"
                handleChange={(e) => {
                  // handleChange(e, 'createdBy');
                  handleAchieverClick(e, 'createdBy');
                }}
                isClearable
              />
            </div>
          )}
          {(role === 'Curator' || role === 'Admin') && (
            <div style={{ marginBottom: '10px' }}>
              {/* <label className="searchDropLabelCreated">Curated By</label> */}
              {/* <SearchSuggestion
                achieverSelected={curatorSelected}
                // disabled={editValue}
                handleChange={handleChange}
                searchSuggestion={curatorSuggestion}
                dropDownData={state.curatorNameList}
                handleUlClose={handleUlCloseCurator}
                handleUlClick={handleCuratorClick}
                handleOnFocus={handleOnFocusCurator}
                name="full_name"
                type="curatedBy"
              /> */}
              <SelectInput
                label="Curated By"
                options={
                  state?.curatorNameList?.map((i) => ({
                    label: i?.full_name,
                    value: i?.curator_id,
                  })) || []
                }
                onFocus={handleOnFocusCurator}
                placeholder="Select Curated By"
                displayName=""
                name="Curated By"
                idName="sCurated_by"
                handleChange={(e) => {
                  // handleChange(e, 'createdBy')
                  handleCuratorClick(e);
                }}
                isClearable
              />
            </div>
          )}

          {/* <SearchDrop
            label="Questions Type"
            options={state.questionsType}
            displayName="display_name"
            name="question_type_id"
            idName="id"
            value={formData.question_type_id}
            onChange={handleChange}
            req={false}
          >
          </SearchDrop> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Question Type"
              options={
                state?.questionsType?.map((i) => ({
                  label: i?.code,
                  value: i?.id,
                })) || []
              }
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Question Type"
              displayName=""
              name="Question Type"
              idName="Questions_type"
              handleChange={(e) => handleChange(e, 'question_type_id')}
              isClearable
            />
          </div>
          {/* <SearchDrop
            label="Board"
            options={state.board}
            name="board_id"
            displayName="board_display_name"
            idName="board_id"
            value={formData.board_id}
            onChange={handleChange}
            req={false}
          /> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Board"
              options={
                state?.board?.map((i) => ({
                  label: i?.board_display_name,
                  value: i?.board_id,
                })) || []
              }
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Board"
              displayName=""
              name="board"
              idName="board_id"
              handleChange={(e) => handleChange(e, 'board_id')}
              isClearable
            />
          </div>
          {/* <SearchDrop
            label="Course"
            options={state.course}
            name="course_id"
            displayName="display_name"
            idName="course_id"
            value={formData.course_id}
            onChange={handleChange}
            req={false}
          /> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Course"
              options={
                state?.course?.map((i) => ({
                  label: i?.display_name,
                  value: i?.course_id,
                })) || []
              }
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Course"
              displayName=""
              name="Coarse"
              idName="course_id"
              handleChange={(e) => handleChange(e, 'course_id')}
              isClearable
            />
          </div>
          {/* <SearchDrop
            label="Subject"
            options={state.subject}
            name="subject_id"
            displayName="display_name"
            idName="subject_id"
            value={formData.subject_id}
            onChange={handleChange}
            req={false}
          /> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Subject"
              options={
                state?.subject?.map((i) => ({
                  label: i?.display_name,
                  value: i?.subject_id,
                })) || []
              }
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Subject"
              displayName=""
              name="subject_id"
              idName="subject_id"
              handleChange={(e) => handleChange(e, 'subject_id')}
              isClearable
            />
          </div>
          {/* <SearchDrop
            label="Sub-Subject"
            options={state.subSubject}
            name="sub_subject_id"
            displayName="display_name"
            idName="sub_subject_id"
            value={formData.sub_subject_id}
            onChange={handleChange}
            req={false}
          /> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Sub-Subject"
              options={
                state?.subSubject?.map((i) => ({
                  label: i?.display_name,
                  value: i?.sub_subject_id,
                })) || []
              }
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Sub-Subject"
              displayName=""
              name="sub_subject_id"
              idName="sub_subject_id"
              handleChange={(e) => handleChange(e, 'sub_subject_id')}
              isClearable
            />
          </div>
          {/* <SearchDrop
            label="Topic 1"
            options={state.topic}
            name="topic_1_id"
            displayName="display_name"
            idName="topic_id"
            value={formData.topic_1_id}
            onChange={handleChange}
            req={false}
          /> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Topic 1"
              options={
                state?.topic?.map((i) => ({
                  label: i?.display_name,
                  value: i?.topic_id,
                })) || []
              }
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Topic 1"
              displayName=""
              name="topic_1_id"
              idName="topic_1_id"
              handleChange={(e) => handleChange(e, 'topic_1_id')}
              isClearable
            />
          </div>
          {/* <SearchDrop
            label="Sub-Topic 1"
            options={state.subTopic}
            name="sub_topic_1_id"
            displayName="display_name"
            idName="sub_topic_id"
            value={formData.sub_topic_1_id}
            onChange={handleChange}
            req={false}
          /> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Sub-Topic 1"
              options={
                state?.subTopic?.map((i) => ({
                  label: i?.display_name,
                  value: i?.subTopic,
                })) || []
              }
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Sub-Topic 1"
              displayName=""
              name="sub_topic_1_id"
              idName="sub_topic_1_id"
              handleChange={(e) => handleChange(e, 'sub_topic_1_id')}
              isClearable
            />
          </div>

          {/* <SearchDrop
            label="Topic 2"
            options={state.topic}
            name="topic_2_id"
            displayName="display_name"
            idName="topic_id"
            value={formData.topic_2_id}
            onChange={handleChange}
            req={false}
          /> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Topic 2"
              options={
                state?.topic?.map((i) => ({
                  label: i?.display_name,
                  value: i?.topic_id,
                })) || []
              }
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Topic 2"
              displayName=""
              name="topic_2_id"
              idName="topic_2_id"
              handleChange={(e) => handleChange(e, 'topic_2_id')}
              isClearable
            />
          </div>
          {/* <SearchDrop
            label="Sub-Topic 2"
            options={state.subTopic2}
            name="sub_topic_2_id"
            displayName="display_name"
            idName="sub_topic_id"
            value={formData.sub_topic_2_id}
            onChange={handleChange}
            req={false}
          /> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Sub-Topic 2"
              options={
                state?.subTopic2?.map((i) => ({
                  label: i?.display_name,
                  value: i?.sub_topic_id,
                })) || []
              }
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Sub-Topic 2"
              displayName=""
              name="sub_topic_2_id"
              idName="sub_topic_2_id"
              handleChange={(e) => handleChange(e, 'sub_topic_2_id')}
              isClearable
            />
          </div>
          {/* <SearchDrop
            label="Taxonomy"
            options={state.taxonomies}
            displayName="display_name"
            name="taxonomy_id"
            idName="id"
            onChange={handleChange}
            value={formData.taxonomy_id}
            req={false}
          /> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Taxonomy"
              options={
                state?.taxonomies?.map((i) => ({
                  label: i?.display_name,
                  value: i?.id,
                })) || []
              }
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Taxonomy"
              displayName=""
              name="taxonomy_id"
              idName="taxonomy_id"
              handleChange={(e) => handleChange(e, 'taxonomy_id')}
              isClearable
            />
          </div>
          {/* <SearchDrop
            label="Objective"
            options={state.objectives}
            displayName="objective_code"
            name="objective_id"
            idName="objective_id"
            onChange={handleChange}
            value={formData.objective_id}
            req={false}
          /> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Objective"
              options={
                state?.objectives?.map((i) => ({
                  label: i?.objective_code,
                  value: i?.objective_id,
                })) || []
              }
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Objective"
              displayName=""
              name="objective_id"
              idName="objective_id"
              handleChange={(e) => handleChange(e, 'objective_id')}
              isClearable
            />
          </div>
          {/* <SearchDrop
            label="Difficulty Level"
            options={difficultyLevel}
            name="difficulty_level"
            displayName=""
            value={formData.difficulty_level}
            onChange={handleChange}
            req={false}
          /> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Difficulty Level"
              options={difficultyLevel?.map((i) => ({ label: i, value: i })) || []}
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Difficulty Level"
              displayName=""
              name="difficulty_level"
              idName="difficulty_level"
              handleChange={(e) => handleChange(e, 'difficulty_level')}
              isClearable
            />
          </div>
          {/* <SearchDrop
            label="Access Level"
            options={state.accessLevel}
            displayName="display_name"
            name="access_level_id"
            idName="id"
            value={formData.access_level_id}
            onChange={handleChange}
            req={false}
          /> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Access Level"
              options={
                state?.accessLevel?.map((i) => ({
                  label: i?.display_name,
                  value: i?.id,
                })) || []
              }
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Access Level"
              displayName=""
              name="access_level_id"
              idName="access_level_id"
              handleChange={(e) => handleChange(e, 'access_level_id')}
              isClearable
            />
          </div>
          {/* <SearchDrop
            label="Medium"
            options={state.medium}
            name="medium_id"
            displayName="medium_name"
            idName="medium_id"
            value={formData.medium_id}
            onChange={handleChange}
            req={false}
          /> */}
          <div style={{ marginBottom: '10px' }}>
            <SelectInput
              label="Medium"
              options={
                state?.medium?.map((i) => ({
                  label: i?.medium_name,
                  value: i?.medium_id,
                })) || []
              }
              // onFocus={handleOnFocusCurator}
              // value={state?.questionsType?.filter((i)=>i.)}
              placeholder="Select Medium"
              displayName=""
              name="medium_id"
              idName="medium_id"
              handleChange={(e) => handleChange(e, 'medium_id')}
              isClearable
            />
          </div>
          {/* <div className="viewdropdownDiv"> */}
          <label className="viewQueDropLabel">Question #</label>
          <br />
          <input
            type="number"
            placeholder="Enter Question #"
            className="viewQueDropSelect"
            value={formData.question_code}
            onChange={(e) => {
              handleChange(e, 'question_code');
              console.log(e.target.value, 'state');
            }}
          />
          {/* </div> */}
          {/* <div className="viewdropdownDiv"> */}
          <label className="viewQueDropLabel">From Date</label>
          <br />
          <input
            className="viewQueDropSelect"
            type="date"
            value={formData.from_date}
            onChange={(e) => {
              handleChange(e, 'from_date');
            }}
            // onFocus={myPicker.open()}
          ></input>
          {/* </div> */}
          {/* <div className="viewdropdownDiv"> */}
          <label className="viewQueDropLabel">To Date</label>
          <br />
          <input
            className="viewQueDropSelect"
            type="date"
            value={formData.to_date}
            onChange={(e) => handleChange(e, 'to_date')}
          ></input>
          {/* </div> */}
        </div>
      </div>
      <div className="viewContainer">
        <button className="viewSearch" id="searchQuestions" type="button" onClick={handleSubmit}>
          Search
        </button>
        {/* {submitLoading ? (
          <div className="loadingDiv">
            <ReactLoading
              type="spinningBubbles"
              color="#68dff0"
              className="reactLoadingIcon"
            />
            <h5 className="loadingText">Loading</h5>
          </div>
        ) : null} */}
        {/* {noData ? (
          <div className="loadingDiv">
            <h5 className="loadingTextData">Data is not available</h5>
          </div>
        ) : null} */}
      </div>
    </div>
  );
}

export default ViewDropDown;
