import { toast } from 'react-toastify';
import {
  fetchMigrateClass,
  fetchMigrateSubject,
  fetchMigrateSubSubject,
  fetchMigrateSubTopic,
  fetchMigrateSy,
  fetchMigrateTopic,
} from '../../../../api/ms_sql';

export default async function fetchOtherResults(e, selLabel, setStartUpLoad, setFormData, setState, formdata) {
  setStartUpLoad(true);
  if (selLabel === 'language_id') {
    setFormData((prevState) => {
      return {
        ...prevState,
        syllabus_id: -1,
        class_id: -1,
        subject_id: -1,
        subSubject_id: 0,
        topic_id: 0,
        subTopic_id: 0,
      };
    });
    try {
      const getSyllabus = await fetchMigrateSy();
      setState((prevState) => ({
        ...prevState,
        syllabus: getSyllabus.data.syllabusList,
      }));
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'syllabus_id') {
    const syllabus_id = e?.target?.value || e;
    setFormData((prevState) => {
      return {
        ...prevState,
        class_id: -1,
        subject_id: -1,
        subSubject_id: 0,
        topic_id: 0,
        subTopic_id: 0,
      };
    });
    try {
      const getClass = await fetchMigrateClass(formdata.language_id, syllabus_id);
      setState((prevState) => ({
        ...prevState,
        class: getClass.data.Class,
      }));
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'class_id') {
    const class_id = e?.target?.value || e;
    setFormData((prevState) => {
      return {
        ...prevState,
        subject_id: -1,
        subSubject_id: 0,
        topic_id: 0,
        subTopic_id: 0,
      };
    });
    try {
      // const getSubject = await Axios.get(
      //   `${BASE_URL_MIGRATE}/GetSubjectList?languageID=${language_id}&SyllabusID=${syllabus_id}&ClassID=${class_id}`
      // );
      const getSubject = await fetchMigrateSubject(formdata.language_id, formdata.syllabus_id, class_id);
      setState((prevState) => ({
        ...prevState,
        subject: getSubject.data.subjectList,
      }));
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'subject_id') {
    const subject_id = e?.target?.value || e;
    setFormData((prevState) => {
      return {
        ...prevState,
        subSubject_id: 0,
        topic_id: 0,
        subTopic_id: 0,
      };
    });
    try {
      // const getSubSubject = await Axios.get(
      //   `${BASE_URL_MIGRATE}/GetSubSubjectList?SubjectID=${subject_id}`
      // );
      const getSubSubject = await fetchMigrateSubSubject(subject_id);
      setState((prevState) => ({
        ...prevState,
        subSubject: getSubSubject.data.subSubjectList,
      }));
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'subSubject_id') {
    const sub_subject_id = e?.target?.value || e;
    setFormData((prevState) => {
      return {
        ...prevState,
        topic_id: 0,
        subTopic_id: 0,
      };
    });
    try {
      // const getTopic = await Axios.get(
      //   `${BASE_URL_MIGRATE}/GetTopicList?languageID=${language_id}&SyllabusID=${syllabus_id}&ClassID=${class_id}&SubjectID=${subject_id}&SubSubjectID=${sub_subject_id}`
      // );
      const getTopic = await fetchMigrateTopic(
        formdata.language_id,
        formdata.syllabus_id,
        formdata.class_id,
        formdata.subject_id,
        sub_subject_id,
      );
      setState((prevState) => ({
        ...prevState,
        topic: getTopic.data.topicList,
      }));
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'topic_id') {
    const topic_id = e?.target?.value || e;
    setFormData((prevState) => {
      return {
        ...prevState,
        subTopic_id: 0,
      };
    });
    try {
      // const getSubTopic = await Axios.get(
      //   `${BASE_URL_MIGRATE}/GetSubTopicList?TopicID=${topic_id}`
      // );
      const getSubTopic = await fetchMigrateSubTopic(topic_id);
      setState((prevState) => ({
        ...prevState,
        subTopic: getSubTopic.data.subTopicList,
      }));
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'subTopic_id') {
    setStartUpLoad(false);
  }
}

export const fetch0ptionfromlocal = async (e, selLabel, setStartUpLoad, setFormData, setState, formdata) => {
  setStartUpLoad(true);
  console.log(e, selLabel, formdata, 'apicall');
  if (selLabel === 'language_id' && Number(e) > 0) {
    try {
      const getSyllabus = await fetchMigrateSy();
      setState((prevState) => ({
        ...prevState,
        syllabus: getSyllabus.data.syllabusList,
      }));
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'syllabus_id' && Number(e) > 0) {
    const syllabus_id = e?.target?.value || e;

    try {
      const getClass = await fetchMigrateClass(formdata.language_id, syllabus_id);
      setState((prevState) => ({
        ...prevState,
        class: getClass.data.Class,
      }));
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'class_id' && Number(e) > 0) {
    const class_id = e?.target?.value || e;

    try {
      // const getSubject = await Axios.get(
      //   `${BASE_URL_MIGRATE}/GetSubjectList?languageID=${language_id}&SyllabusID=${syllabus_id}&ClassID=${class_id}`
      // );
      const getSubject = await fetchMigrateSubject(formdata.language_id, formdata.syllabus_id, class_id);
      setState((prevState) => ({
        ...prevState,
        subject: getSubject.data.subjectList,
      }));
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'subject_id' && Number(e) > 0) {
    const subject_id = e?.target?.value || e;

    try {
      // const getSubSubject = await Axios.get(
      //   `${BASE_URL_MIGRATE}/GetSubSubjectList?SubjectID=${subject_id}`
      // );
      const getSubSubject = await fetchMigrateSubSubject(subject_id);
      setState((prevState) => ({
        ...prevState,
        subSubject: getSubSubject.data.subSubjectList,
      }));
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'subSubject_id' && Number(e) > 0) {
    const sub_subject_id = e?.target?.value || e;

    try {
      // const getTopic = await Axios.get(
      //   `${BASE_URL_MIGRATE}/GetTopicList?languageID=${language_id}&SyllabusID=${syllabus_id}&ClassID=${class_id}&SubjectID=${subject_id}&SubSubjectID=${sub_subject_id}`
      // );
      const getTopic = await fetchMigrateTopic(
        formdata.language_id,
        formdata.syllabus_id,
        formdata.class_id,
        formdata.subject_id,
        sub_subject_id,
      );
      setState((prevState) => ({
        ...prevState,
        topic: getTopic.data.topicList,
      }));
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'topic_id' && Number(e) > 0) {
    const topic_id = e?.target?.value || e;

    try {
      // const getSubTopic = await Axios.get(
      //   `${BASE_URL_MIGRATE}/GetSubTopicList?TopicID=${topic_id}`
      // );
      const getSubTopic = await fetchMigrateSubTopic(topic_id);
      setState((prevState) => ({
        ...prevState,
        subTopic: getSubTopic.data.subTopicList,
      }));
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'subTopic_id' && Number(e) > 0) {
    setStartUpLoad(false);
  }
};
