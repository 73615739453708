import React from 'react';
import configuration from '../../../config/Config';
import { REWRITE_URLS } from '../../../config/Api';
import styles from './addquestions.module.css';
import { Player } from '@lottiefiles/react-lottie-player';

export default function AddQuestionImage({ backlog, prvLink, viewQuestion }) {
  return (
    <div className={styles.queContainerdetails}>
      <div className="queDetailsAddDiv">
        <h4 className="queH4Add">Question Details</h4>
      </div>
      <div className="queSubContainer">
        {backlog ? (
          <div>
            {/* <h4 className="queH4">Questions File</h4> */}
            <div className="uploadedImageDisplay">
              <div className="queContant">
                {prvLink?.endsWith('.json') ? (
                  <Player
                    autoplay
                    loop
                    src={
                      REWRITE_URLS === '1'
                        ? configuration.digitalOceanSpacesRewrite + prvLink.slice(configuration.digitalOceanSpaces.length)
                        : prvLink
                    }
                    style={{
                      width: '75%',
                      height: '90%',
                    }}
                  />
                ) : (
                  <img
                    src={
                      REWRITE_URLS === '1'
                        ? configuration.digitalOceanSpacesRewrite + prvLink.slice(configuration.digitalOceanSpaces.length)
                        : prvLink
                    }
                    alt="Uploaded"
                    className="uploadedImage"
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div></div>
          </div>
        )}
        {viewQuestion === true ? (
          <div>
            {/* <h4 className="queH4">Questions File</h4> */}
            <div className="uploadedImageDisplay">
              <div className="queContant">
                {prvLink?.endsWith('.json') ? (
                  <Player
                    autoplay
                    loop
                    src={
                      REWRITE_URLS === '1'
                        ? configuration.digitalOceanSpacesRewrite + prvLink.slice(configuration.digitalOceanSpaces.length)
                        : prvLink
                    }
                    style={{
                      width: '75%',
                      height: '90%',
                    }}
                  />
                ) : (
                  <img
                    src={
                      REWRITE_URLS === '1'
                        ? configuration.digitalOceanSpacesRewrite + prvLink.slice(configuration.digitalOceanSpaces.length)
                        : prvLink
                    }
                    alt="Uploaded"
                    className="uploadedImage"
                  />
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
