import { toast } from 'react-toastify';
import { fetchCourse, fetchSubject, fetchSubSubject, fetchTopic } from '../../../../api/institution';

export async function fetchNewDropDown(setStartUpLoad, oldFormData, setState, institution_id) {
  //   setStartUpLoad(true);
  try {
    const getCourse = await fetchCourse(institution_id, oldFormData.board_id);
    const getSubject = await fetchSubject(institution_id, oldFormData.board_id, oldFormData.course_id);
    const getSubSubject = await fetchSubSubject(
      institution_id,
      oldFormData.board_id,
      oldFormData.course_id,
      oldFormData.subject_id,
    );
    const getTopic = await fetchTopic(
      institution_id,
      oldFormData.board_id,
      oldFormData.course_id,
      oldFormData.subject_id,
      oldFormData.sub_subject_id,
    );
    setState((prevState) => {
      return {
        ...prevState,
        course: getCourse.data.data,
        subject: getSubject.data.data,
        subSubject: getSubSubject.data.data,
        topic: getTopic.data.data,
      };
    });
    setStartUpLoad(false);
  } catch (err) {
    if (err.response && err.response.data) {
      console.log(err.response.data.message);
      toast.error(err.response.data.message);
    } else {
      toast.error('Error');
      console.log(err);
    }
    setStartUpLoad(false);
  }
}
