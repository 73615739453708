import React, { useState, useEffect, useRef } from 'react';
// import Dropdown from '../../SubComponents/Dropdown/Dropdown';
import { useNavigate } from 'react-router-dom';
import SelectInput from '../../SubComponents/SelectInput/SelectInput';
// import { BASE_URL } from "../../config/Api";
// import Axios from "axios";
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import { TopicTable, SubTopicTable, Objective, DifficultyLevelGraph } from '../../SubComponents/SummaryTable/SummaryTable';
import './qSetView.css';
import Pagination from '../../SubComponents/Pagination/Pagination';
import PublicIcon from '@mui/icons-material/Public';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
// import SearchSuggestion from '../../SubComponents/SearchSuggestion/SearchSuggestion';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { fetchQuestionSets, fetchQuestionSetsCount, questionsForQSet, userList, deleteQSet } from '../../../api/questions';
import { fetchQuestionType } from '../../../api/qbMasterData';
import { boardList } from '../../../api/boards';
import { fetchCourse, fetchSubject, fetchSubSubject, fetchTopic } from '../../../api/institution';
import Breadcrums from '../../SubComponents/breadcrum/breadcrum';
import ImageAsset from '../../../assets/images/imageAsset';

var institution_id = 0;
var board_id = -1;
var course_id = -1;
var subject_id = -1;
var sub_subject_id = -1;
// var topic_id = -1;
var link = [];
var linkFinal;
var QSetIdDelete;
var selQSetId;

function QSetView() {
  const history = useNavigate();
  const [formData, setFormData] = useState({
    QSetName: '',
    createdBy: '',
    QSetCode: '',
    question_type_id: '',
    board_id: '',
    course_id: '',
    subject_id: '',
    sub_subject_id: '',
    topic_id: '',
  });
  const [state, setState] = useState({
    questionsType: [],
    medium: [],
    board: [],
    course: [],
    subject: [],
    subSubject: [],
    topic: [],
    achieverNameList: [],
  });
  const [startUpLoad, setStartUpLoad] = useState(true);
  const [questionSet, setQuestionSet] = useState([]);
  const [selQSet, setSelQSet] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [numberOfQuestionSet, setNumberOfQuestionSet] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [achieverSearch, setAchieverSearch] = useState('');
  const [achieverSelected, setAchieverSelected] = useState('');
  const [searchSuggestion, setSearchSuggestion] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const initialRender = useRef(true);

  // const token = window.localStorage.getItem("token");
  const userId = window.localStorage.getItem('userId');
  // const Auth = {
  //   headers: {
  //     authorization: "Bearer " + token,
  //   },
  // };

  function handleChange(e, selLabel) {
    const valueSele = ['QSetName', 'QSetCode'].includes(selLabel) ? e?.target?.value : e?.target?.value || e?.value || e;
    if (selLabel === 'createdBy') {
      setAchieverSelected(valueSele);
      setAchieverSearch(valueSele);
      if (valueSele === '') {
        setFormData((prevState) => {
          return { ...prevState, createdBy: '' };
        });
      }
      // setFormData({ ...formData, [selLabel]: valueSele });
    } else {
      setFormData({ ...formData, [selLabel]: valueSele });
      if (e?.value) {
        fetchOtherResults(e, selLabel);
      }
    }
  }

  function handleEdit(QSetId) {
    setStartUpLoad(true);
    history('/admin/edit-q-set', {
      state: { isEdit: true, QSetCode: QSetId },
    });
    setStartUpLoad(false);
  }

  async function handleDelete(QSetId) {
    setPopUp(true);
    setDeleteRow(true);
    setStartUpLoad(true);
    if (deleteRow === true) {
      try {
        // const deleteQueSet = await Axios.delete(
        //   `${BASE_URL}/qb/question_sets/${QSetId}`,
        //   Auth
        // );
        const deleteQueSet = await deleteQSet(QSetId);
        console.log(deleteQueSet.data.data);
        fetchQuestionSet();
        toast.success('Successfully deleted question set');
        setStartUpLoad(false);
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
      setPopUp(false);
      setDeleteRow(false);
    }
    if (deleteRow === true) {
      QSetIdDelete = QSetId;
    }
  }

  function okDelete() {
    // setDeleteNode(true);
    handleDelete(QSetIdDelete);
    // setPopUp(false);
  }

  //function of cancel in popup
  function cancel() {
    setDeleteRow(false);
    setPopUp(false);
  }

  async function fetchOtherResults(e, selLabel) {
    if (selLabel === 'question_type_id') {
      setFormData((prevState) => {
        return {
          ...prevState,
          board_id: -1,
          course_id: -1,
          subject_id: -1,
          sub_subject_id: -1,
          topic_id: -1,
        };
      });
      setStartUpLoad(true);
      board_id = -1;
      course_id = -1;
      subject_id = -1;
      sub_subject_id = -1;
      try {
        // const getBoard = await Axios.get(`${BASE_URL}/boards`, Auth);
        const getBoard = await boardList();
        setState({
          ...state,
          board: getBoard.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'board_id') {
      setFormData((prevState) => {
        return {
          ...prevState,
          course_id: -1,
          subject_id: -1,
          sub_subject_id: -1,
          topic_id: -1,
        };
      });
      setStartUpLoad(true);
      board_id = e?.target?.value || e?.value || e;
      course_id = -1;
      subject_id = -1;
      sub_subject_id = -1;
      try {
        // const getCourse = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses`,
        //   Auth
        // );
        const getCourse = await fetchCourse(institution_id, board_id);
        setState({
          ...state,
          course: getCourse.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'course_id') {
      setFormData((prevState) => {
        return {
          ...prevState,
          subject_id: -1,
          sub_subject_id: -1,
          topic_id: -1,
        };
      });
      setStartUpLoad(true);
      course_id = e?.target?.value || e?.value || e;
      subject_id = -1;
      sub_subject_id = -1;
      try {
        // const getSubject = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects`,
        //   Auth
        // );
        const getSubject = await fetchSubject(institution_id, board_id, course_id);
        setState({
          ...state,
          subject: getSubject.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'subject_id') {
      setFormData((prevState) => {
        return { ...prevState, sub_subject_id: -1, topic_id: -1 };
      });
      setStartUpLoad(true);
      subject_id = e?.target?.value || e?.value || e;
      sub_subject_id = -1;
      try {
        // const getSubSubject = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects`,
        //   Auth
        // );
        const getSubSubject = await fetchSubSubject(institution_id, board_id, course_id, subject_id);
        setState((prevState) => ({
          ...prevState,
          subSubject: getSubSubject.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, 'error while fetching data');
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'sub_subject_id') {
      setFormData((prevState) => {
        return { ...prevState, topic_id: -1 };
      });
      setStartUpLoad(true);
      sub_subject_id = e?.target?.value || e?.value || e;
      try {
        // const getTopic = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics`,
        //   Auth
        // );
        const getTopic = await fetchTopic(institution_id, board_id, course_id, subject_id, sub_subject_id);
        setState((prevState) => ({
          ...prevState,
          topic: getTopic.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, 'error while fetching data');
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    // if (selLabel === "topic_id") {
    //   topic_id = e?.target?.value || e?.value;
    // }
  }

  useEffect(() => {
    setStartUpLoad(true);
    async function fetchData() {
      try {
        // let getQuestionType = await Axios.get(
        //   `${BASE_URL}/qb/question_types`,
        //   Auth
        // );
        const getQuestionType = await fetchQuestionType();
        // const getAccessLevel = await Axios.get(
        //   `${BASE_URL}/qb/access_levels`,
        //   Auth
        // );
        // const getMediums = await Axios.get(`${BASE_URL}/mediums`, Auth);
        setState({
          ...state,
          questionsType: getQuestionType.data.data,
          // accessLevel: getAccessLevel.data.data,
          // medium: getMediums.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    fetchData();
    setFormData((prevState) => {
      return { ...prevState, added_by_user_id: userId };
    });
    // eslint-disable-next-line
  }, []);

  async function fetchQuestionSet() {
    setSubmitLoading(true);
    setNoData(false);
    link = [];
    console.log(board_id, course_id, subject_id);
    if (formData.board_id) {
      link.push(`board_id=${formData.board_id}`);
    }
    if (formData.course_id) {
      link.push(`course_id=${formData.course_id}`);
    }
    if (formData.subject_id) {
      link.push(`subject_id=${formData.subject_id}`);
    }
    if (formData.sub_subject_id) {
      link.push(`sub_subject_id=${formData.sub_subject_id}`);
    }
    if (formData.topic_id) {
      link.push(`topic_id=${formData.topic_id}`);
    }
    if (formData.createdBy !== '') {
      link.push(`added_by_user_id=${formData.createdBy}`);
    }
    if (formData.QSetName !== '') {
      link.push(`display_name=${formData.QSetName}`);
    }
    if (formData.QSetCode !== '') {
      link.push(`question_set_id=${formData.QSetCode}`);
    }
    linkFinal = link.join('&');
    console.log(linkFinal);
    try {
      // const getQuestionSetCount = await Axios.get(
      //   `${BASE_URL}/qb/question_sets/count_question_sets?${linkFinal}`,
      //   Auth
      // );
      const getQuestionSetCount = await fetchQuestionSetsCount(linkFinal);
      console.log(getQuestionSetCount.data.data.question_set_count);
      setNumberOfQuestionSet(getQuestionSetCount.data.data.question_set_count);
      if (getQuestionSetCount.data.data.question_set_count !== 0) {
        // const getQuestionSet = await Axios.get(
        //   `${BASE_URL}/qb/question_sets/search?${linkFinal}&limit=10&offset=0`,
        //   Auth
        // );
        const getQuestionSet = await fetchQuestionSets(linkFinal, 0);
        console.log(getQuestionSet.data.data);
        setQuestionSet(getQuestionSet.data.data);
        handleSetClick(getQuestionSet.data.data?.[0]?.question_set_id);
        setSubmitLoading(false);
        window.scrollTo(0, 500);
      } else {
        setSelQSet([]);
        setQuestionSet([]);
        setNoData(true);
        setSubmitLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setSubmitLoading(false);
    }
  }

  async function handleSetClick(SelQueSet) {
    console.log(SelQueSet);
    selQSetId = SelQueSet;
    setStartUpLoad(true);
    try {
      // const getSelQuestions = await Axios.get(
      //   `${BASE_URL}/qb/question_sets_questions/${SelQueSet}`,
      //   Auth
      // );
      const getSelQuestions = await questionsForQSet(SelQueSet);
      console.log(getSelQuestions.data.data);
      setSelQSet(getSelQuestions.data.data);
      setStartUpLoad(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }

  function paginationClick(number, offSetNumber) {
    if (Math.ceil(numberOfQuestionSet / 10) > 1) {
      setPageNumber(+number);
      setOffSet(+offSetNumber);
      setStartUpLoad(true);
    }
  }

  useEffect(() => {
    if (initialRender.current === false) {
      async function handlePagination() {
        console.log(offSet);
        // if (subject_id !== -1) {
        try {
          // const getQuestionList = await Axios.get(
          //   `${BASE_URL}/qb/question_sets/search?${linkFinal}&limit=10&offset=${offSet}`,
          //   Auth
          // );
          const getQuestionList = await fetchQuestionSets(linkFinal, offSet);
          // console.log(getQuestionList.data.data);
          setQuestionSet(getQuestionList.data.data);
          setStartUpLoad(false);
        } catch (err) {
          if (err.response && err.response.data) {
            console.log(err.response);
            toast.error(err.response.data.message);
          } else {
            toast.error('Server Error');
          }
          setStartUpLoad(false);
        }
        // }
      }
      handlePagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSet]);

  useEffect(() => {
    console.log(initialRender);
    if (initialRender.current === true) {
      initialRender.current = false;
    } else {
      async function searchItemList() {
        // if (achieverSearch.length > 0) {
        setStartUpLoad(true);
        try {
          // const searchItems = await Axios.get(
          //   `${BASE_URL}/qb/search/users?search_text=${achieverSearch}`,
          //   Auth
          // );
          const searchItems = await userList(achieverSearch);
          console.log(searchItems.data.data);
          let searchItemSort = searchItems.data.data.sort(function (a, b) {
            var x = a.user_name.toLowerCase();
            var y = b.user_name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
          setState({
            ...state,
            achieverNameList: searchItemSort,
          });
          setSearchSuggestion(true);
          setStartUpLoad(false);
        } catch (error) {
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          } else {
            toast.error('Server Error');
          }
          setStartUpLoad(false);
        }
        // } else {
        //   setSearchSuggestion(false);
        // }
      }
      searchItemList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [achieverSearch]);

  function handleAchieverClick(object) {
    setAchieverSelected(object?.label || '');
    setSearchSuggestion(false);
    setFormData({ ...formData, createdBy: object?.value || '' });
    // setAchieverCodeSelected(object.achiever_code);
  }

  // function handleUlClose() {
  //   setSearchSuggestion(false);
  //   setAchieverSelected('');
  //   setAchieverSearch('');
  //   setFormData((prevState) => {
  //     return { ...prevState, createdBy: '' };
  //   });
  //   // setAchieverCodeSelected("");
  // }

  async function handleOnFocus() {
    console.log('s');
    setStartUpLoad(true);
    try {
      // const searchItems = await Axios.get(
      //   `${BASE_URL}/qb/search/users?search_text=${achieverSearch}`,
      //   Auth
      // );
      const searchItems = await userList(achieverSearch);
      console.log(searchItems.data.data);
      let searchItemSortOne = searchItems.data.data.sort(function (a, b) {
        var x = a.user_name.toLowerCase();
        var y = b.user_name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      setState({
        ...state,
        achieverNameList: searchItemSortOne,
      });
      // setSearchSuggestion(true);
      setStartUpLoad(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
    // } else {
    //   setSearchSuggestion(false);
    // }
  }
  const TotalMarks = (e) => {
    let totalmarks = 0;
    if (e.length > 0) {
      for (let j = 0; j < e.length; j++) {
        totalmarks += Number(e[j].max_marks);
      }
    }
    return totalmarks;
  };

  return (
    <div>
      <div>
        <Breadcrums title="Qset View" />
      </div>
      {startUpLoad ? (
        <div>
          <ReactLoading type="spin" color="#68dff0" className="startLoadSpin" />
        </div>
      ) : null}
      <section className="qset-viewMain">
        <div className="qset-viewdrawer">
          <h4 className="qset_view_searchQuestionHeading">Search QSet</h4>
          <form style={{ padding: '10px' }} className="qset_view_searchForm">
            <div className="qset-view-searchQuestionContainer">
              <div className="qset_view_viewdropdownDiv">
                <label className="qset_view_searchQuestionLabel">QSet Name</label>
                <input
                  className="qset_view_searchQuestionSelect"
                  placeholder="Enter Qset Name"
                  onChange={(e) => handleChange(e, 'QSetName')}
                ></input>
              </div>
              <div className="qset_view_viewdropdownDiv">
                {/* <label className="qset_view_searchQuestionLabel">
                  Created By
                </label>
                <SearchSuggestion
                  achieverSelected={achieverSelected}
                  // disabled={editValue}
                  handleChange={handleChange}
                  searchSuggestion={searchSuggestion}
                  dropDownData={state.achieverNameList}
                  handleUlClose={handleUlClose}
                  handleUlClick={handleAchieverClick}
                  handleOnFocus={handleOnFocus}
                /> */}
                <SelectInput
                  placeholder="Select Created By"
                  label="Created By"
                  options={
                    state.achieverNameList?.map((i) => ({
                      label: i.user_name,
                      value: i.user_id,
                    })) || []
                  }
                  handleChange={(e) => {
                    // handleChange(e, "createdBy");
                    handleAchieverClick(e);
                  }}
                  onFocus={handleOnFocus}
                  isClearable
                />
              </div>
              <div className="qset_view_viewdropdownDiv">
                <label className="qset_view_searchQuestionLabel">QSet Code</label>
                <input
                  type="number"
                  className="qset_view_searchQuestionSelect"
                  placeholder="Enter QSet Code"
                  onChange={(e) => handleChange(e, 'QSetCode')}
                ></input>
              </div>
              {/* <Dropdown
                label="Questions Type"
                options={state.questionsType}
                displayName="display_name"
                name="question_type_id"
                value={formData.question_type_id}
                idName="id"
                onChange={handleChange}
                req={false}
              > */}
              <div className="qset_view_viewdropdownDiv">
                <SelectInput
                  placeholder="Select Question Type"
                  label="Question Type"
                  options={
                    state.questionsType?.map((i) => ({
                      label: i.display_name,
                      value: i.id,
                    })) || []
                  }
                  handleChange={(e) => {
                    handleChange(e, 'question_type_id');
                  }}
                  isClearable
                />
              </div>
              {/* </Dropdown> */}
              {/* <Dropdown
                label="Board"
                options={state.board}
                name="board_id"
                displayName="board_display_name"
                value={formData.board_id}
                idName="board_id"
                onChange={handleChange}
                req={false}
              /> */}
              <div className="qset_view_viewdropdownDiv">
                <SelectInput
                  placeholder="Select Board"
                  label="Board"
                  options={
                    state.board?.map((i) => ({
                      label: i.board_display_name,
                      value: i.board_id,
                    })) || []
                  }
                  handleChange={(e) => {
                    handleChange(e, 'board_id');
                  }}
                  isClearable
                />
              </div>
              {/* <Dropdown
                label="Course"
                options={state.course}
                name="course_id"
                displayName="display_name"
                idName="course_id"
                value={formData.course_id}
                onChange={handleChange}
                req={false}
              /> */}
              <div className="qset_view_viewdropdownDiv">
                <SelectInput
                  placeholder="Select Course"
                  label="Course"
                  options={
                    state.course?.map((i) => ({
                      label: i.display_name,
                      value: i.course_id,
                    })) || []
                  }
                  handleChange={(e) => {
                    handleChange(e, 'course_id');
                  }}
                  isClearable
                />
              </div>
              {/* <Dropdown
                label="Subject"
                options={state.subject}
                name="subject_id"
                displayName="display_name"
                idName="subject_id"
                value={formData.subject_id}
                onChange={handleChange}
                req={false}
              /> */}
              <div className="qset_view_viewdropdownDiv">
                <SelectInput
                  placeholder="Select Subject"
                  label="Subject"
                  options={
                    state.subject?.map((i) => ({
                      label: i.display_name,
                      value: i.subject_id,
                    })) || []
                  }
                  handleChange={(e) => {
                    handleChange(e, 'subject_id');
                  }}
                  isClearable
                />
              </div>
              {/* <Dropdown
                label="Sub-Subject"
                options={state.subSubject}
                name="sub_subject_id"
                displayName="display_name"
                idName="sub_subject_id"
                value={formData.sub_subject_id}
                onChange={handleChange}
                req={false}
              /> */}
              <div className="qset_view_viewdropdownDiv">
                <SelectInput
                  placeholder="Select Sub-Subject"
                  label="Sub-Subject"
                  options={
                    state.subSubject?.map((i) => ({
                      label: i.display_name,
                      value: i.sub_subject_id,
                    })) || []
                  }
                  handleChange={(e) => {
                    handleChange(e, 'sub_subject_id');
                  }}
                  isClearable
                />
              </div>
              {/* <Dropdown
                label="Topic"
                options={state.topic}
                name="topic_id"
                displayName="display_name"
                idName="topic_id"
                value={formData.topic_id}
                onChange={handleChange}
                req={false}
              /> */}
              <div className="qset_view_viewdropdownDiv">
                <SelectInput
                  placeholder="Select Topic"
                  label="Topic"
                  options={
                    state.topic?.map((i) => ({
                      label: i.display_name,
                      value: i.topic_id,
                    })) || []
                  }
                  handleChange={(e) => {
                    handleChange(e, 'topic_id');
                  }}
                  isClearable
                />
              </div>
            </div>
          </form>
          <div className="qSetViewContainer">
            <button className="qSetViewSearch" onClick={fetchQuestionSet} type="button">
              Search
            </button>
            {submitLoading ? (
              <div className="loadingDiv">
                <ReactLoading type="spinningBubbles" color="#68dff0" className="reactLoadingIcon" />
                <h5 className="loadingText">Loading</h5>
              </div>
            ) : null}
          </div>
        </div>
        {noData ? (
          <div className="noData">
            There is no available data.
            <div>
              <img src={ImageAsset?.qb_nodata?.default} alt="" />
            </div>
          </div>
        ) : null}
        {questionSet.length > 0 && (
          <div className="compulsoryContainerQSetView">
            <h4 className="compulsoryHeading">View QSet Questions</h4>
            <div className="Qset_view_QuestionsSearchDisplay">
              <div className="QSetTablePagination">
                <div className="QSetViewTableContainer">
                  {/* <div> */}
                  <table className="qSetViewTable">
                    <thead>
                      <tr>
                        <th>Sl No.</th>
                        <th>Set Code</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Question Count</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {questionSet.map((row, questionSetKey) => {
                        return (
                          <tr
                            key={questionSetKey}
                            className={+selQSetId === +row.question_set_id ? 'selQSet' : null}
                            onClick={(e) => handleSetClick(row.question_set_id)}
                          >
                            <td>{questionSetKey + 1}</td>
                            <td>{row.question_set_id}</td>
                            <td>{row.question_set_name}</td>
                            <td>{row.description}</td>
                            <th>{row.question_count}</th>
                            <td style={{ display: 'flex' }}>
                              <button className="editQSet" onClick={(event) => handleEdit(row.question_set_id)} type="button">
                                Edit
                              </button>
                              <button className="deleteQSet" onClick={(event) => handleDelete(row.question_set_id)} type="button">
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* </div> */}
                </div>
                <Pagination
                  paginationClick={paginationClick}
                  numberOfRows={numberOfQuestionSet}
                  offSet={offSet}
                  pageNumber={pageNumber}
                />
              </div>
            </div>
          </div>
        )}
        {selQSet.length > 0 && (
          <div className="qset_view_container3">
            <div className="total">
              <div className="totaldisp">
                Total Question <span className="spanclass">{selQSet.length}</span>
              </div>
              <div className="totaldisp">
                Total Marks{' '}
                <span className="spanclass">
                  {/* {checkedRows?.map((i) => i?.max_marks)} */}
                  {TotalMarks(selQSet)}
                </span>
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <TopicTable checkedRows={selQSet} />
            </div>
            <div style={{ marginTop: '10px' }}>
              <SubTopicTable checkedRows={selQSet} />
            </div>
            <div style={{ marginTop: '10px' }}>
              <Objective checkedRows={selQSet} />
            </div>
            <div style={{ marginTop: '10px' }}>
              <DifficultyLevelGraph checkedRows={selQSet} />
            </div>
            <div style={{ marginTop: '10px' }}>
              <h4 style={{ margin: '0' }}>Questions</h4>
              <div className="qSetViewQuestiondiv">
                {selQSet.map((row, questionKey) => {
                  return (
                    <div key={questionKey}>
                      <div
                        className="qset_view_ResultRowDiv"
                        // onClick={(e) => handleClick(row)}
                      >
                        <div className="qsetNumberDiv">
                          <p>{questionKey + 1}</p>
                        </div>
                        <div className="qsetShortDiv" style={{ marginLeft: '0' }}>
                          <p className="viewShortP">
                            #{row?.question_id} {row.short_description}
                          </p>
                          <div className="viewByLastDiv" style={{ alignItems: 'center', gap: '10px' }}>
                            {row.access_level === 'Global' && (
                              <PublicIcon className="viewPublicIcon" fontSize="small" style={{ margin: '0' }} />
                            )}
                            {row.access_level === 'Organisation' && (
                              <AccountBalanceIcon className="viewPublicIcon" fontSize="small" style={{ margin: '0' }} />
                            )}
                            {row.access_level === 'Private' && (
                              <PersonIcon className="viewPublicIcon" fontSize="small" style={{ margin: '0' }} />
                            )}
                            <p className="viewBy">By: {row.created_by}</p>
                            <p className="viewLastUpdated">Last Updated: {row.last_updated}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </section>

      <div onClick={cancel} className={popUp ? 'popUpBack' : 'noPopUp'}>
        <div className={popUp ? 'yesPopUp' : 'noPopUp'}>
          <button onClick={cancel} className="popUpCloseBtn">
            <CloseOutlinedIcon className="closeIcon" />
          </button>
          <div className="warningDiv">
            <ReportOutlinedIcon className="warningIcon" />
          </div>
          <h2 className="popUph2">Are You Sure?</h2>
          <div className="popUpButtonDiv">
            <button onClick={cancel} className="popUpCancelButton">
              Cancel
            </button>
            <button onClick={okDelete} className="popUpOkButton">
              OK
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default QSetView;
