import React, { useEffect } from 'react';
// import SearchDrop from '../../../SubComponents/SearchDrop/SearchDrop';
import SelectInput from '../../../SubComponents/SelectInput/SelectInput';
import './migratesearch.css';
import { useForm } from 'react-hook-form';

export default function MigrateSearch({ state, formdata, handleChange, Submit, setNoData }) {
  console.log(state, formdata);

  const defaultVal = (e, id) => {
    if (e && Number(id) > 0) {
      return e
        .filter((i) => Number(i.ID) === Number(id))
        ?.map((i) => ({
          label: i?.Name || i?.ClassName,
          value: i?.ID?.toString(),
        }))?.[0];
    } else {
      return null;
    }
  };
  const {
    setValue,
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    register('language_id', { required: true });
    register('syllabus_id', { required: true });
    register('class_id', { required: true });
    register('sub_Subject_id', { required: true });
    register('subject_id', { required: true });
  }, [register]);
  return (
    <div>
      <div className="migrateQuestionContainer">
        <h4 className="migrateHeading">Migrate Search</h4>
        <div className="selectContainer">
          <SelectInput
            // selectstyle="selectclass"
            name="language_id"
            label="Language"
            placeholder="Select Language"
            options={state?.language?.map((i) => ({
              label: i?.Name,
              value: i?.ID?.toString(),
            }))}
            value={defaultVal(state?.language, formdata?.language_id)}
            handleChange={(e) => {
              handleChange(e?.value, 'language_id');
              setValue('language_id', e?.value);
              clearErrors('language_id');
            }}
            isrequired
            isSearchable
            isClearable
            error={!!errors?.language_id}
            errorText={'Language is Required.'}
            // defaultval={defaultVal(state?.language, 4)}
          />
        </div>
        <div className="selectContainer">
          <SelectInput
            // selectstyle="selectclass"
            name="syllabus_id"
            label="Syllabus"
            placeholder="Select Syllabus"
            options={state?.syllabus?.map((i) => ({
              label: i?.Name,
              value: i?.ID?.toString(),
            }))}
            value={defaultVal(state?.syllabus, formdata?.syllabus_id)}
            handleChange={(e) => {
              handleChange(e?.value, 'syllabus_id');
              setValue('syllabus_id', e?.value);
              clearErrors('syllabus_id');
            }}
            isrequired
            isSearchable
            isClearable
            error={!!errors?.syllabus_id}
            errorText={'Syllabus is Required.'}
            // defaultval={defaultVal(state?.language, 4)}
          />
        </div>
        <div className="selectContainer">
          <SelectInput
            // selectstyle="selectclass"
            name="class_id"
            label="Class"
            placeholder="Select Language"
            options={state?.class?.map((i) => ({
              label: i?.ClassName,
              value: i?.ID?.toString(),
            }))}
            value={defaultVal(state?.class, formdata?.class_id)}
            handleChange={(e) => {
              handleChange(e?.value, 'class_id');
              setValue('class_id', e?.value);
              clearErrors('class_id');
            }}
            isrequired
            isSearchable
            isClearable
            error={!!errors?.class_id}
            errorText={'Class is Required.'}
            // defaultval={defaultVal(state?.language, 4)}
          />
        </div>
        <div className="selectContainer">
          <SelectInput
            // selectstyle="selectclass"
            name="subject_id"
            label="Subject"
            placeholder="Select Subject"
            options={state?.subject?.map((i) => ({
              label: i?.Name,
              value: i?.ID?.toString(),
            }))}
            value={defaultVal(state?.subject, formdata?.subject_id)}
            handleChange={(e) => {
              handleChange(e?.value, 'subject_id');
              setValue('subject_id', e?.value);
              clearErrors('subject_id');
            }}
            isrequired
            isSearchable
            isClearable
            error={!!errors?.subject_id}
            errorText={'Subject is Required.'}
            // defaultval={defaultVal(state?.language, 4)}
          />
        </div>
        <div className="selectContainer">
          <SelectInput
            // selectstyle="selectclass"
            name="sub_Subject_id"
            label="Sub-Subject"
            placeholder="Select Sub-Subject"
            options={state?.subSubject?.map((i) => ({
              label: i?.Name,
              value: i?.ID?.toString(),
            }))}
            value={defaultVal(state?.subSubject, formdata?.subSubject_id)}
            handleChange={(e) => {
              handleChange(e?.value, 'subSubject_id');
              setValue('subSubject_id', e?.value);
              clearErrors('subSubject_id');
            }}
            menuPlacement={'top'}
            isrequired
            isSearchable
            isClearable
            error={!!errors?.sub_Subject_id}
            errorText={'Sub-Subject is Required.'}
            // defaultval={defaultVal(state?.language, 4)}
          />
        </div>
        <div className="selectContainer">
          <SelectInput
            // selectstyle="selectclass"
            name="topic_id"
            label="Topic"
            placeholder="Select Topic"
            options={state?.topic?.map((i) => ({
              label: i?.Name,
              value: i?.ID?.toString(),
            }))}
            value={defaultVal(state?.topic, formdata?.topic_id)}
            handleChange={(e) => {
              handleChange(e?.value, 'topic_id');
            }}
            isSearchable
            isClearable
            menuPlacement={'top'}

            // defaultval={defaultVal(state?.language, 4)}
          />
        </div>
        <div className="selectContainer">
          <SelectInput
            // selectstyle="selectclass"
            name="subTopic_id"
            label="Sub-Topic"
            placeholder="Select Sub-Topic"
            options={state?.subTopic?.map((i) => ({
              label: i?.Name,
              value: i?.ID?.toString(),
            }))}
            value={defaultVal(state?.subTopic, formdata?.subTopic_id)}
            handleChange={(e) => {
              handleChange(e?.value, 'subTopic_id');
            }}
            menuPlacement={'top'}
            isSearchable
            isClearable

            // defaultval={defaultVal(state?.language, 4)}
          />
        </div>
        {/* <SearchDrop
          label="Language"
          options={state.language}
          displayName="Name"
          name="language_id"
          value={formdata.language_id}
          idName="ID"
          onChange={handleChange}
          req={true}
        >
          {" "}
        </SearchDrop> */}
        {/* <SearchDrop
          label="Syllabus"
          options={state.syllabus}
          displayName="Name"
          name="syllabus_id"
          value={formdata.syllabus_id}
          idName="ID"
          onChange={handleChange}
          req={true}
        >
          {" "}
        </SearchDrop> */}
        {/* <SearchDrop
          label="Class"
          options={state.class}
          displayName="ClassName"
          name="class_id"
          value={formdata.class_id}
          idName="ID"
          onChange={handleChange}
          req={true}
        >
          {" "}
        </SearchDrop> */}
        {/* <SearchDrop
          label="Subject"
          options={state.subject}
          displayName="Name"
          name="subject_id"
          value={formdata.subject_id}
          idName="ID"
          onChange={handleChange}
          req={true}
        >
          {" "}
        </SearchDrop> */}
        {/* <SearchDrop
          label="Sub-Subject"
          options={state.subSubject}
          displayName="Name"
          name="subSubject_id"
          value={formdata.sub_subject_id}
          idName="ID"
          onChange={handleChange}
          req={false}
        >
          {" "}
        </SearchDrop> */}
        {/* <SearchDrop
          label="Topic"
          options={state.topic}
          displayName="Name"
          name="topic_id"
          value={formdata.topic_id}
          idName="ID"
          onChange={handleChange}
          req={false}
        /> */}
        {/* <SearchDrop
          label="Sub-Topic"
          options={state.subTopic}
          displayName="Name"
          name="subTopic_id"
          value={formdata.sub_topic_id}
          idName="ID"
          onChange={handleChange}
          req={false}
        /> */}
      </div>
      <div className="migrateSearchContainer">
        <button type="submit" className="migrateSubmit" onClick={handleSubmit(Submit)}>
          Search
        </button>
      </div>
    </div>
  );
}
