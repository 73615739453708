import React from 'react';
import TabRowAdd from './TabRowAdd';
import QuestionPrint from './QuestionPrint';
import Hierarchy from './Hierarchy';
import Response from './Response';
import AddAnswer from './AddAnswer';
import AddSoultion from './AddSolution';

export default function AddQuestionData({
  setTabSelected,
  tabSelected,
  curator,
  previousPath,
  handleChangeFile,
  copyFileUpload,
  copyUploadLoading,
  copyUpload,
  handleChange,
  formData,
  state,
  notFilled,
  handleChangeOptionMCQ,
  MCQCrtArray,
  MCQNrArray,
  handleChangeOptionYesNo,
  handleChangeResponse,
  handleChangeOption,
  MAQArray,
  matchingCount,
  handleRemove,
  responseMatchingData,
  incrementMatching,
  handleResponceFile,
  responseUploadFile,
  resUploadLoading,
  resVoice,
  resImgae,
  resVideo,
  resFile,
  handleSoultionFile,
  solutionFileUpload,
  solUploadLoading,
  solFileUpload,
  handleRejectChange,
  rejectReason,
  handleReject,
  // isCurated,
  // curatorName,
  // handleSubmit,
  // curatorId,
  // userId,
  // submitLoading,
  // submited,
  // submitError,
  // errorQueSub,
  printURL,
}) {
  return (
    <div className="questionDataAddDiv">
      <TabRowAdd setTabSelected={setTabSelected} tabSelected={tabSelected} curator={curator} previousPath={previousPath} />
      {/* <div> */}
      {tabSelected === 'Question Print' && (
        <QuestionPrint
          handleChangeFile={handleChangeFile}
          copyFileUpload={copyFileUpload}
          copyUploadLoading={copyUploadLoading}
          copyUpload={copyUpload}
          handleChange={handleChange}
          formData={formData}
          printURL={printURL}
        />
      )}
      {tabSelected === 'Hierarchy Details' && (
        <Hierarchy state={state} handleChange={handleChange} formData={formData} notFilled={notFilled} />
      )}
      {tabSelected === 'Response Details' && (
        <Response
          state={state}
          handleChange={handleChange}
          formData={formData}
          notFilled={notFilled}
          handleChangeOptionMCQ={handleChangeOptionMCQ}
          MCQCrtArray={MCQCrtArray}
          MCQNrArray={MCQNrArray}
          handleChangeOptionYesNo={handleChangeOptionYesNo}
          handleChangeResponse={handleChangeResponse}
          handleChangeOption={handleChangeOption}
          MAQArray={MAQArray}
          matchingCount={matchingCount}
          handleRemove={handleRemove}
          responseMatchingData={responseMatchingData}
          incrementMatching={incrementMatching}
          handleResponceFile={handleResponceFile}
          responseUploadFile={responseUploadFile}
          resUploadLoading={resUploadLoading}
          resVoice={resVoice}
          resImgae={resImgae}
          resVideo={resVideo}
          resFile={resFile}
        />
      )}
      {tabSelected === 'Answer Details' && (
        <AddAnswer state={state} handleChange={handleChange} formData={formData} notFilled={notFilled} />
      )}
      {tabSelected === 'Solution' && (
        <AddSoultion
          handleChange={handleChange}
          formData={formData}
          handleSoultionFile={handleSoultionFile}
          solutionFileUpload={solutionFileUpload}
          solUploadLoading={solUploadLoading}
          solFileUpload={solFileUpload}
        />
      )}

      {curator && previousPath === 'viewQuestion' && tabSelected === 'Reject' && (
        <div className="compulsoryContainer">
          {/* <h4 className="addH4">Reject</h4> */}
          <div className="moreDropdown">
            <div className="shortDescription">
              <h4 className="addH4">Reject Reason</h4>
              <textarea
                placeholder="Can add multi-linguistic text by copy paste"
                className="addInput"
                onChange={(e) => handleRejectChange(e)}
                value={rejectReason}
              ></textarea>
            </div>
          </div>
          <button className="addReject" type="button" onClick={handleReject}>
            Reject
          </button>
        </div>
      )}
      {/* <div className="compulsoryContainerCheckboxSubmit">
        {curator && (
          <div className="addDetailsCuratedDiv">
            <input
              type="checkbox"
              vlaue={isCurated}
              checked={isCurated}
              onChange={(e) => handleChange(e, "is_curated")}
              disabled={ 
                curatorName && curatorName !== "" && isCurated ? true : false
              }
            />{" "}
            {isCurated ? (
              <p className="addDetailsCuratedP">Curated by {curatorName}</p>
            ) : (
              <p className="addDetailsCuratedP">Curated</p>
            )}
          </div>
        )}
        <button
          type="submit"
          className="addSubmit"
          onClick={(e) => handleSubmit(e)}
          disabled={isCurated && curatorId ? true : false}
        >
          Submit
        </button>
        {submitLoading ? (
          <div className="AddDetailsLoadingDiv">
            <ReactLoading
              type="spinningBubbles"
              color="#68dff0"
              className="reactLoadingIcon"
            />
            <h5 className="loadingText">Loading</h5>
          </div>
        ) : null}
        {submited ? (
          <div className="AddDetailsLoadingDiv">
            <h5 className="loadingTextData">Question is Submited</h5>
          </div>
        ) : null}
        {submitError ? (
          <div className="addDetailsLoadingDivErr">
            <h5 className="loadingTextDataErr">{errorQueSub}</h5>
          </div>
        ) : null}
      </div> */}
    </div>
  );
}
