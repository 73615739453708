import { whoReserved } from '../../../../api/queReservation';
import { toast } from 'react-toastify';

export default async function checkReservationTable(
  getQuestionList,
  whichFunction,
  notReservedCount,
  setStartUpLoad,
  setQuestionList,
) {
  let tempGetQuestionList = [];
  notReservedCount = { current: 0 };
  for (let i = 0; i < getQuestionList.length; i++) {
    try {
      const whoResv = await whoReserved(getQuestionList[i].QuestionID);
      // console.log(whoResv.data);
      if (whoResv.data.data) {
        // sellectAllStatus = true;
        tempGetQuestionList.push({
          ...getQuestionList[i],
          isChecked: false,
          reservedBy: whoResv.data.data.reserved_by_name,
          reservationById: whoResv.data.data.reserved_by_id,
          isReserved: true,
        });
      } else {
        tempGetQuestionList.push(getQuestionList[i]);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  }

  let count = 0;

  tempGetQuestionList?.map((question) => {
    return question?.isReserved !== true && (count += 1);
    // : (reservedCount = reservedCount + 1);
  });
  notReservedCount.current = count;
  console.log(
    getQuestionList,
    whichFunction,
    notReservedCount,
    setStartUpLoad,
    setQuestionList,
    whichFunction === 'selectAll',
    'all',
  );

  if (whichFunction === 'selectAll') {
    return tempGetQuestionList;
  } else {
    if (setQuestionList) {
      setQuestionList(tempGetQuestionList);
    }
    if (setStartUpLoad) {
      setStartUpLoad(false);
    }
  }
}
