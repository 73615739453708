import { boardList } from '../../../../api/boards';
import { fetchCourse, fetchSubject, fetchSubSubject, fetchTopic, fetchSubTopic } from '../../../../api/institution';
import { fetchAccessLevel } from '../../../../api/qbMasterData';
import { fetchObjective } from '../../../../api/objective';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

export default async function fetchOtherResults(e, selLabel, setStartUpLoad, formData, setFormData, setState, institution_id) {
  if (selLabel === 'question_type_id') {
    setStartUpLoad(true);
    // board_id = -1;
    // course_id = -1;
    // subject_id = -1;
    // sub_subject_id = -1;
    // topic_1_id = -1;
    // topic_2_id = -1;
    // taxonomy_id = -1;
    setFormData((prevState) => {
      return {
        ...prevState,
        board_id: -1,
        course_id: -1,
        subject_id: -1,
        sub_subject_id: -1,
        topic_1_id: -1,
        sub_topic_1_id: -1,
        sub_topic_2_id: -1,
        topic_2_id: -1,
        taxonomy_id: -1,
      };
    });
    try {
      // const getBoard = await Axios.get(`${BASE_URL}/boards`, Auth);
      const getBoard = await boardList();
      setState((prevState) => {
        return {
          ...prevState,
          board: getBoard.data.data,
        };
      });
      setStartUpLoad(false);
    } catch (error) {
      errorHandle(error);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'board_id') {
    // course_id = -1;
    // subject_id = -1;
    // sub_subject_id = -1;
    // topic_1_id = -1;
    // topic_2_id = -1;
    // taxonomy_id = -1;
    setStartUpLoad(true);
    setFormData((prevState) => {
      return {
        ...prevState,
        course_id: -1,
        subject_id: -1,
        sub_subject_id: -1,
        topic_1_id: -1,
        sub_topic_1_id: -1,
        sub_topic_2_id: -1,
        topic_2_id: -1,
        taxonomy_id: -1,
      };
    });
    let board_id = e?.target?.value || e?.value || e;
    try {
      // const getCourse = await Axios.get(
      //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses`,
      //   Auth
      // );
      const getCourse = await fetchCourse(institution_id, board_id);
      setState((prevState) => {
        return {
          ...prevState,
          course: getCourse.data.data,
        };
      });
      setStartUpLoad(false);
    } catch (error) {
      errorHandle(error);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'course_id') {
    // subject_id = -1;
    // sub_subject_id = -1;
    // topic_1_id = -1;
    // topic_2_id = -1;
    // taxonomy_id = -1;
    setFormData((prevState) => {
      return {
        ...prevState,
        subject_id: -1,
        sub_subject_id: -1,
        topic_1_id: -1,
        sub_topic_1_id: -1,
        sub_topic_2_id: -1,
        topic_2_id: -1,
        taxonomy_id: -1,
      };
    });
    setStartUpLoad(true);
    let course_id = e?.target?.value || e?.value || e;
    try {
      // const getSubject = await Axios.get(
      //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects`,
      //   Auth
      // );
      const getSubject = await fetchSubject(institution_id, formData.board_id, course_id);
      setState((prevState) => {
        return {
          ...prevState,
          subject: getSubject.data.data,
        };
      });

      setStartUpLoad(false);
    } catch (error) {
      errorHandle(error);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'subject_id') {
    // sub_subject_id = -1;
    // topic_1_id = -1;
    // topic_2_id = -1;
    // taxonomy_id = -1;
    setFormData((prevState) => {
      return {
        ...prevState,
        sub_subject_id: -1,
        topic_1_id: -1,
        sub_topic_1_id: -1,
        sub_topic_2_id: -1,
        topic_2_id: -1,
        taxonomy_id: -1,
      };
    });
    setStartUpLoad(true);
    let subject_id = e?.target?.value || e?.value || e;
    try {
      // const getSubSubject = await Axios.get(
      //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects`,
      //   Auth
      // );
      const getSubSubject = await fetchSubSubject(institution_id, formData.board_id, formData.course_id, subject_id);
      setState((prevState) => ({
        ...prevState,
        subSubject: getSubSubject.data.data,
      }));

      setStartUpLoad(false);
    } catch (error) {
      console.log(error, 'error while fetching data');
      errorHandle(error);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'sub_subject_id') {
    // topic_1_id = -1;
    // topic_2_id = -1;
    // taxonomy_id = -1;
    setFormData((prevState) => {
      return {
        ...prevState,
        topic_1_id: -1,
        sub_topic_1_id: -1,
        sub_topic_2_id: -1,
        topic_2_id: -1,
        taxonomy_id: -1,
      };
    });
    setStartUpLoad(true);
    let sub_subject_id = e?.target?.value || e?.value || e;
    try {
      // const getTopic = await Axios.get(
      //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics`,
      //   Auth
      // );
      const getTopic = await fetchTopic(institution_id, formData.board_id, formData.course_id, formData.subject_id, sub_subject_id);
      setState((prevState) => ({
        ...prevState,
        topic: getTopic.data.data,
      }));

      setStartUpLoad(false);
    } catch (error) {
      console.log(error, 'error while fetching data');
      errorHandle(error);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'topic_1_id') {
    // topic_1_id = -1;
    // taxonomy_id = -1;
    setFormData((prevState) => {
      return {
        ...prevState,
        sub_topic_1_id: -1,
        sub_topic_2_id: -1,
        topic_2_id: -1,
        taxonomy_id: -1,
      };
    });
    setStartUpLoad(true);
    let topic_1_id = e?.target?.value || e?.value || e;
    try {
      // const getSubTopic = await Axios.get(
      //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics/${topic_1_id}/sub_topics`,
      //   Auth
      // );
      const getSubTopic = await fetchSubTopic(
        institution_id,
        formData.board_id,
        formData.course_id,
        formData.subject_id,
        formData.sub_subject_id,
        topic_1_id,
      );
      console.log(getSubTopic.data.data);
      setState((prevState) => ({
        ...prevState,
        subTopic: getSubTopic.data.data,
      }));

      setStartUpLoad(false);
    } catch (error) {
      console.log(error, 'error while fetching data');
      errorHandle(error);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'sub_topic_1_id') {
    setStartUpLoad(true);
    // sub_topic_1_id = e.target.value;
    try {
      // const getAccessLevel = await Axios.get(
      //   `${BASE_URL}/qb/access_levels`,
      //   Auth
      // );
      const getAccessLevel = await fetchAccessLevel();
      setState((prevState) => {
        return {
          ...prevState,
          accessLevel: getAccessLevel.data.data,
        };
      });
      setFormData((prevState) => {
        return {
          ...prevState,
          taxonomy_id: -1,
        };
      });
      setStartUpLoad(false);
    } catch (error) {
      errorHandle(error);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'topic_2_id') {
    setStartUpLoad(true);
    let topic_2_id = e?.target?.value || e?.value || e;
    try {
      const getSubTopic2 = await fetchSubTopic(
        institution_id,
        formData.board_id,
        formData.course_id,
        formData.subject_id,
        formData.sub_subject_id,
        topic_2_id,
      );
      setState((prevState) => {
        return {
          ...prevState,
          subTopic2: getSubTopic2.data.data,
        };
      });
      setStartUpLoad(false);
    } catch (error) {
      errorHandle(error);
      setStartUpLoad(false);
    }
  }
  if (selLabel === 'taxonomy_id') {
    setStartUpLoad(true);
    let taxonomy_id = e?.target?.value || e?.value || e;
    console.log(taxonomy_id);
    try {
      const getObjective = await fetchObjective(taxonomy_id);
      setState((prevState) => {
        return {
          ...prevState,
          objectives: getObjective.data.data,
        };
      });
      setStartUpLoad(false);
    } catch (error) {
      errorHandle(error);
      setStartUpLoad(false);
    }
  }
}
