/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import './viewQuestions.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateQueMetadata } from '../../../api/user';
import ViewDropDown from './ViewDropDown/ViewDropDown';
import ViewQuestionResult from './ViewSearchResult/ViewQuestionResult';
import handleSubmitQueView from './viewQuestionFunctions/handleSubmitQueView';
import errorHandle from '../../../utils/errorHandle/errorHandle';
import fetchData from './viewQuestionFunctions/fetchData';
import radioButton from './viewQuestionFunctions/radioButton';
import handlePagination from './viewQuestionFunctions/handlePagination';
import fetchOtherResults from './viewQuestionFunctions/fetchOtherResult';
import fetchMetaData from './viewQuestionFunctions/fetchMetaData';
import handleOnFocusViewQue from './viewQuestionFunctions/handleOnFocus';
import LoadingBars from '../../SubComponents/LoadingBars/LoadingBars';
import OnFocusCurator from './viewQuestionFunctions/onFocusCurator';
import handleDowSource from './viewQuestionFunctions/handleDowSource';
import handleDeleteView from './viewQuestionFunctions/handleDeleteView';
import DeletePopup from '../../SubComponents/DeletePopup/DeletePopup';
import TranslateQuestionsPopup from '../TranslateQuestions/translateQuestionPopup';

let institution_id = 0;
// var board_id = -1;
// var course_id = -1;
// var subject_id = -1;
// var sub_subject_id = -1;
// var topic_1_id = -1;
// var topic_2_id = -1;
// let metaData;
// var taxonomy_id = -1;
// var numberOfPages = 1;

function ViewQuestions() {
  const history = useNavigate();
  const [formData, setFormData] = useState({
    question_type_id: '',
    board_id: '',
    course_id: '',
    subject_id: '',
    sub_subject_id: '',
    topic_1_id: '',
    sub_topic_1_id: '',
    topic_2_id: '',
    sub_topic_2_id: '',
    difficulty_level: '',
    access_level_id: '',
    medium_id: '',
    question_code: '',
    from_date: '',
    to_date: '',
    added_by_user_id: localStorage.getItem('userId'),
    createdBy: '',
    curatedBy: '',
    createdByName: '',
    curatedByName: '',
    objective_id: '',
    taxonomy_id: '',
  });

  const [state, setState] = useState({
    questionsType: [],
    medium: [],
    board: [],
    course: [],
    subject: [],
    subSubject: [],
    topic: [],
    subTopic: [],
    subTopic2: [],
    accessLevel: [],
    achieverNameList: [],
    curatorNameList: [],
    objectives: [],
    taxonomies: [],
  });

  const [questionList, setQuestionList] = useState([]);
  const [questionView, setQuestionView] = useState(false);
  const [selLink, setSelLink] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [questionFileId, setQuestionFileId] = useState('');
  const [startUpLoad, setStartUpLoad] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [noData, setNoData] = useState(false);
  const [selData, setSelData] = useState({});
  const initialRender = useRef(true);
  const [questionType, setQuestionType] = useState('All');
  const [achieverSearch, setAchieverSearch] = useState('');
  const [achieverSelected, setAchieverSelected] = useState('');
  const [searchSuggestion, setSearchSuggestion] = useState(false);
  const [curatorSearch, setCuratorSearch] = useState('');
  const [curatorSelected, setCuratorSelected] = useState('');
  const [curatorSuggestion, setCuratorSuggestion] = useState(false);
  const [popupDelete, setPopupDelete] = useState(false);
  const [translatePopup, setTranslatePopup] = useState(false);

  console.log(questionType);
  const userId = window.localStorage.getItem('userId');
  const role = window.localStorage.getItem('role');
  // console.log(formData);
  // if (numberOfQuestions !== 0) {
  //   numberOfPages = Math.ceil(numberOfQuestions / 10);
  // }
  const handleTranslatePopup = () => {
    setTranslatePopup(!translatePopup);
  };

  function handleChange(e, selLabel) {
    const valueSele = ['question_code', 'from_date', 'to_date'].includes(selLabel)
      ? e?.target?.value
      : e?.target?.value || e?.value || e;

    if (selLabel === 'createdBy') {
      setAchieverSelected(valueSele);
      setAchieverSearch(valueSele);
      if (valueSele === '') {
        setFormData((prevState) => {
          return { ...prevState, createdBy: '' };
        });
      } else if (selLabel === 'curatedBy') {
        setCuratorSelected(valueSele);
        setCuratorSearch(valueSele);
        if (valueSele === '') {
          setFormData((prevState) => {
            return { ...prevState, curatedBy: '' };
          });
        }
      }
      // setFormData({ ...formData, [selLabel]: valueSele });
    } else {
      fetchOtherResults(e, selLabel, setStartUpLoad, formData, setFormData, setState, institution_id);
      setFormData((prevState) => ({
        ...prevState,
        [selLabel]: valueSele,
      }));
    }
  }

  // console.log(formData);

  function handleRadioButtons(e) {
    // console.log(e.target.value);
    setQuestionType(e.target.value);
  }

  function handleAchieverClick(object) {
    setAchieverSelected(object?.label);
    setSearchSuggestion(false);
    setFormData((prevState) => ({
      ...prevState,
      createdBy: object?.value,
      createdByName: object?.label,
    }));
    // setAchieverCodeSelected(object.achiever_code);
  }

  function handleCuratorClick(object) {
    setCuratorSuggestion(false);
    setFormData((prevState) => ({
      ...prevState,
      curatedBy: object?.value,
      curatedByName: object?.label,
    }));
    // setAchieverCodeSelected(object.achiever_code);
  }

  function handleUlClose() {
    setSearchSuggestion(false);
    setAchieverSelected('');
    setAchieverSearch('');
    setFormData((prevState) => {
      return { ...prevState, createdBy: '' };
    });
    // setAchieverCodeSelected("");
  }

  function handleUlCloseCurator() {
    setCuratorSuggestion(false);
    setCuratorSelected('');
    setCuratorSearch('');
    setFormData((prevState) => {
      return { ...prevState, curatedBy: '' };
    });
    // setAchieverCodeSelected("");
  }

  async function handleOnFocus() {
    handleOnFocusViewQue(setStartUpLoad, achieverSearch, setState, setSearchSuggestion);
  }

  async function handleOnFocusCurator() {
    OnFocusCurator(setStartUpLoad, curatorSearch, setState, setCuratorSuggestion);
  }

  function handleSubmit() {
    handleSubmitQueView(
      setStartUpLoad,
      setNoData,
      formData,
      questionType,
      offSet,
      setNumberOfQuestions,
      setQuestionList,
      setQuestionView,
    );
  }

  function handleClick(SelQue) {
    console.log(SelQue);
    setSelLink(SelQue?.image_path);
    setQuestionId(SelQue.question_id);
    setQuestionFileId(SelQue.question_file_id);
    setSelData(SelQue);
    // window.scrollTo(0, 2000);
  }

  async function handleEditDetails() {
    setStartUpLoad(true);
    const pref = {
      pref_key: 'view_question_defaults',
      pref_value_json: JSON.stringify({
        ...formData,
        questionType: questionType,
      }),
    };
    try {
      // metaData = await Axios.put(
      //   `${BASE_URL}/users/${userId}/preferences/question_metadata`,
      //   pref,
      //   Auth
      // );
      let metaData = await updateQueMetadata(userId, pref);
      console.log(metaData);
      setStartUpLoad(false);
    } catch (error) {
      errorHandle(error);
      setStartUpLoad(false);
    }
    // window.localStorage.setItem("question_id", questionId);
    // window.localStorage.setItem("questionFileId", questionFileId);
    window.localStorage.setItem('previousPath', 'viewQuestion');
    // window.localStorage.setItem("previousImageLink", selLink);
    window.localStorage.removeItem('formData');
    window?.localStorage.removeItem('reuseId');
    const state = {
      questionId,
      questionFileId: questionFileId ? questionFileId : 0,
      linkprv: selLink,
    };
    history('/admin/edit-details', {
      state,
    });
    // history('/admin/edit-details');
    // return window.open("/admin/add-details", "_blank", "noreferrer");
  }

  async function handleReuse() {
    // window.localStorage.setItem("question_id", questionId);
    // window.localStorage.setItem("questionFileId", questionFileId);
    window.localStorage.setItem('previousPath', 'viewQuestion');
    // window.localStorage.setItem("previousImageLink", selLink);
    window.localStorage.removeItem('formData');
    window.localStorage.setItem('sourceQueData', JSON.stringify(selData));
    // window.localStorage.setItem("reuse", true);
    window.localStorage.setItem('reuseId', selData.question_reuse_id !== null ? selData.question_reuse_id : selData.question_id);
    history('/admin/edit-details', {
      state: { questionId, questionFileId, linkprv: selLink },
    });
    // window.location = "/admin/add-details";
    // return (window.location = "/admin/add-details");
  }

  function handleTranslate() {
    window.localStorage.setItem('translateQuestion', JSON.stringify(selData));
    // window.location = '/admin/translate-questions';
    // history('/admin/translate-questions');
    setTranslatePopup(true);
  }
  async function handleDownloadSource() {
    handleDowSource(setStartUpLoad, selData);
  }

  async function handleDelete() {
    setPopupDelete(true);
  }

  function okDelete() {
    handleDeleteView(setStartUpLoad, questionId, userId, handleSubmit);
    setPopupDelete(false);
  }

  function cancel() {
    setPopupDelete(false);
  }

  function paginationClick(number, offSetNumber) {
    if (Math.ceil(numberOfQuestions / 10) > 1) {
      setPageNumber(+number);
      setOffSet(+offSetNumber);
      setStartUpLoad(true);
    }
  }

  useEffect(() => {
    if (questionList.length !== 0) {
      // document.getElementById("serachFirstQuestion").click();
      console.log(questionList[0]);
      setSelLink(questionList[0].image_path);
      setQuestionId(questionList[0].question_id);
      setQuestionFileId(questionList[0].question_file_id);
      setSelData(questionList[0]);
    }
    //eslint-disable-next-line
  }, [questionList]);

  useEffect(() => {
    fetchData(setState, setStartUpLoad);
    setFormData((prevState) => {
      return { ...prevState, added_by_user_id: userId };
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialRender.current !== true) {
      handleOnFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [achieverSearch]);

  useEffect(() => {
    if (initialRender.current !== true) {
      handleOnFocusCurator();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curatorSearch]);

  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const asyncfunc = async () => {
      const previousPath = window.localStorage.getItem('previousPath');
      window.localStorage.removeItem('previousPath');
      if (previousPath === 'addDetails') {
        await fetchMetaData(
          institution_id,
          userId,
          formData,
          questionType,
          setFormData,
          setQuestionType,
          setAchieverSelected,
          setState,
          setStartUpLoad,
          setCuratorSelected,
        );
        await handleOnFocus();
        await handleOnFocusCurator();
        console.log(formData);
        // setFormData((prevState) => prevState);
        await setSearchSuggestion(false);
        await setCuratorSuggestion(false);
        //  handleSubmit();
        document.getElementById('searchQuestions').click();
      }
    };
    asyncfunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialRender.current !== true) {
      setSelLink('');
      setQuestionId('');
      setQuestionFileId('');
      setSelData('');
      radioButton(setStartUpLoad, formData, questionType, offSet, setNumberOfQuestions, setQuestionList, setNoData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionType]);

  useEffect(() => {
    if (initialRender.current === true) {
      initialRender.current = false;
    } else {
      setSelLink('');
      setQuestionId('');
      setQuestionFileId('');
      setSelData('');
      handlePagination(offSet, setQuestionList, setStartUpLoad, formData, questionType);
    }
  }, [offSet]);

  return (
    <div>
      {startUpLoad ? <LoadingBars /> : null}
      {translatePopup ? (
        <div>
          <TranslateQuestionsPopup handleTranslatePopup={handleTranslatePopup} />
        </div>
      ) : null}
      <div className="viewQueContainer">
        <div className="queSearch">
          <ViewDropDown
            state={state}
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            achieverSelected={achieverSelected}
            searchSuggestion={searchSuggestion}
            handleUlClose={handleUlClose}
            handleAchieverClick={handleAchieverClick}
            handleOnFocus={handleOnFocus}
            curatorSelected={curatorSelected}
            curatorSuggestion={curatorSuggestion}
            handleUlCloseCurator={handleUlCloseCurator}
            handleCuratorClick={handleCuratorClick}
            handleOnFocusCurator={handleOnFocusCurator}
          />
        </div>
        <ViewQuestionResult
          questionView={questionView}
          questionType={questionType}
          handleRadioButtons={handleRadioButtons}
          questionList={questionList}
          selData={selData}
          handleClick={handleClick}
          paginationClick={paginationClick}
          numberOfQuestions={numberOfQuestions}
          offSet={offSet}
          pageNumber={pageNumber}
          selLink={selLink}
          handleEditDetails={handleEditDetails}
          handleReuse={handleReuse}
          noData={noData}
          role={role}
          handleTranslate={handleTranslate}
          handleDelete={handleDelete}
          handleDownloadSource={handleDownloadSource}
        />
      </div>
      <DeletePopup cancel={cancel} popup={popupDelete} okDelete={okDelete} />
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ViewQuestions;
