// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.migrateQueH2,
.migrateQueH3,
.migrateH3Arrow {
  margin: 0;
}
.migrateQueH3Div {
  padding: 0;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Screens/Migrate/MigrateResult/migrateresult.css"],"names":[],"mappings":"AAAA;;;EAGE,SAAS;AACX;AACA;EACE,UAAU;EACV,YAAY;AACd","sourcesContent":[".migrateQueH2,\n.migrateQueH3,\n.migrateH3Arrow {\n  margin: 0;\n}\n.migrateQueH3Div {\n  padding: 0;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
