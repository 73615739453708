import React from 'react';
import './directAnswer.css';

function DirectAnswer(props) {
  return (
    <div>
      <div className="compulsoryContainer">
        <div className="directAnswerSingleInput">
          <label className="searchQuestionLabel">{props.label}</label>
          <input
            className="searchQuestionSelect"
            type="text"
            onChange={(e) => props.onChange(e, props.name)}
            value={props.value}
          ></input>
        </div>
      </div>
    </div>
  );
}

export default DirectAnswer;
