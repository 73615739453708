import httpsMsSql from '../init/httpsMsSql';

//QB-MSSQL-01: Get Language List
export async function fetchMigrateLan() {
  const fetchMigrateLanData = await httpsMsSql.get(`/GetLanguageList`);
  return fetchMigrateLanData;
}

//QB-MSSQL-02: Get Syllabus List
export async function fetchMigrateSy() {
  const fetchMigrateSyData = await httpsMsSql.get(`/GetSyllabusList`);
  return fetchMigrateSyData;
}

//QB-MSSQL-03: Get Class List
export async function fetchMigrateClass(language_id, syllabus_id) {
  const fetchMigrateClassData = await httpsMsSql.get(`/GetClassList?languageID=${language_id}&SyllabusID=${syllabus_id}`);
  return fetchMigrateClassData;
}

//QB-MSSQL-04: Get Subject List
export async function fetchMigrateSubject(language_id, syllabus_id, class_id) {
  const fetchMigrateSubjectData = await httpsMsSql.get(
    `/GetSubjectList?languageID=${language_id}&SyllabusID=${syllabus_id}&ClassID=${class_id}`,
  );
  return fetchMigrateSubjectData;
}

//QB-MSSQL-05: Sub-subject list
export async function fetchMigrateSubSubject(subject_id) {
  const fetchMigrateSubSubjectData = await httpsMsSql.get(`/GetSubSubjectList?SubjectID=${subject_id}`);
  return fetchMigrateSubSubjectData;
}

//QB-MSSQL-06: Get Topic List
export async function fetchMigrateTopic(language_id, syllabus_id, class_id, subject_id, sub_subject_id) {
  const fetchMigrateTopicData = await httpsMsSql.get(
    `/GetTopicList?languageID=${language_id}&SyllabusID=${syllabus_id}&ClassID=${class_id}&SubjectID=${subject_id}&SubSubjectID=${sub_subject_id}`,
  );
  return fetchMigrateTopicData;
}

//QB-MSSQL-07: Get Sub-Topic List
export async function fetchMigrateSubTopic(topic_id) {
  const fetchMigrateSubTopicData = await httpsMsSql.get(`/GetSubTopicList?TopicID=${topic_id}`);
  return fetchMigrateSubTopicData;
}

//QB-MSSQL-08: Total No of Question Count
export async function fetchMigrateQuesCount(link) {
  const fetchMigrateQuesCountData = await httpsMsSql.get(`/GetQuestionTotalCount?${link}`);
  return fetchMigrateQuesCountData;
}

//QB-MSSQL-09: Question List
export async function fetchMigrateQues(link, offSet) {
  const fetchMigrateQuesData = await httpsMsSql.get(`/GetQuestionAllList?${link}&OFFSET=${offSet}&FETCHNEXT=${10}`);
  return fetchMigrateQuesData;
}
