import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import { useNavigate, Link } from 'react-router-dom';
import './login.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Axios from "axios";
// import { BASE_URL } from "../../config/Api";
import { loginFunction } from '../../../api/login';
import ReactLoading from 'react-loading';

const initialValues = {
  email: '',
  password: '',
};
const validationSchema = Yup.object({
  email: Yup.string().email().required('Email is Required'),
  password: Yup.string().required('Password is Required'),
});

function Login() {
  const [login, setLogin] = useState(false);
  const [startUpLoad, setStartUpLoad] = useState(false);
  const history = useNavigate();

  // var date = new Date();
  console.log(Date.now());

  const onSubmit = async (value) => {
    setStartUpLoad(true);
    const data = {
      email: value.email,
      password: value.password,
    };
    // Axios.post(`${BASE_URL}/user/signin`, data)
    await loginFunction(data)
      .then((response) => {
        toast.success('Login Successful');
        console.log(response.data.data.result);
        window.localStorage.setItem('token', response.data.data.token);
        console.log(response.data.data.expiry, Date.now());
        window.localStorage.setItem('expiry', response.data.data.expiry);
        window.localStorage.setItem('role', response.data.data.result.role);
        // window.localStorage.setItem("expiry", (Date.now() + 10000));
        window.localStorage.setItem('userName', response.data.data.result.full_name);
        window.localStorage.setItem('userId', response.data.data.result.user_id);
        setStartUpLoad(false);
        setTimeout(() => {
          setLogin(true);
        }, 2000);
      })
      .catch((err) => {
        toast.error('Email or Password is Wrong');
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
        setLogin(false);
      });
  };
  console.log(login);
  if (login) {
    history('/admin/upload-questions');
    // return <Route render={() => <Navigate to="/admin/upload-questions" />} />
  }

  return (
    <section className="container">
      <div className="loginContainer">
        {startUpLoad ? (
          <div>
            <ReactLoading type="spin" color="#68dff0" className="startLoadSpin" />
          </div>
        ) : null}
        <div>
          <h1 className="loginH1">Question Bank</h1>
        </div>
        <div>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {(formik) => {
              return (
                <div>
                  <Form>
                    <Box component="div">
                      <Field type="email" id="email" name="email" placeholder="Email Id" className="loginUsername" />
                    </Box>
                    <ErrorMessage name="email" component="div">
                      {(error) => <div className="error">{error}</div>}
                    </ErrorMessage>
                    <Box>
                      <Field type="password" id="password" name="password" placeholder="Password" className="loginUsername" />
                    </Box>
                    <ErrorMessage name="password" component="div">
                      {(error) => <div className="error">{error}</div>}
                    </ErrorMessage>
                    <div>
                      <Link to="/forgot-password" className="forgotPassword">
                        Forgot Password?
                      </Link>
                    </div>
                    <button type="submit" className="loginButton">
                      <div>Login</div>
                    </button>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* <p className="developer">Developed by Sagar Bazar</p> */}
      </div>
    </section>
  );
}

export default Login;
