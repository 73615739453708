import linkGen from './linkGen';
import { fetchQuestionsCount } from '../../../../api/questions';
import { fetchQuestions } from '../../../../api/questions';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

export default async function radioButton(
  setStartUpLoad,
  formData,
  questionType,
  offSet,
  setNumberOfQuestions,
  setQuestionList,
  setNoData,
) {
  setStartUpLoad(true);
  let linkFinal = linkGen(formData, questionType);
  console.log(linkFinal);
  try {
    // const getRadioNumberOfQuestions = await Axios.get(
    //   `${BASE_URL}/qb/questions/count_questions?${linkFinal}`,
    //   Auth
    // );
    const getRadioNumberOfQuestions = await fetchQuestionsCount(linkFinal);
    // const getRadioQuestionList = await Axios.get(
    //   `${BASE_URL}/qb/questions/search?${linkFinal}&limit=10&offset=${offSet}`,
    //   {
    //     headers: Auth.headers,
    //   }
    // );
    const getRadioQuestionList = await fetchQuestions(linkFinal, offSet);
    console.log(getRadioNumberOfQuestions.data.data.count);
    console.log(getRadioQuestionList.data.data);
    setNumberOfQuestions(getRadioNumberOfQuestions.data.data.count);
    setQuestionList(getRadioQuestionList.data.data);
    if (getRadioNumberOfQuestions.data.data.count > 0) {
      setStartUpLoad(false);
    } else {
      setStartUpLoad(false);
      setNoData(true);
    }
  } catch (error) {
    errorHandle(error);
    setStartUpLoad(false);
  }
}
