import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import TabList from '@mui/lab/TabList';

export default function TabRowAdd({ setTabSelected, tabSelected, curator, previousPath }) {
  const tabs = ['Question Print', 'Hierarchy Details', 'Response Details', 'Answer Details', 'Solution'];
  return (
    <div className="tabRowAdd">
      <Box>
        <Tabs
          value={tabSelected}
          centered
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          {tabs.map((tab, tabKey) => {
            return (
              // <button
              //   onClick={() => setTabSelected(tab)}
              //   key={tabKey}
              //   type="button"
              //   className={
              //     tab === tabSelected ? "tabButtonsAddSel" : "tabButtonsAdd"
              //   }
              // >
              //   {tab}
              // </button>

              <Tab label={tab} value={tab} onClick={() => setTabSelected(tab)} />
            );
          })}
        </Tabs>
      </Box>
      {curator && previousPath === 'viewQuestion' && (
        <button
          onClick={() => setTabSelected('Reject')}
          type="button"
          className={'Reject' === tabSelected ? 'tabButtonsAddSel' : 'tabButtonsAdd'}
        >
          Reject
        </button>
      )}
    </div>
  );
}
