import { fetchQuestionType } from '../../../../api/qbMasterData';
import { fetchMedium } from '../../../../api/mediums';
import { fetchTaxonomy } from '../../../../api/taxonomy';
import { toast } from 'react-toastify';

export async function fetchDataQSetReport(setState, state, setStartUpLoad) {
  try {
    const getQuestionType = await fetchQuestionType();
    const getMediums = await fetchMedium();
    const getTaxonomies = await fetchTaxonomy();
    setState({
      ...state,
      questionsType: getQuestionType.data.data,
      medium: getMediums.data.data,
      taxonomies: getTaxonomies.data.data,
    });
    setStartUpLoad(false);
  } catch (err) {
    if (err.response && err.response.data) {
      console.log(err.response.data.message);
      toast.error(err.response.data.message);
    } else {
      toast.error('Server Error');
    }
    setStartUpLoad(false);
  }
}
