import { toast } from 'react-toastify';

export default function errorHandle(error) {
  if (error.response && error.response.data) {
    console.log(error.response.data.message);
    toast.error(error.response.data.message);
  } else {
    toast.error('Server Error');
  }
}
