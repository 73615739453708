import React, { useEffect, useState } from 'react';

function MAQ(props) {
  const [correct, setCorrect] = useState(false);
  function handleCheck() {
    setCorrect(!correct);
  }

  useEffect(() => {
    if (props.array.includes(props.alphabet) === true) {
      setCorrect(true);
    }
    //eslint-disable-next-line
  }, [JSON.stringify(props.array)]);

  return (
    <div>
      <div className="directAnswerMatching">
        <div>
          <h4 className="optionsSerial">{props.alphabet}</h4>
        </div>
        <div>
          <label className="checkbox-label">
            <input
              type="checkbox"
              onChange={(e) => {
                props.onChange(e, props.name, props.alphabet);
                handleCheck();
              }}
              checked={correct}
            />
            <span className="mark"></span>
            Correct
          </label>
        </div>
      </div>
    </div>
  );
}

export default MAQ;
