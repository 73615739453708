import React from 'react';
import Dropdown from '../../../SubComponents/Dropdown/Dropdown';

export default function AddAnswer({ state, handleChange, formData, notFilled }) {
  const difficultyLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div className="addAnaswerDiv">
      <div className="addAnswerContainer">
        {/* <h4 className="compulsoryHeading">Answer Details</h4> */}
        <div className="dropdownFlex">
          <Dropdown
            placeholder="Taxonomy"
            label="Taxonomy"
            options={state.taxonomies}
            displayName="display_name"
            name="taxonomy_id"
            idName="id"
            onChange={handleChange}
            value={formData.taxonomy_id}
            req={true}
            notFill={notFilled}
          />
          <Dropdown
            placeholder="Objective"
            label="Objective"
            options={state.objectives}
            displayName="objective_code"
            name="objective_id"
            idName="objective_id"
            onChange={handleChange}
            value={formData.objective_id}
            req={true}
            notFill={notFilled}
          />
          <Dropdown
            placeholder="Difficulty Level"
            label="Difficulty Level"
            options={difficultyLevel}
            name="difficulty_level"
            onChange={handleChange}
            displayName=""
            value={formData.difficulty_level}
            req={true}
            notFill={notFilled}
          />
        </div>
        <div className="dropdownFlex">
          {/* <div className="moreDropdown"> */}
          <div className="answerDropdownDiv">
            <label
              className={
                (formData.max_marks === 0 || formData.max_marks === '') && notFilled === true ? 'dropdownLabelRed' : 'dropdownLabel'
              }
            >
              Max Marks
            </label>
            <input
              placeholder="Enter Max Marks"
              className="dropdownSelect"
              min="0"
              onChange={(e) => handleChange(e, 'max_marks')}
              type="number"
              required={true}
              value={formData.max_marks}
            ></input>
          </div>
          {/* </div> */}
          {/* <div className="moreDropdown"> */}
          <div className="answerDropdownDiv">
            <label
              className={
                (formData.time_required === 0 || formData.time_required === '') && notFilled === true
                  ? 'dropdownLabelRed'
                  : 'dropdownLabel'
              }
            >
              Time required(Sec)
            </label>
            <input
              placeholder="Enter Time required"
              className="dropdownSelect"
              min="10"
              step="5"
              onChange={(e) => handleChange(e, 'time_required')}
              type="number"
              required={true}
              value={formData.time_required}
            ></input>
          </div>
          {/* </div> */}
          <Dropdown
            placeholder="Medium"
            label="Medium"
            options={state.mediums}
            displayName="medium_name"
            name="medium_id"
            idName="medium_id"
            onChange={handleChange}
            value={formData.medium_id}
            req={true}
            notFill={notFilled}
          />
        </div>
      </div>
      {/* <div className="moreDropdown">
          <div className="dropdownDiv">
          <label className="dropdownLabel">Enable</label>
          <input
            className="addCheckbox"
            onChange={(e) => handleChange(e, "is_enabled")}
            type="checkbox"
            checked={formData.is_enabled}
          ></input>
          </div>
          </div> */}
      <div className="shortDescription">
        <label className={formData.short_description === '' && notFilled === true ? 'addLabelRed' : 'addLabel'}>
          Short Description (max 50 character)
        </label>
        <input
          placeholder="Can add multi-linguistic text by copy paste"
          className="addInput"
          maxLength="50"
          onChange={(e) => handleChange(e, 'short_description')}
          required={true}
          value={formData.short_description}
        ></input>
        <label className="addLabelDescripton">Description</label>
        <textarea
          placeholder="Enter Description"
          className="addInput"
          onChange={(e) => handleChange(e, 'description')}
          value={formData.description}
        ></textarea>
      </div>
      {/* </div> */}
    </div>
  );
}
