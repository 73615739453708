import React, { useState } from 'react';
import Dropdown from '../../SubComponents/Dropdown/Dropdown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './qSetMap.css';

const language = ['Select', 'Kannada', 'English', 'Telugu', 'Tamil', 'Hindi'];

function QSetMap() {
  const [formData, setFormData] = useState({
    QSetName: '',
    board: '',
    medium: '',
    class: '',
    subject: '',
    topic: '',
    title: '',
    shortDescription: '',
  });
  function handleChange(e, selLabel) {
    const valueSele = e.target.value;
    setFormData({ ...formData, [selLabel]: valueSele });
  }

  console.log(formData);

  return (
    <div>
      <form>
        <div className="queContainer">
          <h1 className="viewH3">
            <ArrowForwardIosIcon className="viewH3Arrow" />
            QSet Map
          </h1>
        </div>
        <div className="searchQuestionContainer">
          <h4 className="searchQuestionHeading">Enter QSet Details</h4>
          <div className="viewdropdownDiv">
            <label className="searchQuestionLabel">QSet Name</label>
            <input
              className="searchQuestionSelect"
              placeholder="6 Digit QSet Code"
              onChange={(e) => handleChange(e, 'QSetName')}
            ></input>
          </div>
          <div className="searchQuestionDropdown">
            <Dropdown label="Board" options={language} displayName="" name="board" idName="board_id" onChange={handleChange} />
          </div>
          {/* </div> */}
          {/* <div className="compulsorySubContainer"> */}
          <div className="searchQuestionDropdown">
            <Dropdown label="Medium" options={language} displayName="" name="medium" idName="medium_id" onChange={handleChange} />
          </div>
          <div className="searchQuestionDropdown">
            <Dropdown label="Class" options={language} displayName="" name="class" idName="class_id" onChange={handleChange} />
          </div>
          {/* </div> */}
          {/* <div className="compulsorySubContainer"> */}
          <div className="searchQuestionDropdown">
            <Dropdown
              label="Subject"
              options={language}
              displayName=""
              name="subject"
              idName="subject_id"
              onChange={handleChange}
            />
          </div>
          <div className="searchQuestionDropdown">
            <Dropdown label="Topic" options={language} displayName="" name="topic" idName="topic_id" onChange={handleChange} />
          </div>
          <div className="viewdropdownDiv">
            <label className="searchQuestionLabel">Title</label>
            <input className="searchQuestionSelect" onChange={(e) => handleChange(e, 'title')}></input>
          </div>
          <div className="viewdropdownDiv">
            <label className="searchQuestionLabel">Short Description</label>
            <input className="searchQuestionSelect" onChange={(e) => handleChange(e, 'shortDescription')}></input>
          </div>
        </div>
        <div className="mapContainer">
          <button className="mapSearch">Submit</button>
        </div>
      </form>
    </div>
  );
}

export default QSetMap;
