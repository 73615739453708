// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrum_row__5L5dB {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;

  font-size: 16px;
}
.breadcrum_home__FRIE- {
  list-style: none;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: #2b63d9;
}

.breadcrum_images__8411w {
  color: #2b63d9;
  list-style: none;

  height: 20px;
}
.breadcrum_text__IiafN {
  font-size: 16px;
  font-weight: 400;
  list-style: none;
  text-decoration: none;
}
.breadcrum_breedcrumb__L\\+u6m {
  width: 100%;
  background: #fff;
  padding: 15px 0px;
  border-bottom: 1px solid gray;
}
`, "",{"version":3,"sources":["webpack://./src/components/SubComponents/breadcrum/breadcrum.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,SAAS;;EAET,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,gBAAgB;;EAEhB,YAAY;AACd;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,6BAA6B;AAC/B","sourcesContent":[".row {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  margin: 0;\n\n  font-size: 16px;\n}\n.home {\n  list-style: none;\n  text-decoration: none;\n  font-size: 16px;\n  font-weight: 500;\n  color: #2b63d9;\n}\n\n.images {\n  color: #2b63d9;\n  list-style: none;\n\n  height: 20px;\n}\n.text {\n  font-size: 16px;\n  font-weight: 400;\n  list-style: none;\n  text-decoration: none;\n}\n.breedcrumb {\n  width: 100%;\n  background: #fff;\n  padding: 15px 0px;\n  border-bottom: 1px solid gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `breadcrum_row__5L5dB`,
	"home": `breadcrum_home__FRIE-`,
	"images": `breadcrum_images__8411w`,
	"text": `breadcrum_text__IiafN`,
	"breedcrumb": `breadcrum_breedcrumb__L+u6m`
};
export default ___CSS_LOADER_EXPORT___;
