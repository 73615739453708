import React from 'react';
import Pdf from '@mikecousins/react-pdf';
import Pagination from '../../../SubComponents/Pagination/Pagination';
import ReactLoading from 'react-loading';
import questionSearch from '../../../../assets/images/questionSearch.png';
import noDataImage from '../../../../assets/images/noData.png';
// import arrowForward from '../../../../assets/images/arrowFroward.png';
import PublicIcon from '../../../../assets/images/globe.png';
import organisation from '../../../../assets/images/organisation.png';
import createdByIcon from '../../../../assets/images/createdBy.png';
import reservedIcon from '../../../../assets/images/curatedBy.png';
import notReservedIcon from '../../../../assets/images/noneCurator.png';
import questionTypeIcon from '../../../../assets/images/curated.png';
import Breadcrums from '../../../SubComponents/breadcrum/breadcrum';
import './migrateresult.css';

export default function MigrateResult({
  noData,
  questionList,
  numberOfQuestions,
  handleSelectAll,
  notReservedCount,
  linkSel,
  selQuestionID,
  handleClick,
  handleCheckbox,
  paginationClick,
  offSet,
  pageNumber,
  selLink,
  handleAddToBacklog,
  migrateLoading,
  error,
  handleCancel,
  queNum,
  migrateComplete,
  errorQuestionId,
}) {
  let pageNum = 1;
  let imageFileText = 'File not supported';
  return (
    <div className="migrateResult">
      <div className="migrateQueH3Div">
        <Breadcrums title="Migrate Question" />
      </div>
      {questionList.length === 0 && noData === false ? (
        <div className="questionSearchImgDiv">
          <p className="useLeftPanel">Please use left panel to search migrate question</p>
          <img src={questionSearch} alt="question search" />
        </div>
      ) : null}
      {noData ? (
        <div className="questionSearchImgDiv">
          <p className="useLeftPanel">There is no data available</p>
          <img src={noDataImage} alt="question search" />
        </div>
      ) : null}
      {questionList !== undefined && numberOfQuestions !== 0 ? (
        <div className="migrateQueCountDiv">
          <h4 className="migrateQueCount">{numberOfQuestions} Questions</h4>
          <div className="selectDiv">
            <div className="selectAllDiv">
              <input
                type="checkbox"
                onChange={(e) => handleSelectAll(e)}
                className="selectAllInput"
                checked={
                  notReservedCount.current !== 0
                    ? linkSel.current.length === parseInt(notReservedCount.current)
                      ? true
                      : false
                    : false
                }
                // disabled= {sellectAllStatus}
              ></input>
              <p className="selectAllP">Select All</p>
            </div>
            <div>{linkSel.current.length === 0 ? null : <p className="selectedP">{linkSel.current.length} Selected</p>}</div>
          </div>
        </div>
      ) : null}
      {questionList !== undefined && numberOfQuestions !== 0 ? (
        <div className="migrateCompulsoryContainer">
          <div className="migrateTableDiv">
            <div className="tableContainerMigrate">
              {/* <table className="migrateTable">  */}
              {/* <thead>
                  <tr>
                    <th>Sl. No.</th>
                    <th>Select</th>
                    <th>Question #</th>
                    <th className="child2Migrate">Old Question Code</th>
                    <th className="child3Migrate">Access Level</th>
                    <th>Question Type</th>
                    <th>Migrate Status</th>
                     <th>Photo Path</th> 
                  </tr>
                </thead>
                 <tbody>  */}
              {questionList.map((row, questionKey) => {
                return (
                  // <tr
                  //   key={questionKey}
                  //   className={
                  //     +selQuestionID === +row.QuestionID ? "selQSet" : null
                  //   }
                  //   onClick={(e) =>
                  //     handleClick(row.PhotoPath, row.QuestionID)
                  //   }
                  // >
                  //   <td>{questionKey + 1}.</td>
                  //   <td>
                  //     {row.IsMigrate === true ? (
                  //       "Migrated"
                  //     ) : (
                  //       <input
                  //         type="checkbox"
                  //         onChange={(e) =>
                  //           handleCheckbox(e, row.PhotoPath, row)
                  //         }
                  //         checked={row.isChecked ? row.isChecked : false}
                  //         className={
                  //           // row.IsMigrate === true ? "checkboxhid" : null
                  //           row.isReserved === true ? "checkboxhid" : null
                  //         }
                  //         // disabled={row.IsMigrate === true ? true : false}
                  //         disabled={row.isReserved === true ? true : false}
                  //         name={row.QuestionID}
                  //       ></input>
                  //     )}
                  //   </td>
                  //   <td>{row.QuestionID}</td>
                  //   <td>{row.QuestionNumber}</td>
                  //   <td>{row.AccessLevel}</td>
                  //   <td>{row.QuestionType}</td>
                  //   <td>
                  //     {/* {row.IsMigrate === true ? "Migrated" : "Not-Migrated"} */}
                  //     {row.isReserved
                  //       ? "Reserved by " + row.reservedBy
                  //       : "Not Reserved"}
                  //   </td>
                  //   {/* <td>
                  //     {row.PhotoPath.slice(-3) === "pdf" ? (
                  //       <Pdf file={row.PhotoPath} page={pageNum} />
                  //     ) : (
                  //       imageFileText
                  //     )}
                  //   </td> */}
                  // </tr>
                  <div
                    key={questionKey}
                    onClick={(e) => handleClick(row.PhotoPath, row.QuestionID)}
                    className={+selQuestionID === +row.QuestionID ? 'selMigrateQue' : 'nonSelMigrateQue'}
                  >
                    <p className="migrateTableNumber">{questionKey + 1}.</p>
                    {row.IsMigrate === true ? (
                      'Migrated'
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => handleCheckbox(e, row.PhotoPath, row)}
                        checked={row.isChecked ? row.isChecked : false}
                        className={
                          // row.IsMigrate === true ? "checkboxhid" : null
                          row.isReserved === true ? 'checkboxhid' : 'migrateTableCheckbox'
                        }
                        // disabled={row.IsMigrate === true ? true : false}
                        disabled={row.isReserved === true ? true : false}
                        name={row.QuestionID}
                      ></input>
                    )}
                    <div className="migrateTableContentDiv">
                      <div className="migrateTablePDiv">
                        <p className="migrateContentP">#{row.QuestionID}</p>
                        <p className="migrateContentP">{row.QuestionNumber}</p>
                        <img src={questionTypeIcon} alt="question type" className="migrateTableQueTypeImg" />
                        <p className="migrateContentP">{row.QuestionType}</p>
                      </div>
                      {/* {row.IsMigrate === true ? "Migrated" : "Not-Migrated"} */}
                      {row.isReserved ? (
                        <div style={{ display: 'flex' }}>
                          <img src={reservedIcon} alt="reserved by" className="migrateTableQReserveImg" />
                          <p className="migrateContentReserve">Reserved by {row.reservedBy}</p>
                        </div>
                      ) : (
                        <div style={{ display: 'flex' }}>
                          <img src={notReservedIcon} alt="reserved by" className="migrateTableQReserveImg" />
                          <p className="migrateContentReserve">Not Reserved</p>
                        </div>
                      )}
                    </div>
                    {row.AccessLevel === 'Global' && <img src={PublicIcon} alt="glode" className="migratePublicIcon" />}
                    {row.AccessLevel === 'Organisation' && (
                      <img src={organisation} alt="organisation" className="migratePublicIcon" />
                    )}
                    {row.AccessLevel === 'Private' && <img src={createdByIcon} alt="personal" className="migratePublicIcon" />}
                  </div>
                );
              })}
              {/* </tbody>
              </table> */}
              {/* )} */}
            </div>
            <Pagination
              paginationClick={paginationClick}
              numberOfRows={numberOfQuestions}
              offSet={offSet}
              pageNumber={pageNumber}
            />
          </div>
          <div className="migrateImgDisplay">
            {selLink !== '' ? (
              <div className="migrateImageContainer ">
                {selLink.slice(-3) === 'pdf' ? (
                  <Pdf file={selLink} page={pageNum} className="migrateBigImage" />
                ) : (
                  <p>{imageFileText}</p>
                )}
              </div>
            ) : null}
            <div className="migrateAddContainer">
              <button className="migrateButton" onClick={handleAddToBacklog}>
                Add To My Backlogs
              </button>
              <div className="migrateLoading">
                {migrateLoading && error === false ? (
                  <div className="loadingDivMigrateAdd">
                    <button onClick={handleCancel} className="cancelMigrate">
                      Cancel
                    </button>
                    <ReactLoading type="spinningBubbles" color="#68dff0" className="reactLoadingIconAdd" />
                    <h5 className="loadingTextAdd">
                      Migrating {queNum} / {linkSel.current.length}
                    </h5>
                  </div>
                ) : null}
                {migrateComplete && error === false ? (
                  <div className="loadingDiv">
                    <h5 className="loadingTextMigrate">
                      Migration Complete {linkSel.current.length}/{linkSel.current.length}
                    </h5>
                  </div>
                ) : null}
                {error ? (
                  <div className="loadingDiv">
                    <h5 className="loadingTextMigrateError">Error! Please try again.</h5>
                  </div>
                ) : null}
              </div>
            </div>
            {error
              ? errorQuestionId.map((errQueId, errKey) => {
                  return (
                    <p className="errorP" key={errKey}>
                      Question Id - {errQueId}, has invalid PDF structure.
                    </p>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}
