import React from 'react';
import styles from './card.module.css';
import configuration from '../../config/Config';
import { Player } from '@lottiefiles/react-lottie-player';

function Card({ row, handleAddDetails, handleDelete }) {
  // const [rejectReason, setRejectReason] = useState(false);
  const iniFilePath = configuration.digitalOceanSpaces;
  // function handleEnter() {
  //   if (row.is_rejected === true) {
  //     console.log(123);
  //     setRejectReason(true);
  //   }
  // }

  // function handleLeave() {
  //   console.log(456);
  //   setRejectReason(false);
  // }
  return (
    <div
      className={row.is_rejected ? styles.cardReject : styles.card}
      //
    >
      <div className={styles.cardPDiv}>
        <p className={row.is_rejected ? styles.cardPReject : styles.cardP}>#{row.question_file_id}</p>
      </div>
      <div className={styles.cardImgDiv}>
        {row.question_file_path?.endsWith('.json') ? (
          <Player
            autoplay
            loop
            src={iniFilePath + row?.question_file_path}
            style={{
              width: '100%',
              height: '250px',
              background: 'white',
              borderRadius: '10px',
              border: '0.5px solid #9c9c9c',
            }}
          />
        ) : (
          <img
            className={styles.questionImage}
            src={iniFilePath + row.question_file_path}
            alt="question"
            // onMouseEnter={handleEnter}
            // onMouseLeave={handleLeave}
          />
        )}
      </div>
      <div className={row.is_rejected ? styles.cardButtonDivReject : styles.cardButtonDiv}>
        <button className={styles.cardBtnDelete} onClick={(e) => handleDelete(row.question_file_id)}>
          Delete
        </button>
        <button
          className={styles.cardBtnAdd}
          onClick={(e) => handleAddDetails(row.question_file_path, row.question_file_id, row.migrate_question_id)}
        >
          Edit Details
        </button>
      </div>
      {/* {rejectReason === true ? ( */}
      {row.is_rejected ? (
        <div className={styles.rejectOverlay} /* style={{ pointerEvents: "none" }} */>
          <div className={styles.rejectTextDiv}>
            <p className={styles.rejectText}>{row.reject_reason ? row.reject_reason : 'No reason specified'}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Card;
