import { getQueMetadata } from '../../../../api/user';
import { questionMetadata } from '../../../../api/user';
import fetchDropdownData from './fetchDropdownData';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

export default async function fetchMetaData(
  institution_id,
  userId,
  formData,
  questionType,
  setFormData,
  setQuestionType,
  setAchieverSelected,
  setState,
  setStartUpLoad,
  setCuratorSelected,
) {
  try {
    // const submitedMetaData = await Axios.get(
    //   `${BASE_URL}/users/${userId}/preferences/question_metadata?pref_key=view_question_defaults`,
    //   Auth
    // );
    const submitedMetaData = await getQueMetadata(userId, 'view_question_defaults');
    console.log(submitedMetaData.data.data[0]);
    if (submitedMetaData.data.data.length === 0) {
      // async function meta() {
      const pref = {
        pref_key: 'view_question_defaults',
        pref_value_json: JSON.stringify({
          ...formData,
          questionType: questionType,
        }),
      };
      try {
        // metaData = await Axios.post(
        //   `${BASE_URL}/users/${userId}/preferences/question_metadata`,
        //   pref,
        //   Auth
        // );
        let metaData = await questionMetadata(userId, pref);
        console.log(metaData);
      } catch (error) {
        errorHandle(error);
        setStartUpLoad(false);
      }
    }
    if (submitedMetaData.data.data.length !== 0) {
      const subData = JSON.parse(submitedMetaData.data.data[0].pref_value_json);
      console.log(subData);
      // if (subData.answer_json !== "" && subData.answer_json !== "{}") {
      setFormData((prevState) => {
        console.log(prevState);
        return {
          ...prevState,
          question_type_id: subData.question_type_id,
          board_id: subData.board_id,
          course_id: subData.course_id,
          subject_id: subData.subject_id,
          sub_subject_id: subData.sub_subject_id,
          topic_1_id: subData.topic_1_id,
          sub_topic_1_id: subData.sub_topic_1_id,
          topic_2_id: subData.topic_2_id,
          sub_topic_2_id: subData.sub_topic_2_id,
          difficulty_level: subData.difficulty_level,
          access_level_id: subData.access_level_id,
          medium_id: subData.medium_id,
          question_code: subData.question_code,
          from_date: subData.from_date,
          to_date: subData.to_date,
          added_by_user_id: userId,
          createdBy: subData.createdBy,
          objective_id: subData.objective_id,
          taxonomy_id: subData.taxonomy_id,
        };
      });
      setQuestionType(subData.questionType);
      setAchieverSelected(subData.createdByName);
      setCuratorSelected(subData.curatedByName);
      // }
      await fetchDropdownData(
        institution_id,
        subData.board_id,
        subData.course_id,
        subData.subject_id,
        subData.sub_subject_id,
        subData.topic_1_id,
        subData.taxonomy_id,
        setState,
        setStartUpLoad,
      );
    }
  } catch (error) {
    errorHandle(error);
    setStartUpLoad(false);
  }
}
