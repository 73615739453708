import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Link, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import './newNavebar.css';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';

const questionsList = [
  {
    label: 'My Backlog',
    value: '/admin/backlog',
  },
  {
    label: 'Upload Questions',
    value: '/admin/upload-questions',
  },
  {
    label: 'Migrate',
    value: '/admin/migrate',
  },
  {
    label: 'View Questions',
    value: '/admin/view-questions',
  },
  // {
  //   label: "Translate Questions",
  //   value: "/admin/translate-questions",
  // },
];

const qSetList = [
  {
    label: 'Qset Creator',
    value: '/admin/q-set-creator',
  },
  {
    label: 'Qset View',
    value: '/admin/q-set-view',
  },
  // {
  //   label: "Qset Map",
  //   value: "/admin/q-set-map",
  // },
];
const reportlist = [
  {
    label: 'Topic Report',
    value: '/admin/topic-report',
  },
  {
    label: 'Sub-Topic-Report',
    value: '/admin/sub-topic-report',
  },
  {
    label: 'Qset Report',
    value: '/admin/q-set-report',
  },
];

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #352dc8',
    padding: '0',
  },
}));
export default function Navbarnew() {
  const pathname = useLocation();
  const history = useNavigate();
  // const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openMenue, setOpenMenue] = useState({
    questions: false,
    qSet: false,
    report: false,
  });

  function handleLogout() {
    window.localStorage.clear();
    history('/');
  }
  return (
    <div className="navbarContainer">
      <div>
        <Link className="navbarLogo" to="/admin">
          <img src={require('../../assets/images/stucleLogo.png')} alt="stucle question bank logo" className="navbarQueBankLogo" />
          <h1 className="navbarH1">Question Bank</h1>
          {/* <div className="navbarh2">ver 0.1</div> */}
        </Link>
      </div>

      <div className=" menucontainer">
        <div
          onMouseOver={() => {
            setOpenMenue({
              ...openMenue,
              questions: true,
            });
          }}
          onMouseLeave={() => {
            setOpenMenue({
              ...openMenue,
              questions: false,
            });
          }}
        >
          <HtmlTooltip
            open={openMenue?.questions}
            onOpen={() => {
              setOpenMenue({ ...openMenue, questions: true });
            }}
            // onClose={() => {
            //   setOpenMenue({
            //     ...openMenue,
            //     questions: false,
            //   });
            // }}
            title={
              <React.Fragment>
                {questionsList?.map((i) => (
                  <MenuItem
                    onClick={() => {
                      history(i.value);
                      setOpenMenue({
                        ...openMenue,
                        questions: false,
                      });
                    }}
                  >
                    <span className={`${pathname.pathname === i.value ? 'selectedItem' : ''}`}>{i.label}</span>
                  </MenuItem>
                ))}
              </React.Fragment>
            }
          >
            <Button
              className={`${
                [
                  '/admin/backlog',
                  '/admin/upload-questions',
                  '/admin/migrate',
                  '/admin/view-questions',
                  '/admin/edit-details',
                  '/admin/add-details',
                ]?.includes(pathname?.pathname)
                  ? 'activenavbutton'
                  : 'navbutton'
              }`}
            >
              {' '}
              <img src={require('../../assets/images/questionIcon.png')} alt="question icon" className="navPageIcon" />
              Questions
            </Button>
          </HtmlTooltip>
        </div>
        <div
          onMouseOver={() => {
            setOpenMenue({
              ...openMenue,
              qSet: true,
            });
          }}
          onMouseLeave={() => {
            setOpenMenue({
              ...openMenue,
              qSet: false,
            });
          }}
        >
          <HtmlTooltip
            open={openMenue?.qSet}
            onOpen={() => {
              setOpenMenue({
                ...openMenue,
                qSet: true,
              });
            }}
            // onClose={() => {
            //   setOpenMenue({
            //     ...openMenue,
            //     qSet: false,
            //   });
            // }}
            title={
              <React.Fragment>
                {qSetList?.map((i) => (
                  <MenuItem
                    onClick={() => {
                      history(i.value);
                      setOpenMenue({
                        ...openMenue,
                        qSet: false,
                      });
                    }}
                  >
                    <span className={`${pathname.pathname === i.value ? 'selectedItem' : ''}`}>{i.label}</span>
                  </MenuItem>
                ))}
              </React.Fragment>
            }
          >
            <Button className={`${qSetList?.map((i) => i.value).includes(pathname?.pathname) ? 'activenavbutton' : 'navbutton'}`}>
              {' '}
              <img src={require('../../assets/images/qSetIcon.png')} alt="question icon" className="navPageIcon" />
              Question set
            </Button>
          </HtmlTooltip>
        </div>
        <div
          onMouseOver={() => {
            setOpenMenue({
              ...openMenue,
              report: true,
            });
          }}
          onMouseLeave={() => {
            setOpenMenue({
              ...openMenue,
              report: false,
            });
          }}
        >
          <HtmlTooltip
            open={openMenue?.report}
            onOpen={() => {
              setOpenMenue({
                ...openMenue,
                report: true,
              });
            }}
            // onClose={() => {
            //   setOpenMenue({
            //     ...openMenue,
            //     report: false,
            //   });
            // }}
            title={
              <React.Fragment>
                {reportlist?.map((i) => (
                  <MenuItem
                    onClick={() => {
                      history(i.value);
                      setOpenMenue({
                        ...openMenue,
                        report: false,
                      });
                    }}
                  >
                    <span className={`${pathname.pathname === i.value ? 'selectedItem' : ''}`}>{i.label}</span>
                  </MenuItem>
                ))}
              </React.Fragment>
            }
          >
            <Button className={`${reportlist?.map((i) => i.value).includes(pathname?.pathname) ? 'activenavbutton' : 'navbutton'}`}>
              {' '}
              <img src={require('../../assets/images/reportsIcon.png')} alt="question icon" className="navPageIcon" />
              Reports
            </Button>
          </HtmlTooltip>
        </div>
      </div>
      <div className="logout">
        {/* <ClickAwayListener>
          <div>
            <HtmlTooltipLog
              // PopperProps={{
              //   disablePortal: true,
              // }}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="bottom-end"
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    <MenuItem
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Typography>
                </React.Fragment>
              }
            >
              <Button>
                <div>
                  <div
                    className="navbarUserInfo"
                    // onClick={() => setTeacher(!teacher)}
                  >
                    <p className="NavTeacherName">
                      {window.localStorage.userName}
                    </p>
                    <div className="navTeacherImg">
                      <p className="navTeacherLetter">
                        {window?.localStorage?.userName?.slice(0, 1)}
                      </p>
                    </div>
                    <img
                      src={require("../../assets/images/navbarDownArrow.png")}
                      alt="arrow down"
                      className="navbarDownArrow"
                    />
                  </div>
                </div>
              </Button>
            </HtmlTooltipLog>
          </div>
        </ClickAwayListener> */}
        <React.Fragment>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Tooltip title="">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{
                  // ml: 2,
                  border: '1px solid gray',
                  borderRadius: '8px',
                  color: 'white',
                  padding: '10px ',
                  background: open ? '#51cdc5' : null,
                }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                {window.localStorage.userName}

                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    marginLeft: '10px',
                    background: '#f46304',
                    marginRight: '10px',
                  }}
                >
                  {window?.localStorage?.userName?.slice(0, 1)}
                </Avatar>
                <img src={require('../../assets/images/navbarDownArrow.png')} alt="arrow down" className="navbarDownArrow" />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                handleLogout();
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </React.Fragment>
      </div>
    </div>
  );
}
