import React from 'react';
import './summaryTable.css';
// import { Padding } from '@mui/icons-material';

var uniqueTopicArray = [];
var countArrayTopic = [];
var tempTopicArray = [];
var tempTopicMarksArray = [];
var marksArrayTopic = [];
var uniqueSubTopicArray = [];
var countArraySubTopic = [];
var tempSubTopicArray = [];
var tempSubTopicMarksArray = [];
var marksArraySubTopic = [];
var uniqueDifficultyArray = [];
var countArrayDifficulty = [];
var tempDifficultyArray = [];
var unsortedDifficultyObject = [];
var sortedDifficultyObject = [];
var uniqueObjectiveArray = [];
var countArrayObjective = [];
var tempObjectiveArray = [];
var uniqueMarksArray = [];
var countArrayMarks = [];
var tempMarksArray = [];
var unsortedMarksObject = [];
var sortedMarksObject = [];

export const SumofArray = (e) => {
  var sum = 0;
  for (let i = 0; i < e?.length; i++) {
    sum += Number(e[i]);
  }
  return sum;
};

export function TopicTable(props) {
  tempTopicArray = props.checkedRows.map((row, rowIndex) => {
    return row.topic;
  });

  tempTopicMarksArray = props.checkedRows.map((row) => {
    return { topic: row.topic, marks: row.max_marks };
  });

  uniqueTopicArray = [];
  countArrayTopic = [];
  marksArrayTopic = [];
  tempTopicArray.forEach((topic) => {
    if (!uniqueTopicArray.includes(topic)) {
      uniqueTopicArray.push(topic);
    }
  });

  uniqueTopicArray.forEach((topic, topicIndex) => {
    countArrayTopic[topicIndex] = 0;
    marksArrayTopic[topicIndex] = 0;
  });

  // console.log(uniqueTopicArray);
  uniqueTopicArray.forEach((topic, topicIndex) => {
    tempTopicArray.forEach((tempTopic, tempIndex) => {
      if (topic === tempTopic) {
        countArrayTopic[topicIndex] = countArrayTopic[topicIndex] + 1;
      }
    });
  });

  uniqueTopicArray.forEach((topic, topicIndex) => {
    tempTopicMarksArray.forEach((tempTopicMarks, tempIndex) => {
      // console.log(topic, tempTopicMarks.topic, marksArrayTopic[topicIndex])
      if (topic === tempTopicMarks.topic) {
        marksArrayTopic[topicIndex] = marksArrayTopic[topicIndex] + tempTopicMarks.marks;
      }
    });
  });

  console.log(marksArrayTopic, uniqueTopicArray, props.checkedRows);

  return (
    <div className="tablecontainer">
      <table className="summaryTableVertical">
        <thead>
          {/* <tr>
            <th>Topic</th>
            {uniqueTopicArray.map((topic, topicIndex) => {
              return <th key={topicIndex}>{topic}</th>;
            })}
          </tr> */}
          <tr>
            <th>Topic</th>
            {/* <th>Count</th> */}
            <th style={{ whiteSpace: 'nowrap' }}>Marks ( {SumofArray(marksArrayTopic)} )</th>
          </tr>
        </thead>
        <tbody>
          {/* <tr>
            <td>Count</td>
            {countArrayTopic.map((item, itemIndex) => {
              return <td key={itemIndex}>{item}</td>;
            })}
          </tr> */}
          {uniqueTopicArray.map((topic, topicIndex) => {
            return (
              <tr key={topicIndex}>
                <td className="summaryTabletextAlign">{topic}</td>
                {/* <td>{countArrayTopic[topicIndex]}</td> */}
                <td>{marksArrayTopic[topicIndex]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export function SubTopicTable(props) {
  tempSubTopicArray = props.checkedRows.map((row, rowIndex) => {
    return row.sub_topic;
  });
  tempSubTopicMarksArray = props.checkedRows.map((row) => {
    return { subTopic: row.sub_topic, marks: row.max_marks };
  });
  uniqueSubTopicArray = [];
  countArraySubTopic = [];
  marksArraySubTopic = [];
  tempSubTopicArray.forEach((subTopic) => {
    if (!uniqueSubTopicArray.includes(subTopic)) {
      uniqueSubTopicArray.push(subTopic);
    }
  });

  uniqueSubTopicArray.forEach((subTopic, subTopicIndex) => {
    countArraySubTopic[subTopicIndex] = 0;
    marksArraySubTopic[subTopicIndex] = 0;
  });

  // console.log(uniqueSubTopicArray);
  uniqueSubTopicArray.forEach((subTopic, subTopicIndex) => {
    tempSubTopicArray.forEach((tempSubTopic, subTempIndex) => {
      if (subTopic === tempSubTopic) {
        countArraySubTopic[subTopicIndex] = countArraySubTopic[subTopicIndex] + 1;
      }
    });
  });

  uniqueSubTopicArray.forEach((subTopic, subTopicIndex) => {
    tempSubTopicMarksArray.forEach((tempSubTopicMarks, tempIndex) => {
      console.log(subTopic, tempSubTopicMarks.subTopic, marksArraySubTopic[subTopicIndex]);
      if (subTopic === tempSubTopicMarks.subTopic) {
        marksArraySubTopic[subTopicIndex] = marksArraySubTopic[subTopicIndex] + tempSubTopicMarks.marks;
      }
    });
  });

  console.log(marksArraySubTopic);

  return (
    <div>
      <table className="summaryTableVertical">
        <thead>
          <tr>
            <th>Sub-Topic</th>
            <th style={{ whiteSpace: 'nowrap' }}>Marks ( {SumofArray(marksArraySubTopic)} )</th>
            {/* {uniqueSubTopicArray.map((subTopic, subTopicIndex) => {
              return <th key={subTopicIndex}>{subTopic}</th>;
            })} */}
          </tr>
        </thead>
        <tbody>
          {/* <tr>
            <td>Count</td>
            {countArraySubTopic.map((item, itemIndex) => {
              return <td key={itemIndex}>{item}</td>;
            })}
          </tr> */}
          {uniqueSubTopicArray.map((subTopic, subTopicIndex) => {
            return (
              <tr key={subTopicIndex}>
                <td
                  className="summaryTabletextAlign"
                  // style={{
                  //   width: "300px",
                  //   whiteSpace: "nowrap",
                  //   overflow: "hidden",
                  //   textOverflow: "ellipsis",
                  // }}
                >
                  {subTopic}
                </td>
                <td>{marksArraySubTopic[subTopicIndex]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export function DifficultyLevel(props) {
  tempDifficultyArray = props.checkedRows.map((row, rowIndex) => {
    return row.difficulty_level;
  });
  uniqueDifficultyArray = [];
  countArrayDifficulty = [];
  unsortedDifficultyObject = [];
  sortedDifficultyObject = [];
  tempDifficultyArray.forEach((difficulty) => {
    if (!uniqueDifficultyArray.includes(difficulty)) {
      uniqueDifficultyArray.push(difficulty);
    }
  });

  uniqueDifficultyArray.forEach((difficulty, difficultyIndex) => {
    countArrayDifficulty[difficultyIndex] = 0;
  });

  // console.log(uniqueDifficultyArray);
  uniqueDifficultyArray.forEach((difficulty, difficultyIndex) => {
    tempDifficultyArray.forEach((tempDifficulty, difficultyTempIndex) => {
      if (difficulty === tempDifficulty) {
        countArrayDifficulty[difficultyIndex] = countArrayDifficulty[difficultyIndex] + 1;
      }
    });
  });

  unsortedDifficultyObject = uniqueDifficultyArray.map((difficulty, difficultyIndex) => {
    return {
      difficultyLevel: difficulty,
      count: countArrayDifficulty[difficultyIndex],
    };
  });

  sortedDifficultyObject = unsortedDifficultyObject.sort(function (a, b) {
    return a.difficultyLevel - b.difficultyLevel;
  });

  // console.log(countArrayDifficulty);

  return (
    <div>
      <table className="summaryTable">
        <thead>
          <tr>
            <th>Difficulty Level</th>
            {sortedDifficultyObject.map((difficulty, difficultyIndex) => {
              return <th key={difficultyIndex}>{difficulty.difficultyLevel}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Count</td>
            {sortedDifficultyObject.map((item, itemIndex) => {
              return <td key={itemIndex}>{item.count}</td>;
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export function Objective(props) {
  tempObjectiveArray = props.checkedRows.map((row, rowIndex) => {
    return row.objective;
  });
  uniqueObjectiveArray = [];
  countArrayObjective = [];
  tempObjectiveArray.forEach((objective) => {
    if (!uniqueObjectiveArray.includes(objective)) {
      uniqueObjectiveArray.push(objective);
    }
  });

  uniqueObjectiveArray.forEach((objective, objectiveIndex) => {
    countArrayObjective[objectiveIndex] = 0;
  });

  // console.log(uniqueObjectiveArray);
  uniqueObjectiveArray.forEach((objective, objectiveIndex) => {
    tempObjectiveArray.forEach((tempObjective, objectiveTempIndex) => {
      if (objective === tempObjective) {
        countArrayObjective[objectiveIndex] = countArrayObjective[objectiveIndex] + 1;
      }
    });
  });

  // console.log(countArrayObjective);

  return (
    <div>
      <table style={{ width: '100%' }} className="summaryTables">
        <thead style={{ width: '100%' }}>
          <tr style={{ width: '100%' }}>
            <th>Objective</th>
            <th width="20%"></th>
          </tr>
        </thead>
        <tbody>
          {uniqueObjectiveArray.map((objective, objectiveIndex) => {
            return (
              <tr>
                <td key={objectiveIndex}>{objective}</td>
                <td style={{ textAlign: 'center' }}>{countArrayObjective[objectiveIndex]}</td>
              </tr>
            );
          })}
          {/* {countArrayObjective.map((item, itemIndex) => {
              return <td key={itemIndex}>{item}</td>;
            })} */}
        </tbody>
      </table>
    </div>
  );
}

export function Marks(props) {
  tempMarksArray = props.checkedRows.map((row, rowIndex) => {
    return row.max_marks;
  });
  uniqueMarksArray = [];
  countArrayMarks = [];
  tempMarksArray.forEach((marks) => {
    if (!uniqueMarksArray.includes(marks)) {
      uniqueMarksArray.push(marks);
    }
  });

  uniqueMarksArray.forEach((marks, marksIndex) => {
    countArrayMarks[marksIndex] = 0;
  });

  // console.log(uniqueMarksArray);
  uniqueMarksArray.forEach((marks, marksIndex) => {
    tempMarksArray.forEach((tempMarks, marksTempIndex) => {
      if (marks === tempMarks) {
        countArrayMarks[marksIndex] = countArrayMarks[marksIndex] + 1;
      }
    });
  });

  unsortedMarksObject = uniqueMarksArray.map((marks, marksIndex) => {
    return { marks: marks, count: countArrayMarks[marksIndex] };
  });

  sortedMarksObject = unsortedMarksObject.sort(function (a, b) {
    return a.marks - b.marks;
  });

  // console.log(countArrayMarks);

  return (
    <div>
      <table className="summaryTable">
        <thead>
          <tr>
            <th>Max-Marks</th>
            {sortedMarksObject.map((marks, marksIndex) => {
              return <th key={marksIndex}>{marks.marks}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Count</td>
            {sortedMarksObject.map((item, itemIndex) => {
              return <td key={itemIndex}>{item.count}</td>;
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export const DifficultyLevelGraph = ({ checkedRows }) => {
  const difficultylevels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  console.log(checkedRows, 'checkedRows');
  const count = (e) => {
    let sum = 0;
    for (let i = 0; i < checkedRows.length; i++) {
      if (checkedRows[i].difficulty_level === e) {
        sum += 1;
      }
    }
    return sum;
  };
  return (
    <div
      style={{
        background: 'white',
        padding: '0px 0px',
        borderRadius: '10px',
        overflow: 'hidden',
      }}
    >
      <h3
        style={{
          marginTop: '0px',
          padding: '10px 10px',
          background: '#F7F7F8',
          fontSize: '16px',
          fontWeight: '600',
          letterSpacing: '1px',
        }}
      >
        Difficulty
      </h3>
      <div style={{ position: 'relative', padding: '0px 10px' }}>
        <div className="gradientmainline"></div>

        <div
          className="gradientsubline"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          {difficultylevels?.map((i, ind) => (
            <div
              key={ind}
              className="levelbox"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                className={`${count(i) === 0 ? 'stylebggray' : 'stylebgactive'}`}
                style={{
                  padding: '5px 10px',

                  borderRadius: '5px',
                  color: 'white',
                }}
              >
                {i}
              </div>
              <div className={`${count(i) === 0 ? 'stylegray' : 'styleactive'}`}>|</div>
              <div className={`${count(i) === 0 ? 'stylegray' : 'styleactive'}`} style={{ padding: '3px 5px 7px 5px' }}>
                {count(i)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
