import React from 'react';
import Dropdown from '../../../SubComponents/Dropdown/Dropdown';

export default function Hierarchy({ state, handleChange, formData, notFilled }) {
  return (
    <div className="hierarchyContainermain">
      <div className="hierarchyContainer">
        {/* <h4 className="compulsoryHeading">Hierarchy Details</h4> */}
        <div className="dropdownFlex">
          <Dropdown
            placeholder="Question Type"
            label="Question Type"
            options={state.questionsType}
            displayName="display_name"
            name="question_type_id"
            idName="id"
            onChange={handleChange}
            value={formData.question_type_id}
            req={true}
            notFill={notFilled}
          >
            {' '}
          </Dropdown>
          <Dropdown
            placeholder="Board"
            label="Board"
            options={state.boards}
            displayName="board_display_name"
            name="board_id"
            idName="board_id"
            onChange={handleChange}
            value={formData.board_id}
            req={true}
            notFill={notFilled}
          >
            {' '}
          </Dropdown>
          <Dropdown
            placeholder="Course"
            label="Course"
            options={state.courses}
            displayName="display_name"
            name="course_id"
            idName="course_id"
            onChange={handleChange}
            value={formData.course_id}
            req={true}
            notFill={notFilled}
          >
            {' '}
          </Dropdown>

          <Dropdown
            placeholder="Subject"
            label="Subject"
            options={state.subjects}
            displayName="display_name"
            name="subject_id"
            idName="subject_id"
            onChange={handleChange}
            value={formData.subject_id}
            req={true}
            notFill={notFilled}
          >
            {' '}
          </Dropdown>
          <Dropdown
            placeholder="Sub-Subject"
            label="Sub-Subject"
            options={state.subSubjects}
            displayName="display_name"
            name="sub_subject_id"
            idName="sub_subject_id"
            onChange={handleChange}
            value={formData.sub_subject_id}
            req={true}
            notFill={notFilled}
          >
            {' '}
          </Dropdown>
        </div>
        <div className="dropdownFlex">
          <Dropdown
            placeholder="Topic1"
            label="Topic1"
            options={state.topics}
            name="topic_1_id"
            onChange={handleChange}
            idName="topic_id"
            displayName="display_name"
            value={formData.topic_1_id}
            req={true}
            notFill={notFilled}
          />
          <Dropdown
            placeholder="Sub-Topic1"
            label="Sub-Topic1"
            options={state.subTopics}
            name="sub_topic_1_id"
            onChange={handleChange}
            idName="sub_topic_id"
            displayName="display_name"
            value={formData.sub_topic_1_id}
            req={true}
            notFill={notFilled}
          />
          <Dropdown
            placeholder="Topic2"
            label="Topic2"
            options={state.topics}
            name="topic_2_id"
            onChange={handleChange}
            idName="topic_id"
            displayName="display_name"
            value={formData.topic_2_id}
            req={false}
          />
          <Dropdown
            placeholder="Sub-Topic2"
            label="Sub-Topic2"
            options={state.subTopics2}
            name="sub_topic_2_id"
            onChange={handleChange}
            idName="sub_topic_id"
            displayName="display_name"
            value={formData.sub_topic_2_id}
            req={false}
          />
        </div>
      </div>
    </div>
  );
}
