import React from 'react';
import './matching.css';

const matchingOptions = ['Select', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
function Matching(props) {
  return (
    <div>
      <div className="directAnswerMatching">
        <label className="searchQuestionLabelMatching">{props.number}.</label>
        <select className="matchingSelect" onChange={(e) => props.onChange(e, props.name, props.number)} value={props.value}>
          {matchingOptions.map((e, keyMatching) => {
            return (
              <option key={keyMatching} value={e}>
                {e}
              </option>
            );
          })}
        </select>
        <button onClick={props.onClick} className="btnResponseMinusParti">
          X
        </button>
      </div>
    </div>
  );
}

export default Matching;
