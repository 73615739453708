import configuration from '../../../config/Config';
import s3 from '../../../config/DigitalOcean';
import { toast } from 'react-toastify';

export default async function fileUpload(e, data, folderName, fileFolder, setFormData, loading, upload, inFormData) {
  e.preventDefault();
  if (data === '') {
    toast.error('Choose a File');
  }
  if (data !== undefined) {
    loading(true);
    let file = data;
    let userId = window.localStorage.getItem('userId');
    let newDate = new Date();
    let date =
      newDate.getDate() +
      '-' +
      (newDate.getMonth() + 1) +
      '-' +
      newDate.getFullYear() +
      '-' +
      newDate.getHours() +
      '-' +
      newDate.getMinutes() +
      '-' +
      newDate.getSeconds();
    let FilePath = folderName + '/' + fileFolder + '/' + date + '-' + userId + '-' + file.name;
    let pdfFilePath = configuration.digitalOceanSpaces + FilePath;
    console.log(pdfFilePath);
    setFormData((prevState) => {
      return {
        ...prevState,
        [inFormData]: pdfFilePath,
      };
    });

    const params = {
      Body: file,
      Bucket: `${configuration.bucketName}`,
      Key: FilePath,
    };
    s3.putObject(params)
      .on('build', (request) => {
        request.httpRequest.headers.Host = `${configuration.digitalOceanSpaces}`;
        request.httpRequest.headers['Content-Length'] = file.size;
        request.httpRequest.headers['Content-Type'] = file.type;
        request.httpRequest.headers['x-amz-acl'] = 'public-read';
        request.httpRequest.headers['Access-Control-Allow-Origin'] = '*';
        loading(false);
        upload(true);
      })
      .send((err) => {
        if (err) {
          console.log(err, 'Img error');
          toast.error(err.response.data.message);
          setFormData((prevState) => {
            return {
              ...prevState,
              [inFormData]: '',
            };
          });
          loading(false);
        }
      });
  } else {
    toast.error('Please upload a valid file');
  }
}
