import { deleteQuestionAdmin } from '../../../../api/questions';
import { toast } from 'react-toastify';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

export default async function handleDeleteView(setStartUpLoad, questionId, userId, handleSubmit) {
  setStartUpLoad(true);
  console.log(questionId, userId);
  try {
    const deleteQue = await deleteQuestionAdmin(questionId, userId);
    console.log(deleteQue);
    toast.success('Question delete sucessful');
    handleSubmit();
  } catch (err) {
    errorHandle(err);
    setStartUpLoad(false);
  }
}
