const configuration = {
  digitalOceanSpaces: process.env.REACT_APP_DIGITALOCEANSPACES,
  digitalOceanSpacesRewrite: process.env.REACT_APP_REWRITE_DIGITALOCEANSPACES,
  bucketName: process.env.REACT_APP_BUCKETNAME,
  folderName: process.env.REACT_APP_ROOT_DIR,
  solutionFolder: process.env.REACT_APP_SOLUTION_DIR,
  responseFolder: process.env.REACT_APP_RESPONSE_DIR,
  copyFileFolder: process.env.REACT_APP_COPYFILE_DIR,
};

export default configuration;
