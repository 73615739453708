import React, { useState, useEffect, useRef } from 'react';
import './migrate.css';
import './migrate.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchMigrateQues, fetchMigrateQuesCount } from '../../../api/ms_sql';
import { uploadQuestionFunction } from '../../../api/questions';
import { clearAll, clearReservation, reserveQue, whoReserved } from '../../../api/queReservation';
import LoadingBars from '../../SubComponents/LoadingBars/LoadingBars';
import MigrateSearch from './MigrateSearch/MigrateSearch';
import MigrateResult from './MigrateResult/MigrateResult';
import fetchData from './MigrateFunctions/fetchData';
import fetchOtherResults from './MigrateFunctions/fetchOtherResult';
import checkReservationTable from './MigrateFunctions/checkReservationTable';
import { fetch0ptionfromlocal } from './MigrateFunctions/fetchOtherResult';

// var language_id = -1;
// var syllabus_id = -1;
// var class_id = -1;
// var subject_id = -1;
// var sub_subject_id = 0;
// var topic_id = 0;
// var sub_topic_id = 0;
// let linkSel = [];
let queSel = [];
// let link;
let linkArray = [];
// let notReservedCount = 0;
// var reservedCount = 0;

let errorQuestionId = [];
let reservedArray = [];
// let sellectAllStatus = false;

function Migrate() {
  const [formdata, setFormdata] = useState({
    language_id: -1,
    syllabus_id: -1,
    class_id: -1,
    subject_id: -1,
    subSubject_id: -1,
    sub_subject_id: 0,
    topic_id: 0,
    sub_topic_id: 0,
    subTopic_id: 0,
  });
  const [state, setState] = useState({
    language: [],
    syllabus: [],
    class: [],
    subject: [],
    subSubject: [],
    topic: [],
    subTopic: [],
  });
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [questionList, setQuestionList] = useState([]);
  const [selLink, setSelLink] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [migrateLoading, setMigrateLoding] = useState(false);
  const [migrateComplete, setMigrateComplete] = useState(false);
  const [error, setError] = useState(false);
  const [queNum, setQueNum] = useState(0);
  const [noData, setNoData] = useState(false);
  const [startUpLoad, setStartUpLoad] = useState(true);
  const initialRender = useRef(true);
  const [selQuestionID, setSelQuestionID] = useState('');
  const notReservedCount = { current: 0 };
  const linkSel = useRef([]);
  // console.log(formdata);
  console.log(notReservedCount, 'notReservedCount');

  useEffect(() => {
    if (window.localStorage.getItem('migrationData')) {
      console.log('localstorage');
      let data = JSON.parse(window.localStorage.getItem('migrationData'));
      setFormdata(data);

      for (let key in data) {
        // fetch0ptionfromlocal(setStartUpLoad, setFormdata, setState, formdata);
        if (Number(data?.[key]) > 0) {
          console.log(key, 'in');
          fetch0ptionfromlocal(data?.[key], key, setStartUpLoad, setFormdata, setState, data);
        }
      }
    }
  }, []);
  const saveLocal = (e) => {
    window?.localStorage?.setItem('migrationData', JSON.stringify(e));
  };
  useEffect(() => {
    saveLocal(formdata);
  }, [formdata]);
  console.log(linkSel.current);
  console.log(notReservedCount.current);
  const userId = window.localStorage.getItem('userId');

  function handleClick(clickedLink, questionID) {
    console.log(clickedLink);
    setSelLink(clickedLink);
    setSelQuestionID(questionID);
  }

  async function handleAddToBacklog() {
    setMigrateComplete(false);
    errorQuestionId = [];
    setError(false);
    if (linkSel.current.length === 0) {
      toast.error('No Questions Selected');
      setTimeout(() => {
        toast.error('Click On The Checkbox To Select');
      }, 2000);
    }
    if (linkSel.current.length !== 0) {
      setMigrateLoding(true);
      console.log(migrateLoading);
      for (var i = 0; i < linkSel.current.length; i++) {
        if (questionList[i]) {
          // setQueNum(i);
          await handleBacklog(i);
          // await handleQueDelete(i);
        }
        if (i === linkSel.current.length - 1) {
          setMigrateLoding(false);
          setMigrateComplete(true);
        }
      }
      await clearAll(userId);
    }
    // if (linkSel?.current) {
    //   console.log(linkSel, "current");
    //   toast.error("Please Select Questions To Migrate");
    // }
  }
  console.log(linkSel, 'current');
  //function to cancel migrate to backlog request
  function handleCancel() {
    linkSel.current = [];
    queSel = [];
    setMigrateComplete(false);
    setMigrateLoding(false);
    setError(false);
    errorQuestionId = [];
    setQueNum(0);
    let tempList = questionList.map((question) => {
      return { ...question, isChecked: false };
    });
    setQuestionList(tempList);
  }

  async function handleSelectAll(e) {
    setStartUpLoad(true);
    setMigrateComplete(false);
    setMigrateLoding(false);
    setError(false);
    var check = e.target.checked;
    console.log(check);
    if (check === true) {
      linkSel.current = [];
      queSel = [];
      let newList = [];
      newList = await checkReservationTable(questionList, 'selectAll', notReservedCount, setStartUpLoad, setQuestionList);
      console.log(newList);
      let tempList = newList.map((question) => {
        return question.isReserved !== true ? { ...question, isChecked: true } : { ...question, isChecked: false };
      });
      for (var i = 0; i < numberOfQuestions; i++) {
        if (newList[i]) {
          if (newList[i].isReserved !== true) {
            linkSel.current = [...linkSel.current, newList[i].PhotoPath];
            queSel = [...queSel, newList[i].QuestionID];
            // console.log(newList[i].PhotoPath);
          }
        }
      }
      console.log(tempList);
      setQuestionList(tempList);
      setStartUpLoad(false);
    } else if (check === false) {
      linkSel.current = [];
      queSel = [];
      try {
        console.log(userId);
        const clear = await clearAll(userId);
        if (clear.status === 200) {
          let tempList = questionList.map((question) => {
            return question.isReserved !== true ? { ...question, isChecked: false } : { ...question, isChecked: false };
          });
          console.log(tempList);
          setQuestionList(tempList);
          setStartUpLoad(false);
        }
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
  }

  async function handleBacklog(i) {
    const data = {
      question_file_path: linkSel.current[i],
      migrate_question_id: queSel[i],
      source_file_path: '',
    };
    console.log(data);
    try {
      // let response = await Axios.post(
      //   `${BASE_URL}/qb/question_group_files?question_format_id=3&user_id=${userId}`,
      //   data,
      //   Auth
      // );
      const toastId2 = toast.loading('Migrating Please wait...', {
        position: 'top-right',
      });

      let response = await uploadQuestionFunction(3, userId, data);
      toast.update(toastId2, {
        render: 'Migration Complete',
        type: 'success',
        closeButton: true,
        // closeButton: true,
        autoClose: 1000,
        isLoading: false,
        position: 'top-right',
      });
      console.log(response);
      console.log(data);
      setQueNum(i);
      return response;
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.err.message);
      } else {
        toast.error('Server Error');
      }
      setError(true);
      console.log(data);
      errorQuestionId.push(queSel[i]);
      linkSel.current = [];
      queSel = [];
      setMigrateLoding(false);
      setMigrateComplete(false);
      setQueNum(0);
      let tempList = questionList.map((question) => {
        return { ...question, isChecked: false };
      });
      setQuestionList(tempList);
    }
  }

  // async function handleQueDelete(i) {
  //   let data = {
  //     Questions: [
  //       {
  //         QuestionId: queSel[i],
  //         IsSync: true,
  //       },
  //     ],
  //   };

  //   Axios.post(
  //     `${BASE_URL_MIGRATE}/PostQuestionMigrateBulk`,
  //     qs.stringify(data)
  //   )
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       if (err.response && err.response.data) {
  //         console.log(err.response);
  //         toast.error(err.response.data.message);
  //       }
  //       migrateLoading(false);
  //     });
  // }

  function handleChange(e, selLabel) {
    const valueSele = e?.target?.value || e;
    setFormdata({ ...formdata, [selLabel]: valueSele });

    fetchOtherResults(e, selLabel, setStartUpLoad, setFormdata, setState, formdata);
  }

  //function to search questions
  async function handleSubmit() {
    linkSel.current = [];
    setMigrateComplete(false);
    setMigrateLoding(false);
    setError(false);
    setSelLink('');
    setSelQuestionID('');
    errorQuestionId = [];
    notReservedCount.current = 0;
    linkArray = [];
    let link = '';
    if (formdata.language_id !== -1) {
      linkArray.push(`languageId=${formdata.language_id}`);
    }
    if (formdata.syllabus_id !== -1) {
      linkArray.push(`syllabusId=${formdata.syllabus_id}`);
    }
    if (formdata.class_id !== -1) {
      linkArray.push(`classId=${formdata.class_id}`);
    }
    if (formdata.subject_id !== -1) {
      linkArray.push(`subjectId=${formdata.subject_id}`);
    }
    if (formdata.sub_subject_id !== -1) {
      linkArray.push(`subSubjectId=${formdata.subSubject_id}`);
    }
    if (formdata.topic_id !== -1) {
      linkArray.push(`topicId=${formdata.topic_id}`);
    }
    if (formdata.sub_topic_id !== -1) {
      linkArray.push(`subTopicId=${formdata.subTopic_id}`);
    }
    link = linkArray.join('&');
    console.log(link);
    if (formdata.subject_id === -1) {
      toast.error('Please Select Options Till SubSubject');
    }
    if (+formdata.subject_id !== -1) {
      setStartUpLoad(true);
      setNumberOfQuestions(0);
      setQuestionList([]);
      try {
        // const getNumberOfQuestions = await Axios.get(
        //   `${BASE_URL_MIGRATE}/GetQuestionTotalCount?${link}`
        // );
        const getNumberOfQuestions = await fetchMigrateQuesCount(link);
        console.log(getNumberOfQuestions.data.QuestionTotalCounts);
        if (getNumberOfQuestions.data.QuestionTotalCounts !== 0) {
          // const getQuestionList = await Axios.get(
          //   `${BASE_URL_MIGRATE}/GetQuestionAllList?${link}&OFFSET=${offSet}&FETCHNEXT=${10}`
          // );
          const getQuestionList = await fetchMigrateQues(link, offSet);
          setNumberOfQuestions(getNumberOfQuestions.data.QuestionTotalCounts);
          console.log('question', getQuestionList);
          console.log(getQuestionList.data[0].PhotoPath);
          setSelLink(getQuestionList.data[0].PhotoPath);
          setSelQuestionID(getQuestionList.data[0].QuestionID);
          checkReservationTable(getQuestionList.data, 'notAll', notReservedCount, setStartUpLoad, setQuestionList);
          setNoData(false);
        }
        if (getNumberOfQuestions.data.QuestionTotalCounts === 0) {
          setNoData(true);
          setStartUpLoad(false);
        }
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.status);
          toast.error(err.response.data.message);
          setStartUpLoad(false);
        }
        if (err.response.status === 404) {
          setNoData(true);
          setStartUpLoad(false);
        } else {
          setStartUpLoad(false);
          toast.error('Server Error');
        }
      }
    }
  }
  async function handleCheckbox(e, selectedLink, row) {
    setMigrateComplete(false);
    setMigrateLoding(false);
    setError(false);
    setStartUpLoad(true);
    const valueSele = e.target.checked;
    const nameSele = parseInt(e.target.name);
    console.log(valueSele, nameSele);
    if (valueSele === true) {
      try {
        const isReserve = await whoReserved(row.QuestionID);
        console.log(isReserve.data);
        if (isReserve.data.data) {
          toast.error('The question is reserved by ' + isReserve.data.data.reserved_by_name);
          let tempList = questionList.map((question) => {
            if (question.QuestionID === nameSele) {
              return {
                ...question,
                isChecked: false,
                reservedBy: isReserve.data.data.reserved_by_name,
                reservationById: isReserve.data.data.reserved_by_id,
                isReserved: true,
              };
            } else {
              return question;
            }
          });
          console.log(tempList);
          setQuestionList(tempList);
        } else {
          const reserve = await reserveQue(nameSele, userId);
          console.log(reserve);
          linkSel.current = [...linkSel.current, selectedLink];
          queSel = [...queSel, nameSele];
          let tempList = questionList.map((question) => {
            if (question.QuestionID === nameSele) {
              return {
                ...question,
                isChecked: true,
                reservationId: reserve.data.data.o_reservation_id,
              };
            } else {
              return question;
            }
          });
          reservedArray.push({
            questionId: nameSele,
            reservationId: reserve.data.data.o_reservation_id,
          });
          console.log(reservedArray);
          window.localStorage.setItem('reservedArray', JSON.stringify(reservedArray));
          console.log(tempList);
          setQuestionList(tempList);
        }

        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (valueSele === false) {
      try {
        const clear = await clearReservation(row.reservationId, nameSele);
        console.log(clear);
        let clearIndex = reservedArray.indexOf({
          reservationId: row.reservationId,
        });
        console.log(clearIndex);
        reservedArray.splice(clearIndex, 1);
        window.localStorage.setItem('reservedArray', JSON.stringify(reservedArray));
        var index = linkSel.current.indexOf(selectedLink);
        var indexQue = queSel.indexOf(nameSele);
        linkSel.current.splice(index, 1);
        queSel.splice(indexQue, 1);
        let tempList = questionList.map((question) => {
          return question.QuestionID === nameSele ? { ...question, isChecked: false } : question;
        });
        tempList.forEach((question) => {
          question.QuestionID === nameSele && delete question.reservationId;
        });
        console.log(tempList);
        setQuestionList(tempList);
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    console.log(linkSel.current);
  }

  function paginationClick(number, offSetNumber) {
    if (Math.ceil(numberOfQuestions / 10) > 1) {
      setPageNumber(+number);
      setOffSet(+offSetNumber);
      setStartUpLoad(true);
    }
  }

  useEffect(() => {
    reservedArray = [];
    fetchData(setState, setStartUpLoad);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialRender.current === true) {
      initialRender.current = false;
    } else {
      async function handlePagination() {
        linkSel.current = [];
        queSel = [];
        console.log(offSet);
        if (formdata.subject_id !== -1) {
          try {
            let link = `LanguageID=${formdata.language_id}&SyllabusID=${formdata.syllabus_id}&ClassID=${formdata.class_id}&SubjectID=${formdata.subject_id}&SubSubjectID=${formdata.sub_subject_id}&TopicID=${formdata.topic_id}&SubtopicID=${formdata.sub_topic_id}`;
            const getQuestionList = await fetchMigrateQues(link, offSet);
            // setQuestionList(getQuestionList.data);
            // setStartUpLoad(false);
            notReservedCount.current = 0;
            // reservedCount = 0;
            checkReservationTable(getQuestionList.data, 'notAll', notReservedCount, setStartUpLoad, setQuestionList);
          } catch (err) {
            if (err.response && err.response.data) {
              console.log(err.response);
              toast.error(err.response.data.message);
            } else {
              toast.error('Server Error');
            }
            setStartUpLoad(false);
          }
        }
      }
      handlePagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSet]);

  return (
    <div>
      {startUpLoad ? (
        <div>
          <LoadingBars />
        </div>
      ) : null}
      {/* <h3 className="queH3Migrate">Migrate Entry</h3> */}
      <div className="migrateQueContainer">
        <div className="migrateSearch">
          <MigrateSearch
            state={state}
            formdata={formdata}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            setNoData={setNoData}
          />
        </div>
        <MigrateResult
          noData={noData}
          questionList={questionList}
          numberOfQuestions={numberOfQuestions}
          handleSelectAll={handleSelectAll}
          notReservedCount={notReservedCount}
          linkSel={linkSel}
          selQuestionID={selQuestionID}
          handleClick={handleClick}
          handleCheckbox={handleCheckbox}
          paginationClick={paginationClick}
          offSet={offSet}
          pageNumber={pageNumber}
          selLink={selLink}
          handleAddToBacklog={handleAddToBacklog}
          migrateLoading={migrateLoading}
          error={error}
          handleCancel={handleCancel}
          queNum={queNum}
          migrateComplete={migrateComplete}
          errorQuestionId={errorQuestionId}
        />
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Migrate;
