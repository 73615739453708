export default function linkGen(formData, questionType) {
  let link = [];
  if (formData.question_type_id && +formData.question_type_id !== -1) {
    link.push(`question_type_id=${formData.question_type_id}`);
  }
  if (formData.board_id && +formData.board_id !== -1) {
    link.push(`board_id=${formData.board_id}`);
  }
  if (formData.course_id && +formData.course_id !== -1) {
    link.push(`course_id=${formData.course_id}`);
  }
  if (formData.subject_id && +formData.subject_id !== -1) {
    link.push(`subject_id=${formData.subject_id}`);
  }
  if (formData.sub_subject_id && +formData.sub_subject_id !== -1) {
    link.push(`sub_subject_id=${formData.sub_subject_id}`);
  }
  if (formData.topic_1_id && +formData.topic_1_id !== -1) {
    link.push(`topic_1_id=${formData.topic_1_id}`);
  }
  if (formData.sub_topic_1_id && +formData.sub_topic_1_id !== -1) {
    link.push(`sub_topic_1_id=${formData.sub_topic_1_id}`);
  }
  if (formData.topic_2_id && +formData.topic_2_id !== -1) {
    link.push(`topic_2_id=${formData.topic_2_id}`);
  }
  if (formData.sub_topic_2_id && +formData.sub_topic_2_id !== -1) {
    link.push(`sub_topic_2_id=${formData.sub_topic_2_id}`);
  }
  if (formData.taxonomy_id && +formData.taxonomy_id !== -1) {
    link.push(`taxonomy_id=${formData.taxonomy_id}`);
  }
  if (formData.objective_id && +formData.objective_id !== -1) {
    link.push(`objective_id=${formData.objective_id}`);
  }
  if (formData.difficulty_level && +formData.difficulty_level !== -1) {
    link.push(`difficulty_level=${formData.difficulty_level}`);
  }
  if (formData.access_level_id && +formData.access_level_id !== -1) {
    link.push(`access_level_id=${formData.access_level_id}`);
  }
  if (formData.medium_id && +formData.medium_id !== -1) {
    link.push(`medium_id=${formData.medium_id}`);
  }
  if (formData.question_code) {
    link.push(`question_code=${formData.question_code}`);
  }
  if (formData.from_date) {
    link.push(`from_date=${formData.from_date}`);
  }
  if (formData.to_date) {
    link.push(`to_date=${formData.to_date}`);
  }
  if (formData.added_by_user_id) {
    // link.push(`added_by_user_id=${formData.added_by_user_id}`);
    link.push(`user_id=${formData.added_by_user_id}`);
  }
  if (questionType === 'Verified') {
    link.push(`curate_status=Curated`);
  }
  if (questionType === 'Not Verified') {
    link.push(`curate_status=Not_Curated`);
  }
  if (questionType === 'Rejected') {
    link.push(`reject_status=Rejected`);
  }
  if (formData.createdBy) {
    link.push(`created_by=${formData.createdBy}`);
  }
  if (formData.curatedBy) {
    link.push(`curated_by_user_id=${formData.curatedBy}`);
  }
  return link.join('&');
}
