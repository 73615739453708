import { boardList } from '../../../../api/boards';
import { fetchCourse, fetchSubject, fetchSubSubject, fetchTopic, fetchSubTopic } from '../../../../api/institution';
import { fetchAccessLevel } from '../../../../api/qbMasterData';
import { fetchObjective } from '../../../../api/objective';
import errorHandle from '../../../../utils/errorHandle/errorHandle';

export default async function fetchDropdownData(
  institution_id,
  board_id,
  course_id,
  subject_id,
  sub_subject_id,
  topic_1_id,
  taxonomy_id,
  setState,
  setStartUpLoad,
) {
  // setStartUpLoad(true);
  try {
    const getBoardData = await boardList();
    const getCourseData = await fetchCourse(institution_id, board_id);
    const getSubjectData = await fetchSubject(institution_id, board_id, course_id);
    const getSubSubjectData = await fetchSubSubject(institution_id, board_id, course_id, subject_id);
    const getTopicData = await fetchTopic(institution_id, board_id, course_id, subject_id, sub_subject_id);
    const getSubTopicData = await fetchSubTopic(institution_id, board_id, course_id, subject_id, sub_subject_id, topic_1_id);
    const getAccessLevelData = await fetchAccessLevel();
    const getObjective = await fetchObjective(taxonomy_id);

    setState((prevState) => {
      return {
        ...prevState,
        board: getBoardData.data.data,
        course: getCourseData.data.data,
        subject: getSubjectData.data.data,
        subSubject: getSubSubjectData.data.data,
        topic: getTopicData.data.data,
        subTopic: getSubTopicData.data.data,
        accessLevel: getAccessLevelData.data.data,
        objectives: getObjective.data.data,
      };
    });

    console.log('dropdown');
    setStartUpLoad(false);
  } catch (error) {
    errorHandle(error);
    setStartUpLoad(false);
  }
}
