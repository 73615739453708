// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addquestions_queContainerdetails__SIWdk {
  /* background-color: #f2f2f2; */
  border-right: 1px solid gray;
  width: 45%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Screens/AddDetails/QuestionImage/addquestions.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,4BAA4B;EAC5B,UAAU;AACZ","sourcesContent":[".queContainerdetails {\n  /* background-color: #f2f2f2; */\n  border-right: 1px solid gray;\n  width: 45%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"queContainerdetails": `addquestions_queContainerdetails__SIWdk`
};
export default ___CSS_LOADER_EXPORT___;
