// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapContainer {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: Inter;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  margin-left: 15%;
  margin-right: 15%;
}

.mapSearch {
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
}
.mapSearch:hover {
  background-color: #51cac2;
}

.mapSearch:active {
  background-color: #327e79;
}
`, "",{"version":3,"sources":["webpack://./src/components/Screens/QSetMap/qSetMap.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,mCAAmC;EACnC,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,4CAA4C;EAC5C,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".mapContainer {\n  background: #ffffff;\n  padding: 15px;\n  margin-bottom: 15px;\n  box-sizing: border-box;\n  color: #797979;\n  font-family: Inter;\n  font-size: 13px;\n  box-shadow: 3px 3px 3px 3px #aab2bd;\n  margin-left: 15%;\n  margin-right: 15%;\n}\n\n.mapSearch {\n  color: #fff;\n  background-color: #68dff0;\n  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);\n  padding: 6px 12px;\n  margin-bottom: 0;\n  font-size: 14px;\n  font-weight: normal;\n  line-height: 1.42857143;\n  text-align: center;\n  white-space: nowrap;\n  vertical-align: middle;\n  border: 1px solid #48bcb4;\n  border-radius: 4px;\n  cursor: pointer;\n}\n.mapSearch:hover {\n  background-color: #51cac2;\n}\n\n.mapSearch:active {\n  background-color: #327e79;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
